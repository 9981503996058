import React from "react";
import styled from "styled-components";

const ListItemFittingTypeAngle = ({ icon, title, onClick }: any) => {
    return (
        <Wrapper onClick={() => onClick()}>
            {icon}
            <Title>{title}</Title>
        </Wrapper>
    );
};

export default ListItemFittingTypeAngle;

export const Title = styled.h3`
    font-size: 16px;
    margin: 0;
    margin-left: 30px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;

const Wrapper = styled.div`
    cursor: pointer;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-top: 9px;
    height: 82px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.app_grey};
    padding: 0 20px;
`;
