import { ArrowRightOutlined } from "@ant-design/icons";
import { Checkbox } from "react-input-checkbox";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { SubHeader, Text } from "../../styled/typography";
import { AppButtonText } from "../buttons/App_ButtonText";

export const ListItemCover = ({ data, isSelected, onSelect, openModal }: any) => {
    return (
        <Wrapper
            onClick={() => onSelect()}
            style={{
                borderColor: isSelected && theme.color.app_blue2,
                backgroundColor: isSelected && theme.color.app_lightBlue,
            }}>
            <LeftWrap>
                <Checkbox
                    modification="coverItem"
                    theme="fancy-checkbox"
                    disabled={false}
                    value={isSelected}
                    onChange={onSelect}>
                    <p></p>
                </Checkbox>
                <ImageWrapper onClick={() => openModal({ data: data, state: true })}>
                    <Image
                        onClick={() => openModal({ state: true, symbol: data?.symbol })}
                        src={`${process.env.REACT_APP_API_URL}/${data?.obrazki[0]}`}
                        alt="hose_cover"
                    />
                </ImageWrapper>
                <InfoElementWrap>
                    <SubHeader>{data?.nazwa}</SubHeader>
                    <AppButtonText
                        onClick={() => openModal({ data: data, state: true })}
                        title={"zobacz szczegóły"}
                        icon={<ArrowRightOutlined style={{ marginLeft: 3 }} />}
                    />
                </InfoElementWrap>
            </LeftWrap>
            <RightWrap>
                <Text>{data?.cena} zł/m</Text>
            </RightWrap>
        </Wrapper>
    );
};
export const ListItemCoverEmpty = ({ isSelected, onSelect }: any) => {
    return (
        <Wrapper
            onClick={() => onSelect()}
            style={{
                borderColor: isSelected && theme.color.app_blue2,
                backgroundColor: isSelected && theme.color.app_lightBlue,
                height: 40,
            }}>
            <LeftWrap>
                <Checkbox
                    modification="coverItem"
                    theme="fancy-checkbox"
                    disabled={false}
                    value={isSelected}
                    onChange={onSelect}>
                    <p></p>
                </Checkbox>
                <SubHeader style={{ marginLeft: 12 }}>Brak osłony</SubHeader>
            </LeftWrap>
            <RightWrap>
                <Text>0,00 zł</Text>
            </RightWrap>
        </Wrapper>
    );
};
const Image = styled.img`
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    object-fit: contain;
    width: 100%;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
`;
const ImageWrapper = styled.div`
    cursor: pointer;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;
    padding: 10px;
`;
const LeftWrap = styled.div`
    display: flex;
    align-items: center;
`;
const RightWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    border: 1px solid ${({ theme }) => theme.color.app_grey};
    border-radius: 5px;
    width: 100%;
    padding: 0 12px;
    height: 60;
    cursor: pointer;
`;
const InfoElementWrap = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 100%;
    padding: 10px 0;
`;
