import * as React from "react";

const IconFittingFlanche45Svg = ({ SIZE = 40, COLOR = "#000" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={SIZE} height={SIZE} viewBox="0 0 44 16.216">
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.25}
            data-name="Path 15"
            d="M13.3 5.2c-.1 0-.1 0-.2-.1a.3.3 0 0 1 0-.4 8.166 8.166 0 0 1 6.2-2.6.2.2 0 0 1 0 .4 7.7 7.7 0 0 0-5.9 2.4.438.438 0 0 1-.1.3Z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.25}
            data-name="Path 16"
            d="M24.8 5.9a.215.215 0 0 0-.2.2v1.2a.2.2 0 0 0 .4 0V6.1c.1-.1 0-.2-.2-.2Z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.25}
            data-name="Path 17"
            d="M35.9 5.9a.215.215 0 0 0-.2.2v1.2a.2.2 0 1 0 .4 0V6.1a.215.215 0 0 0-.2-.2Z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.25}
            data-name="Path 18"
            d="M30.3 5.9a.215.215 0 0 0-.2.2v1.2a.2.2 0 0 0 .4 0V6.1c.1-.1 0-.2-.2-.2Z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.25}
            data-name="Path 19"
            d="M42.8 1.1h-2.6l-2.7-1h-1.6a.215.215 0 0 0-.2.2v.8h-2.8L31.6.6h-1.2a.215.215 0 0 0-.2.2v.3h-2.5l-.9-.5h-1.5V.2a.215.215 0 0 0-.2-.2h-6.7a8.54 8.54 0 0 0-6.2 2.6l-1.3 1.2-.3.3-1.2 1.2-.8.8H7.4c-.1 0-.1 0-.2.1l-.4.4-1.9 1.9-.8-.8a.854.854 0 0 0-1.3 0L.3 10.2a.854.854 0 0 0 0 1.3l10.6 10.6a.854.854 0 0 0 1.3 0l2.2-2.6a.854.854 0 0 0 0-1.3L13.2 17l1.9-1.9.4-.4a.349.349 0 0 0 .1-.2v-1.2l1.5-1.5.4-.4a3.949 3.949 0 0 1 2.8-1.2h4.5a.215.215 0 0 0 .2-.2v-.5h1.5l.9-.5h2.5v.3a.215.215 0 0 0 .2.2h1.2l1.3-.5h2.8v.8a.215.215 0 0 0 .2.2h1.9l2.7-1h2.6a.215.215 0 0 0 .2-.2V1.4c.1-.2-.1-.3-.2-.3ZM14.1 18.6a.375.375 0 0 1 0 .6l-2.5 2.5a.375.375 0 0 1-.6 0L.4 11.1a.375.375 0 0 1 0-.6L2.9 8a.375.375 0 0 1 .6 0l2.1 2.1 6 6ZM42.6 8.5h-2.5l-2.7 1h-1.2v-.8c0-.1-.1-.2-.3-.2h-3.2l-1.3.5h-.8v-.3a.215.215 0 0 0-.2-.2h-2.9l-.9.5h-1.4v-.5a.2.2 0 0 0-.4 0v1.1h-4.2a4.779 4.779 0 0 0-2.8 1l-1 1-.6.6-.8.8a.349.349 0 0 0-.1.2v1.2L13 16.7l-1-1-6-6-1-1 2.3-2.3h1.1l4.1 4.1a.3.3 0 0 0 .4 0 .3.3 0 0 0 0-.4l-4-4 1.6-1.6 1.9-1.9A8.237 8.237 0 0 1 18.3.2h6.4V5a.2.2 0 0 0 .4 0V.9h1.4l.9.5h2.7V5a.2.2 0 1 0 .4 0V.8h.9l1.3.5h2.9v3.6a.2.2 0 1 0 .4 0V.5h1.2l2.7 1h2.5v7Z"
        />
    </svg>
);

export default IconFittingFlanche45Svg;
