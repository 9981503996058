import { useSelector } from "react-redux";
import styled from "styled-components";
import { RandomInfoComponent } from "../randomInfoComponent/RandomInfoComponent";
import { SummaryHeader } from "./SummaryHeader";
import SummaryFittingSection from "./Summary_FittingSection";
import SummaryHoseSection from "./Summary_HoseSection";

const SummaryDesktop = () => {
    const { hose, leftFitting, rightFitting } = useSelector((state: any) => state.currentHose);

    return (
        <Section>
            <SummaryHeader />
            <div>
                <SummaryHoseSection
                    parameters={hose?.parameters}
                    dimension={hose?.dimension}
                    category={hose?.category}
                />
                <SummaryFittingSection
                    left={true}
                    data={{
                        angle: leftFitting.angle,
                        type: leftFitting.type,
                        diameter: leftFitting.parameters?.srednica_gwintu,
                        parameters: leftFitting.parameters,
                    }}
                />
                <SummaryFittingSection
                    left={false}
                    data={{
                        angle: rightFitting.angle,
                        type: rightFitting.type,
                        diameter: rightFitting.parameters?.srednica_gwintu,
                        parameters: rightFitting.parameters,
                    }}
                />
            </div>
            <RandomInfoComponent style={{ marginLeft: 42, marginRight: 42, marginBottom: 20, marginTop: 42 }} />
        </Section>
    );
};

export default SummaryDesktop;

const Section = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.app_grey4};
    width: 480px;
    height: 100%;
    box-sizing: border-box;
    padding: 22px 0px 0px;
`;
