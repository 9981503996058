import { useEffect, useState } from "react";
import { Checkbox } from "react-input-checkbox";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import PAYU_LOGO_WHITE from "../../../assets/PAYU_LOGO_WHITE.png";
import { theme } from "../../../constants/Theme";
import { useRemoveHosesFromLocalStore } from "../../../hooks/localStoreHosesMaintenance/useRemoveHosesFromLocalStore.ts/useRemoveHosesFromLocalStore";
import { useAppDispatch } from "../../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../../reducers";
import { ErrorText, Header, Text, TitleHeader } from "../../../styled/typography";
import { OrderDataTypes } from "../../../types/orderDataTypes";
import { PaymentDataTypes } from "../../../types/paymentDataTypes";
import { getIpAddress } from "../../../utils/getIpAddress";
import { createInternalOrder } from "../../../utils/payment and order/createInternalOrder";
import { prepareOrderData } from "../../../utils/payment and order/prepareOrderData";
import { preparePaymentData } from "../../../utils/payment and order/preparePaymentData";
import { startApiPayProcess } from "../../../utils/payment and order/startApiPayProcess";
import { validateCartAddress } from "../../../utils/payment and order/validation/validateCartAddress";
import { AppButtonRegular } from "../../buttons/App_ButtonRegular";
import { ReusableInfoElementWithEmoji } from "../../elements/ReusableInfoElementWithEmoji";
import { CartPriceResumeWrap } from "../CartPriceResumeWrap";

type Types = {
    cartTotalPrice: number;
    shippingIncluded: boolean | any;
    setVisibility: (arg: boolean) => void;
};
export const CartConfirm = ({ cartTotalPrice, shippingIncluded, setVisibility }: Types) => {
    const dispatch = useAppDispatch();
    const { guestCartHoses } = useSelector((state: RootState) => state.guestCart);

    const { removeHosesFromLocalStore } = useRemoveHosesFromLocalStore();
    const { tempAddress, invoiceData, courierInfo, comment, isLogged } = useSelector(
        (state: RootState) => state.account
    );
    const { updatedCartHoses, hosesIds, userData } = useSelector((state: RootState) => state.account.user);

    const [ip, setIp] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [adressError, setAddressError] = useState("");
    const [error, setError] = useState("");
    const [regulamin, setRegulamin] = useState(false);
    const [regulamin2, setRegulamin2] = useState(false);

    useEffect(() => {
        const fetchIP = async () => {
            const response = await getIpAddress();
            setIp(response.data.ip);
        };
        fetchIP();
    }, []);
    const orderData: OrderDataTypes = prepareOrderData({
        shippingIncluded: shippingIncluded,
        tempAddress: tempAddress,
        hosesList: isLogged ? updatedCartHoses : guestCartHoses,
        userData: userData,
        courierInfo: courierInfo,
        comment: comment,
        cartTotalPrice: cartTotalPrice,
        invoiceData: invoiceData,
        isLogged: isLogged,
    });
    const paymentData: PaymentDataTypes = preparePaymentData({
        tempAddress,
        userIpAddress: ip ? ip : "1.10.14.16",
        updatedCartHoses: isLogged ? updatedCartHoses : guestCartHoses,
        userData: userData,
        cartTotalPrice,
        orderData,
        existingOrder: false,
        isLogged: isLogged,
    });
    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (!regulamin || !regulamin2) {
                setError("Proszę zaakceptować regulamin i politykę prywatności aby kontynuować.");
                return setTimeout(() => setError(""), 5000);
            }
            const isValid = await validateCartAddress(tempAddress);
            if (isValid !== true) {
                setAddressError(isValid);
                alert("Podaj adres odbioru przesyłki!");
                return setTimeout(() => setAddressError(""), 5000);
            }
            const orderId = await createInternalOrder(dispatch, hosesIds, orderData, setError, isLogged);
            const paymentUrl = await startApiPayProcess({ orderId: orderId, paymentData: paymentData });
            window.location.href = paymentUrl;
            // window.open(paymentUrl, "_blank"); //do testowania
            if (!isLogged) {
                removeHosesFromLocalStore(JSON.parse(orderData?.hosesIds));
            }
        } catch (err) {
            console.error(`Wystąpił błąd: ${err}`);
            alert(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Header>Potwierdzenie płatności</Header>
            <ReusableInfoElementWithEmoji
                style={{ backgroundColor: "white", marginTop: 20 }}
                infoRowStyle={{ alignItems: "center" }}
                infos={[
                    {
                        emoji: "📤",
                        description:
                            "Jeśli przerwiesz proces płatności, Twoje zamówienie będzie czekać na finalizację w zakładce Menu - Zamówienia.",
                    },
                    {
                        emoji: "🔏",
                        description: (
                            <>
                                <TitleHeader style={{ marginBottom: 10 }}>Zaakceptuj aby kontynuwać</TitleHeader>
                                <CheckboxWrapper onClick={() => setRegulamin(!regulamin)}>
                                    <Checkbox
                                        modification="cartItemDisable"
                                        theme="fancy-checkbox"
                                        disabled={false}
                                        value={regulamin}
                                        onChange={() => setRegulamin(!regulamin)}>
                                        <p></p>
                                    </Checkbox>
                                    <Text style={{ marginLeft: 10, marginBottom: 2 }}>
                                        Akceptuję <Link to="/Regulamin">Regulamin</Link> sprzedaży
                                    </Text>
                                </CheckboxWrapper>
                                <CheckboxWrapper onClick={() => setRegulamin2(!regulamin2)}>
                                    <Checkbox
                                        modification="cartItemDisable"
                                        theme="fancy-checkbox"
                                        disabled={false}
                                        value={regulamin2}
                                        onChange={() => setRegulamin2(!regulamin2)}>
                                        <p></p>
                                    </Checkbox>
                                    <Text style={{ marginLeft: 10, marginBottom: 2 }}>
                                        Akceptuję <Link to="/PrivacyPolicy">Politykę Prywatności</Link>
                                    </Text>
                                </CheckboxWrapper>
                            </>
                        ),
                    },
                ]}
            />
            <CartPriceResumeWrap price={cartTotalPrice} description="Do zapłaty" />
            <BtnWrap style={{ backgroundColor: "white" }}>
                <ErrorText>{adressError}</ErrorText>
                <ErrorText style={{ marginBottom: error ? 10 : 0 }}>{error}</ErrorText>

                {loading && (
                    <div style={{ width: "100%", height: 40, justifyContent: "center", display: "flex" }}>
                        <MoonLoader color={"black"} loading={loading} size={30} speedMultiplier={0.4} />
                    </div>
                )}

                <AppButtonRegular
                    disable={loading}
                    onClick={() => handleSubmit()}
                    title={"Zapłać"}
                    icon={<img style={{ height: 40 }} src={PAYU_LOGO_WHITE} alt="lengthExample" />}
                    style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        height: 41,
                        marginBottom: 10,
                    }}
                    titleStyle={{
                        fontSize: 14,
                        marginLeft: 80,
                    }}
                />
                <AppButtonRegular
                    onClick={() => setVisibility(false)}
                    borderColor={theme.color.app_blue2}
                    bgColor={theme.color.app_lightBlue}
                    color={theme.color.app_blue2}
                    title="Anuluj"
                />
            </BtnWrap>
        </>
    );
};

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 2px 0;
`;
const BtnWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0;
    padding: 16px 0;
`;
