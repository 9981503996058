import { PaymentDataTypes, preparePaymentDataTypes } from "../../types/paymentDataTypes";

export const preparePaymentData = ({
    userIpAddress,
    updatedCartHoses,
    userData,
    cartTotalPrice,
    orderData,
    existingOrder = false,
    isLogged,
    tempAddress,
}: preparePaymentDataTypes) => {
    const buyer = () => {
        if (isLogged) {
            return {
                customerId: userData?.id,
                email: userData?.mail,
                firstName: userData?.individualData?.fname,
                lastName: userData?.individualData?.lname,
                phone: userData?.phone,
            }
        } else {
            return {
                customerId: '120', //troll account id    
                email: tempAddress?.mail,
                firstName: tempAddress?.fname,
                lastName: tempAddress?.lname,
                phone: tempAddress?.phone,
            }
        }
    }
    const paymentData: PaymentDataTypes = {
        notifyUrl: process.env.REACT_APP_PAYU_NOTIFY_URL,
        continueUrl: process.env.REACT_APP_PAYU_CONTINUE_URL,
        totalAmount: Math.round(cartTotalPrice).toString(),
        ipAddress: userIpAddress,
        merchantPosId: process.env.REACT_APP_PAYU_CLIENT_ID,
        currencyCode: "PLN",
        additionalDescription: JSON.stringify(orderData),
        buyer: buyer(),
        products: existingOrder ? mapExistingOrderProducts(updatedCartHoses) : mapProductFromCart(updatedCartHoses),
    };

    return paymentData;
};

const mapExistingOrderProducts = (products: any) => {
    return products?.map((item: any) => {
        return {
            name: item?.id,
            unitPrice: 123,
            qrCode: item.hose_details[0]?.qrCode,
            quantity: item?.quantity,
        };
    });
};

const mapProductFromCart = (products: any) => {
    return products
        ?.filter((item: any) => item.activity)
        .map((item: any) => {
            return {
                name: item?.ID,
                unitPrice: Math.round(item.totalPrice)?.toString(),
                qrCode: item.qrCode,
                quantity: item?.quantity,
            };
        });
};
