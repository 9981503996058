import styled from "styled-components";
import { Header } from "../../styled/typography";
import { HoseConfig } from "../hoseImages/HoseConfig/HoseConfig";
import { HelpInfoComponent } from "../help/HelpInfoComponent";

export const SummaryHeader = () => {
    return (
        <div>
            <div style={{ marginLeft: 42 }}>
                <HelpInfoComponent />
            </div>
            <HeaderWrap>
                <HeaderCustom>Postęp konfiguracji</HeaderCustom>
            </HeaderWrap>
            <HoseConfig />
        </div>
    );
};
const HeaderCustom = styled(Header)`
    margin: 0px 42px;
`;
const HeaderWrap = styled.div`
    height: 42px;
    display: flex;
    padding-top: 16px;
`;
