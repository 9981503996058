import styled from "styled-components";
import { Text } from "../../styled/typography";
import { renderFittingIcon } from "../../utils/cart/DisplayAvailabilityIcons";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";

export const CartOrderExceededItems = ({ exceededItems }: any) => {
    return (
        <Wrapper>
            <div>
                {exceededItems?.map((item) => (
                    <Element key={item.symbol}>
                        <ElementIcon>{renderFittingIcon(item?.typ_zakucia)}</ElementIcon>
                        <Text>
                            Zamówienie przewyższa dostępny stan magazynowy dla towaru <br />
                            <SpanText> {item.nazwa}</SpanText> <br /> Obecnie mamy dostępne:{" "}
                            <SpanText style={{ color: "black" }}>
                                {item.stock} {item?.typ_zakucia ? "sztuk" : "centymetrów"}
                            </SpanText>
                            , natomiast zamówionych zostało:{" "}
                            <SpanText style={{ color: "black" }}>
                                {item.ordered} {item?.typ_zakucia ? "sztuk" : "centymetrów"}
                            </SpanText>
                            .
                        </Text>
                    </Element>
                ))}
                <ReusableInfoElementWithEmoji
                    style={{ backgroundColor: "white" }}
                    infoRowStyle={{ alignItems: "flex-start" }}
                    infos={[
                        {
                            emoji: "📑",
                            description: (
                                <Text>
                                    Jeśli zdecydujesz się na złożenie zamówienia w obecnej formie, czas realizacji może
                                    ulec wydłużeniu. Możesz również zmodyfikować zamówienie, aby obejmowało tylko
                                    dostępne ilości, lub zapytać ile potrwa dostawa pod numerem telefonu
                                    <SpanText> 603 304 530</SpanText>
                                </Text>
                            ),
                        },
                    ]}
                />
            </div>
        </Wrapper>
    );
};
const SpanText = styled(Text.withComponent("span"))`
    font-size: 13px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.app_blue};
`;
const ElementIcon = styled.div`
    margin-right: 20px;
    width: 50px;
    transform: rotate(90deg);
    display: flex;
    align-items: center;
`;

const Element = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    margin-top: 10px;
`;

const Wrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    @media (min-width: 1000px) {
        height: auto;
        width: 500px;
    }
`;
