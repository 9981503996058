import { createPayuOrder } from "../../services/PayU/payu.service";

export type startApiPayProcessTypes = {
    orderId: string,
    paymentData: any,
}
export const startApiPayProcess = async ({ orderId, paymentData }: startApiPayProcessTypes) => {
    try {
        const payuPaymentUrl = await createPayuOrder({
            paymentData: paymentData,
            orderId: orderId,
        });
        return payuPaymentUrl;
    } catch (error) {
        console.error(`Wystąpił błąd podczas procesu płatności2: ${error}`);
    }
};