import { fetchHoseDNs, fetchHoseDiameters } from "../services/hoseDiameters.service";
import { FETCH_HOSE_DIAMETERS, FETCH_HOSE_DNS } from '../constants/actionNames'

export const fetchHoseDiametersAction = (category_id: string) => ({
    type: FETCH_HOSE_DIAMETERS,
    payload: fetchHoseDiameters(category_id)
})
export const fetchHoseDNsAction = (category_id: string) => ({
    type: FETCH_HOSE_DNS,
    payload: fetchHoseDNs(category_id)
})
