import { LWymiarFlansza451 } from "./base64/L_wymiar_flansza_45-1";
import { LWymiarFlansza901 } from "./base64/L_wymiar_flansza_90-1";
import { LWymiarFlanszaProsta1 } from "./base64/L_wymiar_flansza_prosta-1";
import { LWymiarGw451 } from "./base64/L_wymiar_gw_45-1";
import { LWymiarGw901 } from "./base64/L_wymiar_gw_90-1";
import { LWymiarGwProsta1 } from "./base64/L_wymiar_gw_prosta-1";
import { LWymiarGzProsta1 } from "./base64/L_wymiar_gz_prosta-1";
import { LWymiarOczko1 } from "./base64/L_wymiar_oczko-1";
import { LWymiarNietypowe1 } from "./base64/L_wymiar_nietypowe-1";
import { PWymiarFlansza451 } from "./base64/P_wymiar_flansza_45-1";
import { PWymiarFlansza901 } from "./base64/P_wymiar_flansza_90-1";
import { PWymiarFlanszaProsta1 } from "./base64/P_wymiar_flansza_prosta-1";
import { PWymiarGw451 } from "./base64/P_wymiar_gw_45-1";
import { PWymiarGw901 } from "./base64/P_wymiar_gw_90-1";
import { PWymiarGwProsta1 } from "./base64/P_wymiar_gw_prosta-1";
import { PWymiarGzProsta1 } from "./base64/P_wymiar_gz_prosta-1";
import { PWymiarOczko1 } from "./base64/P_wymiar_oczko-1";
import { PWymiarNietypowe1 } from "./base64/P_wymiar-nietypowe-1";

export const setLeftPdfImage = (leftFitting: any) => {
    switch (leftFitting?.typ_zakucia) {
        case "wewnetrzne":
            if (leftFitting.kat_zakucia === "proste") {
                return LWymiarGwProsta1;
            } else if (leftFitting.kat_zakucia === "90 stopni") {
                return LWymiarGw901;
            } else {
                return LWymiarGw451;
            }
        case "zewnetrzne":
            return LWymiarGzProsta1;
        case "stozkowe":
            return LWymiarGzProsta1;
        case "oczko":
            return LWymiarOczko1;
        case "flansza":
            if (leftFitting.kat_zakucia === "proste") {
                return PWymiarFlanszaProsta1;
            } else if (leftFitting.kat_zakucia === "90 stopni") {
                return LWymiarFlansza901;
            } else {
                return LWymiarFlansza451;
            }
        case "nietypowe":
            return LWymiarNietypowe1;
    }
};
export const setRightPdfImage = (rightFitting: any) => {
    switch (rightFitting?.typ_zakucia) {
        case "wewnetrzne":
            if (rightFitting.kat_zakucia === "proste") {
                return PWymiarGwProsta1;
            } else if (rightFitting.kat_zakucia === "90 stopni") {
                return PWymiarGw901;
            } else {
                return PWymiarGw451;
            }
        case "zewnetrzne":
            return PWymiarGzProsta1;
        case "stozkowe":
            return PWymiarGzProsta1;
        case "oczko":
            return PWymiarOczko1;
        case "flansza":
            if (rightFitting.kat_zakucia === "proste") {
                return LWymiarFlanszaProsta1;
            } else if (rightFitting.kat_zakucia === "90 stopni") {
                return PWymiarFlansza901;
            } else {
                return PWymiarFlansza451;
            }
        case "nietypowe":
            return PWymiarNietypowe1;
    }
};
