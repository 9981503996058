import * as React from "react"

function IconArrowLeftSvg({SIZE = 15, COLOR = '#2B8AB6'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 16.673 11.253"
		>
			<path
				data-name="Path 640"
				d="M16.673 5.627H2.643"
				fill="none"
				stroke={COLOR}
				strokeWidth={2}
			/>
			<path
				data-name="Path 639"
				d="M7.834 10.465L1.627 5.627 7.834.789"
				fill="none"
				stroke={COLOR}
				strokeMiterlimit={10}
				strokeWidth={2}
			/>
		</svg>
	)
}

export default IconArrowLeftSvg
