import axios from "../../utils/axiosInterceptor";
interface createOrderTypes {
    paymentData: any;
    orderId: string | number;
}

export const createPayuOrder = async ({ paymentData, orderId }: createOrderTypes) => {
    const currentTime = new Date().getTime();
    const payuOrderDataSchema = {
        notifyUrl: paymentData.notifyUrl,
        customerIp: paymentData.ipAddress,
        merchantPosId: paymentData.merchantPosId,
        description: `${"Zamówienie nr " + orderId}`,
        currencyCode: paymentData.currencyCode,
        totalAmount: paymentData.totalAmount,
        extOrderId: orderId + "_" + currentTime,
        continueUrl: paymentData.continueUrl,
        buyer: paymentData.buyer,
        products: paymentData.products,
    };

    const fetchLinkResponse = await axios.post(`order-payu.php`, payuOrderDataSchema);
    const fetchLinkResponseData = await fetchLinkResponse?.data;
    if (!fetchLinkResponseData.redirectUri) {
        return {
            statusCode: 500,
            body: "Nie udało się uzyskać linku do płatności_serv2",
        };
    }
    return fetchLinkResponseData?.redirectUri;
};









// export const createPayuForNetlify = async ({ paymentData, orderId }: createOrderTypes) => {
//     console.log('paymentData: ', paymentData);
//     const fetchLinkResponse = await fetch(
//         "https://konfigurator-zakuwanie24-staging.netlify.app/.netlify/functions/createOrder",
//         {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ paymentData: paymentData, orderId: orderId }),
//         }
//     );
//     const data = await fetchLinkResponse.json()
//     console.log('data: ', data)
//     if (!data?.url) {
//         alert("Nie udało się utworzyć zamówienia w systemie płatności_inapp2");
//         throw new Error("Nie udało się uzyskać linku do płatności_inapp2.5");
//     }
//     return await data.url;
// };

//
// export const createOrderEXAMPLE = async () => {
//     const fetchLink = fetch("https://secure.snd.payu.com/api/v2_1/orders", {
//         body: JSON.stringify({
//             notifyUrl: "https://mojsklep.pl",
//             customerIp: "127.0.0.1",
//             merchantPosId: 465690,
//             description: "RTV market",
//             currencyCode: "PLN",
//             totalAmount: "15000",
//             extOrderId: Math.random(),
//             buyer: {
//                 email: "john.doe@example.com",
//                 phone: "654111654",
//                 firstName: "John",
//                 lastName: "Doe",
//             },
//             products: [
//                 {
//                     name: "Wireless Mouse for Laptop",
//                     unitPrice: "15000",
//                     quantity: "1",
//                 },
//             ],
//         }),
//         headers: {
//             Authorization: `Bearer ca4f1519-0e3c-450c-828b-04ee1fde8780`,
//             "Content-Type": "application/json",
//         },
//         method: "POST",
//     });
//     return await fetchLink;
// };
