import styled from "styled-components";

export type infoWithIconTypes = {
    emoji?: string | any;
    description: React.ReactNode;
    infoRowStyle?: any;
};
type InfoContainerProps = {
    infos: infoWithIconTypes[];
    style?: any;
    infoRowStyle?: any;
};
export const InformationElement: React.FC<infoWithIconTypes> = ({ emoji, description, infoRowStyle }) => {
    return (
        <InfoRow style={infoRowStyle}>
            <Emoji>{emoji}</Emoji>
            <Text>{description}</Text>
        </InfoRow>
    );
};

export const ReusableInfoElementWithEmoji: React.FC<InfoContainerProps> = ({ infos, style, infoRowStyle }) => {
    return (
        <InfoContainer style={style}>
            {infos.map((info, index) => (
                <InformationElement
                    infoRowStyle={infoRowStyle}
                    key={index}
                    emoji={info.emoji}
                    description={info.description}
                />
            ))}
        </InfoContainer>
    );
};
const InfoContainer = styled.div`
    border: 1px solid ${({ theme }) => theme.color.app_blue};
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    padding: 5px 15px;
    border-radius: 5px;
`;
const Text = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    width: 100%;
`;

const InfoRow = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 15px 0px;
`;

const Emoji = styled.span`
    margin-right: 10px;
    font-size: 24px;
    white-space: nowrap;
`;
