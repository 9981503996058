import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { Header } from "../../styled/typography";
import ProgressBar from "../progress/ProgressBar";
import { RandomInfoComponent } from "../randomInfoComponent/RandomInfoComponent";

const ListWrapper = ({ list, title, listHeader, subtitle }: any) => {
    const screenSize = useScreenSize();
    return (
        <Wrapper>
            <ProgressBar />

            <ContentWrap>
                <TitleWrap>
                    <div>
                        <CustomHeader>{title}</CustomHeader>
                        {subtitle}
                    </div>
                </TitleWrap>
                {listHeader}
                <ListWrap>{list}</ListWrap>
            </ContentWrap>
            {screenSize.width <= 1023 && <RandomInfoComponent style={{ marginLeft: 15, marginRight: 15 }} />}
        </Wrapper>
    );
};

export default ListWrapper;

const CustomHeader = styled(Header)`
    margin-bottom: 9px;
`;

const ListWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;
const TitleWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
`;
const ContentWrap = styled.div`
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    @media (min-width: 1279px) {
        padding: 16px 76px 41px 76px;
        width: 580px;
    }
`;
const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px 0;
    background-color: ${({ theme }) => theme.color.white};
    margin-bottom: 50px;
    box-sizing: border-box;
    @media (min-width: 619px) {
        width: 612px;
    }
`;
