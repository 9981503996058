import styled from "styled-components";
import { theme } from "../../constants/Theme";

interface Types {
    title: string;
    onClick: any;
    color?: any;
    style?: any;
    icon?: any;
}
export const AppButtonText = ({ title, onClick, color = theme.color.app_blue2, style, icon }: Types) => {
    return (
        <Title onClick={onClick} style={{ color: color, ...style }}>
            {title}
            {icon}
        </Title>
    );
};

const Title = styled.p`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    outline: none !important;
    transition: transform 0.3s ease;
    transform-origin: left;
    &:hover {
        transform: scale(1.05);
    }
`;
