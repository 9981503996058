import styled from "styled-components";
import { switchFittingTypeName } from "../../utils/switchFittingTypeIconByName";
import SummaryRegularElement from "./SummaryRegularElement";

interface Types {
    left: boolean;
    data: any;
}

const SummaryFittingSection = ({ left, data }: Types) => {
    return (
        <>
            {data.type && (
                <>
                    <SubHeader>{left ? "2. Lewe" : "3. Prawe"} Zakucie</SubHeader>
                    <SummaryRegularElement header="Typ zakucia" title={switchFittingTypeName(data.type)} />
                </>
            )}
            {data.angle && <SummaryRegularElement header="Kąt zakucia" title={data.angle} />}
            {data.diameter && <SummaryRegularElement header="Średnica gwintu" title={data.diameter.split("_")[0]} />}
            {data.parameters && <SummaryRegularElement header="Nazwa zakucia" title={data.parameters.nazwa} />}
        </>
    );
};
export default SummaryFittingSection;

const SubHeader = styled.h2`
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.black};
    padding: 12px 42px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
`;
