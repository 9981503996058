import styled from "styled-components";

export const FittingLSAlert = ({ type }: any) => {
    const thisType = type === "L" ? "lekki" : "cięzki";
    const similarType = type !== "L" ? "lekki" : "cięzki";
    return (
        <Wrapper>
            <Text>
                Uwaga, to jest <span style={{ fontWeight: 700 }}>{thisType} </span> rodzaj tego zakucia. Obok znajdziesz
                rodzaj {similarType}, który różni się tylko jednym parametrem{" "}
                <span style={{ fontWeight: 700 }}>"D"</span>. Najłatwiej rozróżnia się je po gwincie współpracującym.
                <br />
                <Text2
                    onClick={() => window.open("https://zakuwanie24.pl/jak-rozroznic-system-dkol-i-dkos/", "_blank")}>
                    Zobacz jak rozróżnić zakucia ciężkie i lekkie
                </Text2>
            </Text>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: 10px;
`;
const Text = styled.p`
    padding: 4px 10px 6px;
    font-size: 11px;
    background-color: ${({ theme }) => theme.color.app_yellow2};
    border: 2px solid ${({ theme }) => theme.color.app_orange};
    color: ${({ theme }) => theme.color.black};
    border-radius: 5px;
    font-weight: 600;
`;
const Text2 = styled.p`
    padding: 4px 10px 6px;
    margin: 5px;
    font-size: 11px;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    border: 1px solid ${({ theme }) => theme.color.app_blue2};
    color: ${({ theme }) => theme.color.app_blue2};
    border-radius: 5px;
    width: "auto";
    font-weight: 600;
    cursor: pointer;
    &:hover {
        color: ${({ theme }) => theme.color.black};
    }
`;
