import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import useScreenSize from "use-screen-size";
import { setLeftFittingAngleAction, setRightFittingAngleAction } from "../../actions/currentHose.action";
import { fetchFittingAnglesAction } from "../../actions/fittingAngles.action";
import { AppButtonBorderWithIcon } from "../../components/buttons/App_ButtonBorderWithIcon";
import ListItemFittingTypeAngle from "../../components/listElements/ListItem_FittingTypeAngle";
import ListWrapper from "../../components/listWrappers/ListWrapper";
import { theme } from "../../constants/Theme";
import IconAngleSvg from "../../icons/Icon_AngleSvg";
import IconArrowRight from "../../icons/Icon_ArrowRight";
import { RootState } from "../../reducers";
import { RouteWrapper } from "../../styled/wrappers";
import { getFittingIcon } from "./helpers";

const FittingAngleRoute = () => {
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const side = useSelector((state: any) => state.inAppStateReducer.hoseSide);

    const { parameters, category } = useSelector((state: RootState) => state.currentHose.hose);
    const leftFittingType = useSelector((state: any) => state.currentHose.leftFitting.type);
    const rightFittingType = useSelector((state: any) => state.currentHose.rightFitting.type);
    const { angles, loading } = useSelector((state: any) => state.fittings);

    useEffect(() => {
        if (side === "LEFT") {
            dispatch(setLeftFittingAngleAction(null));
            dispatch(fetchFittingAnglesAction(parameters.symbol, leftFittingType, category.id));
        } else if (side === "RIGHT") {
            dispatch(setRightFittingAngleAction(null));
            dispatch(fetchFittingAnglesAction(parameters.symbol, rightFittingType, category.id));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectCurrentFittingAngle = (angle: any) => {
        if (side === "LEFT") {
            dispatch(setLeftFittingAngleAction(angle));
        } else if (side === "RIGHT") {
            dispatch(setRightFittingAngleAction(angle));
        }
        navigate("/create/fittingDiameter");
    };

    return (
        <RouteWrapper>
            <ListWrapper
                icon={<IconAngleSvg COLOR={theme.color.black} SIZE={screenSize.width > 1120 ? 35 : 25} />}
                title={"Wybierz kąt zakucia"}
                subtitle={
                    "Kąty dostępne dla wybranego rodzaju zakucia. Jeśli nie ma to dla Ciebie znaczenia, najlepszym wyborem są zakucia proste"
                }
                listHeader={
                    <AppButtonBorderWithIcon
                        description="Pomoc - Jak wybrać kąt zakucia?"
                        onClick={() =>
                            window.open("https://zakuwanie24.pl/jak-wybrac-rodzaj-i-kat-zakonczenia/", "_blank")
                        }
                        icon={<IconArrowRight />}
                    />
                }
                list={
                    <>
                        <MoonLoader color={"white"} loading={loading} size={50} speedMultiplier={0.4} />

                        {!loading && (leftFittingType || rightFittingType) && angles && (
                            <>
                                {angles.map((angle: any) => (
                                    <ListItemFittingTypeAngle
                                        key={angle}
                                        routeName={"/create/fittingDiameter"}
                                        title={`Zakucie ${angle}`}
                                        icon={getFittingIcon(
                                            angle,
                                            side === "LEFT" ? leftFittingType : rightFittingType
                                        )}
                                        onClick={() => selectCurrentFittingAngle(angle)}
                                    />
                                ))}
                            </>
                        )}
                    </>
                }></ListWrapper>
        </RouteWrapper>
    );
};

export default FittingAngleRoute;
