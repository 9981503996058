import { InboxOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getUserDataAction } from "../actions/account.action";
import { ListItemOrder } from "../components/listElements/ListItem_Order";
import { RootState } from "../reducers";
import { RouteWrapper } from "../styled/wrappers";
import { getIpAddress } from "../utils/getIpAddress";

export const OrdersRoute = () => {
    const dispatch = useDispatch();
    const [ip, setIp] = useState<string | null>(null);
    const { orders } = useSelector((state: RootState) => state.account.user);
    const { id } = useSelector((state: RootState) => state.account.user.userData);
    useEffect(() => {
        const fetchIP = async () => {
            const response = await getIpAddress();
            setIp(response.data.ip);
        };
        fetchIP();
    }, []);
    useEffect(() => {
        dispatch(getUserDataAction(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <RouteWrapper>
            <Wrapper>
                <CartBody>
                    <Header>
                        <InboxOutlined style={{ fontSize: 28, marginRight: 10 }} />
                        Zamówienia
                    </Header>
                    {[...orders]?.reverse()?.map((item: any, index: number) => (
                        <ListItemOrder ip={ip} key={index} data={item} />
                    ))}
                </CartBody>
            </Wrapper>
        </RouteWrapper>
    );
};

const Header = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin: 24px 0;
    display: flex;
    align-items: center;
`;
const CartBody = styled.div`
    border-radius: 15px;
    padding: 17px;
    background-color: ${({ theme }) => theme.color.white};
    @media (max-width: 1024px) {
        width: auto;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.white};
    box-sizing: border-box;
    min-height: 100vh;
    @media (min-width: 619px) {
        width: 612px;
    }
`;
