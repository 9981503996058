import * as React from "react";

export default function IconHoseHydraulicSvg({ SIZE = 45, COLOR = "#2699FB" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={SIZE}
      height={SIZE}
      viewBox="0 0 34.216 47.899"
    >
      <path
        data-name="Path 31"
        d="M17.562.006A15.4 15.4 0 001.711 15.393v25.662A1.711 1.711 0 000 42.766v3.421a1.711 1.711 0 001.711 1.711h5.132a1.711 1.711 0 001.711-1.711v-3.422a1.711 1.711 0 00-1.711-1.711v-25.66a10.265 10.265 0 1120.529 0v25.661a1.711 1.711 0 00-1.711 1.711v3.421a1.711 1.711 0 001.711 1.711h5.132a1.711 1.711 0 001.711-1.711v-3.422a1.711 1.711 0 00-1.711-1.711v-25.66A15.4 15.4 0 0017.565.007zm-.053 1.707a13.686 13.686 0 0113.285 13.68v17.108h-1.711V15.393a11.976 11.976 0 10-23.951 0v17.108h-1.71V15.393a13.686 13.686 0 0114.087-13.68zm-14.087 32.5h1.71v6.843h-1.71zm25.662 0h1.711v6.843h-1.712zM1.711 42.766h5.132v3.422H1.711zm25.662 0h5.132v3.422h-5.132z"
        fill={COLOR}
      />
    </svg>
  );
}
