import { useEffect, useState } from "react";
import { infosList } from "../../data/infos";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { useLocation } from "react-router-dom";

export const RandomInfoComponent = ({ style, single }: any) => {
    const [randomInfo, setRandomInfo] = useState(null);
    const location = useLocation();

    const getRandomInfo = () => {
        const randomIndex = Math.floor(Math.random() * infosList.length);
        return infosList[randomIndex];
    };

    useEffect(() => {
        setRandomInfo(getRandomInfo());
    }, [location.pathname]);

    return (
        <ReusableInfoElementWithEmoji
            style={{ marginTop: 10, ...style }}
            infos={[
                {
                    emoji: "🕚",
                    description:
                        "Ekspresowa wysyłka! Zamówienia złożone do godziny 11:00 są wysyłane tego samego dnia.",
                },
                ...(randomInfo ? [randomInfo] : []),
            ]}
        />
    );
};
