import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { Text } from "../../styled/typography";
import { HelpInfoComponent } from "./HelpInfoComponent";

export const HelpContact = () => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Wrapper style={{ height: isOpen ? "auto" : 30 }}>
            <CloseBtnWrap
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    top: isOpen ? 0 : -3,
                }}>
                <CloseBtn
                    style={{
                        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                        transitionProperty: "transform",
                        transitionDuration: "0.2s",
                    }}>
                    <IconArrowDownSvg COLOR={theme.color.black} />
                </CloseBtn>
            </CloseBtnWrap>
            {isOpen ? (
                <HelpInfoComponent />
            ) : (
                <Content>
                    <Text>Email: </Text>
                    <PhoneSmall href="mailto:info@zakuwanie24.pl"> info@zakuwanie24.pl</PhoneSmall>
                    <Text style={{ marginLeft: 10 }}>Tel: </Text>
                    <PhoneSmall href="tel:+48885443185"> 885 443 185</PhoneSmall>
                </Content>
            )}
        </Wrapper>
    );
};
const Content = styled.div`
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.color.app_green};
    border-radius: 5px;
    width: 100%;
`;
const PhoneSmall = styled.a`
    color: ${({ theme }) => theme.color.app_black};
    font-size: ${({ theme }) => theme.fontSize.font12};
    font-weight: 600;
    margin-left: 5px;
    color: ${({ theme }) => theme.color.black};
`;
const Wrapper = styled.div`
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.app_lightGreen};
    border-radius: 5px;
    position: fixed;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
`;
const CloseBtnWrap = styled.div`
    height: 30px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 1000;
    right: 8px;
`;
const CloseBtn = styled.div`
    cursor: pointer;
`;
