import React from "react";
import styled from "styled-components";

interface Types {
    title: string;
    icon?: object;
    value: string | number;
}

const PropertyElementSummary = ({ title, value }: Types) => {
    return (
        <Wrap>
            <Title>{title}</Title>
            <Value>{value}</Value>
        </Wrap>
    );
};

export default PropertyElementSummary;

const Value = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: black;
`;
const Title = styled.p`
    margin: 4px 30px 0 0;
    font-size: 10px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_grey2};
    width: 70px;
`;
const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0;
`;
