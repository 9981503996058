import * as React from "react";
import { theme } from "../constants/Theme";

function IconAngleSvg({ SIZE = 30, COLOR = theme.color.white }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={SIZE} height={SIZE} viewBox="0 0 20.416 19.944">
            <path
                data-name="Path 48"
                d="M18.438 18.177l.052-.064.131-.225 1.672-2.666a.863.863 0 00-.052-.964.544.544 0 00-.915.1l-.915 1.477a18.155 18.155 0 00-6.9-12.847h.026l1.542-.418a.756.756 0 00.47-.9.624.624 0 00-.732-.546l-2.692.739-.209.064a.769.769 0 00-.47.867l.052.353.47 3.276c.052.353.34.642.627.61a.727.727 0 00.575-.867l-.34-1.959s.026.032.052.032a16.054 16.054 0 015.488 7.9 18.934 18.934 0 01.81 4.047l-.052-.032-1.303-1.06a.572.572 0 00-.862.193.879.879 0 00.157 1.028l2.117 1.734.183.128.183.128a.833.833 0 00.287.128H4.09a2.771 2.771 0 01-2.639-1.188c-.6-1.124.131-2.634.706-3.533l6.742-10.7.784-1.22.235-.385.131-.225a.834.834 0 00-.1-1.028.546.546 0 00-.862.128l-.6.964-.784 1.253-6.538 10.347c-.836 1.317-1.542 3.148-.941 4.786a3.462 3.462 0 002.77 2.216 6.335 6.335 0 001.1.1h15.232a.71.71 0 00.627-.739.688.688 0 00-.627-.739h-1.332a.787.787 0 00.444-.293z"
                fill={COLOR}
            />
        </svg>
    );
}

export default IconAngleSvg;
