import * as React from "react";
import { theme } from "../constants/Theme";

const IconCloseSvg = ({ COLOR = theme.color.app_blue, SIZE = 14 }) => (
    <svg width={SIZE} height={SIZE} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m8.412 7 4.3-4.29a1.004 1.004 0 0 0-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 1 0-1.42 1.42L5.592 7l-4.3 4.29a.999.999 0 0 0 0 1.42 1.001 1.001 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1.002 1.002 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095L8.412 7Z"
            fill={COLOR}
        />
    </svg>
);

export default IconCloseSvg;
