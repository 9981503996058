import React from "react";
import { theme } from "../../constants/Theme";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { Text } from "../../styled/typography";

export const HelpInfoComponent = () => {
    return (
        <ReusableInfoElementWithEmoji
            style={{
                backgroundColor: theme.color.app_lightGreen,
                borderColor: theme.color.app_green,
            }}
            infos={[
                {
                    emoji: "📞",
                    description: (
                        <div>
                            <Text>
                                Potrzebujesz pomocy? Zadzwoń między 7:30 a 16:00 pod{" "}
                                <a href="tel:+48885443185" style={{ fontWeight: 600, color: "black" }}>
                                    885 443 185
                                </a>{" "}
                                lub napisz{" "}
                                <a href="mailto:info@zakuwanie24.pl" style={{ fontWeight: 600, color: "black" }}>
                                    info@zakuwanie24.pl
                                </a>
                            </Text>
                        </div>
                    ),
                },
            ]}
        />
    );
};
