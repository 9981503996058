import styled from "styled-components";
import imgHose from "../../assets/pomiar/PRZEWOD-1.png";
import { ListHeaderTableDescription } from "./ListHeaderTableDescription";

const HoseDimensionListHeader = () => {
    return (
        <Wrapper>
            <Image src={imgHose} alt="przewód_pomiar" />
            <ListHeaderTableDescription
                items={[
                    { text: "DN", width: "100%" },
                    { text: "Cale", width: "100%" },
                    { text: "Milimetry", width: "100%" },
                ]}
            />
        </Wrapper>
    );
};

export default HoseDimensionListHeader;

const Image = styled.img`
    margin: 0;
    object-fit: contain;
    width: 100%;
    min-height: 140px;
    box-sizing: border-box;
`;
const Wrapper = styled.div`
    width: 100%;
`;
