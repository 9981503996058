import { useState } from "react";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setComment, setCourierInfo } from "../../actions/account.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { RootState } from "../../reducers";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { InputAddress } from "../inputs/InputAddress";
import { SubHeader, TitleHeader, Text } from "../../styled/typography";

export const CartShippingPrice = ({ shippingIncluded, cartTotalQuantity }: any) => {
    const dispatch = useAppDispatch();
    const { courierInfo } = useSelector((state: RootState) => state.account);

    const [collapseOpen, setCollapseOpen] = useState(false);
    const { comment } = useSelector((state: RootState) => state.account);
    const [collapseOpen2, setCollapseOpen2] = useState(false);

    return (
        <ReusableInfoElementWithEmoji
            style={{
                backgroundColor: theme.color.white,
                marginTop: 10,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <div>
                            <Wrapper>
                                <TitleHeader>Dostawa</TitleHeader>
                                <SubHeader>{shippingIncluded ? "Gratis!" : "20 zł"}</SubHeader>
                            </Wrapper>
                            <Collapsible
                                onOpen={() => setCollapseOpen(true)}
                                onClose={() => setCollapseOpen(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            marginTop: 15,
                                        }}>
                                        <Text style={{ marginRight: 10 }}>Dodaj informację dla kuriera</Text>
                                        <IconWrap
                                            style={{
                                                marginRight: 5,
                                                transform: collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg COLOR="black" />
                                        </IconWrap>
                                    </div>
                                }>
                                <InputAddress
                                    type="textarea"
                                    wrapperStyles={{ marginTop: 15 }}
                                    value={courierInfo ? courierInfo : ""}
                                    onTextChange={(e: any) => dispatch(setCourierInfo(e.target.value))}
                                    title={"Wiadomość dla kuriera"}
                                    inputStyle={{ height: 80 }}
                                />
                            </Collapsible>
                        </div>
                    ),
                },
                {
                    emoji: "",
                    description: (
                        <div>
                            <Wrapper>
                                <TitleHeader>Ilość zamówionych przewodów</TitleHeader>
                                <SubHeader>{cartTotalQuantity} sztuk</SubHeader>
                            </Wrapper>
                            <Collapsible
                                onOpen={() => setCollapseOpen2(true)}
                                onClose={() => setCollapseOpen2(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            marginTop: 15,
                                        }}>
                                        <Text style={{ marginRight: 10 }}>Dodaj komentarz do zamówienia</Text>
                                        <IconWrap
                                            style={{
                                                transform: collapseOpen2 ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg COLOR="black" />
                                        </IconWrap>
                                    </div>
                                }>
                                <InputAddress
                                    type="textarea"
                                    wrapperStyles={{ marginTop: 15 }}
                                    value={comment ? comment : ""}
                                    onTextChange={(e: any) => dispatch(setComment(e.target.value))}
                                    title={"Komentarz do zamówienia"}
                                    inputStyle={{ height: 80 }}
                                />
                            </Collapsible>
                        </div>
                    ),
                },
            ]}
        />
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const IconWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
