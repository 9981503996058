import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setCurrentHoseOrientationAction } from "../../actions/currentHose.action";
import { theme } from "../../constants/Theme";
import IconPosition0Svg from "../../icons/positions/Icon_Position0Svg";
import IconPosition135Svg from "../../icons/positions/Icon_Position135Svg";
import IconPosition180Svg from "../../icons/positions/Icon_Position180Svg";
import IconPosition225Svg from "../../icons/positions/Icon_Position225Svg";
import IconPosition270Svg from "../../icons/positions/Icon_Position270Svg";
import IconPosition315Svg from "../../icons/positions/Icon_Position315Svg";
import IconPosition45Svg from "../../icons/positions/Icon_Position45Svg";
import IconPosition90Svg from "../../icons/positions/Icon_Position90Svg";
import { RootState } from "../../reducers";
import { Button } from "../buttons/Button";

const FittingsPosition = () => {
    const dispatch = useDispatch();
    const currentOrientation = useSelector((state: RootState) => state.currentHose.hose.orientation);
    const [hoverPosition, setHoverPosition] = useState(0);

    const availablePositions = [0, 45, 90, 135, 180, 225, 270, 315];
    const iconSize = 160;
    const switchPosition = (position: number) => {
        switch (position) {
            case 0:
                return <IconPosition0Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 45:
                return <IconPosition45Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 90:
                return <IconPosition90Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 135:
                return <IconPosition135Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 180:
                return <IconPosition180Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 225:
                return <IconPosition225Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 270:
                return <IconPosition270Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            case 315:
                return <IconPosition315Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
            default:
                return <IconPosition0Svg SIZE={iconSize} COLOR={"#9F9F9F"} ROTATE_COLOR={"black"} />;
        }
    };

    const btnHover = (item: any) => {
        setHoverPosition(item);
    };
    return (
        <Wrapper>
            <Header>Orientacja zakończeń</Header>
            <Text>Oba zakucia są kątowe - możesz wybrać ich orientację względem siebie</Text>
            <Content>
                <ImageWrap>{switchPosition(hoverPosition)}</ImageWrap>
                <ButtonsWrap>
                    {availablePositions.map((item: any) => (
                        <SingleBtn
                            onMouseEnter={() => btnHover(item)}
                            onMouseLeave={() => setHoverPosition(currentOrientation)}>
                            <Button
                                bgColor={
                                    currentOrientation === item ? theme.color.app_blue2 : theme.color.app_lightBlue
                                }
                                color={currentOrientation === item ? theme.color.app_lightBlue : theme.color.app_blue2}
                                title={item + "°"}
                                onClick={() => dispatch(setCurrentHoseOrientationAction(item))}
                                isButtonGroup
                            />
                        </SingleBtn>
                    ))}
                </ButtonsWrap>
            </Content>
        </Wrapper>
    );
};

export default FittingsPosition;

const Header = styled.h2`
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const Text = styled.p`
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const SingleBtn = styled.div`
    width: 22%;
    padding-bottom: 5px;
    cursor: pointer;
    @media (min-width: 1279px) {
        width: 45%;
    }
`;
const ButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: 1279px) {
        flex-direction: row;
        width: 100%;
        margin-left: 15px;
    }
`;
const ImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
    height: 140px;
    width: 100%;
    padding-top: 7px;
    flex-shrink: 0;
    overflow: hidden;
    margin-bottom: 11px;
    @media (min-width: 1279px) {
        width: 300px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 1279px) {
        flex-direction: row;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
