import * as React from "react"

function IconFittingFlancheSvg({SIZE = 40, COLOR = '#FFF'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 38.605 16.843"
		>
			<g data-name="Group 761">
				<path
					data-name="Path 727"
					d="M38.354 4.149H35.73l-2.728-1.028a.247.247 0 00-.089-.017h-1.474a.25.25 0 00-.25.25v.8h-2.787l-1.292-.492a.247.247 0 00-.089-.017h-1.14a.25.25 0 00-.25.25v.258h-2.5l-.938-.48a.249.249 0 00-.114-.027h-1.437v-.432a.25.25 0 00-.25-.25H9.745l-.88-.885a.251.251 0 00-.177-.073H5.375V.911A.912.912 0 004.464 0H.911A.912.912 0 000 .911v15.021a.912.912 0 00.911.911h3.554a.912.912 0 00.911-.911v-1.737h3.313a.251.251 0 00.177-.073l.88-.886h10.646a.25.25 0 00.25-.25v-.431h1.441a.262.262 0 00.114-.027l.938-.48h2.5v.258a.25.25 0 00.25.25h1.145a.247.247 0 00.089-.017l1.292-.492h2.782v.8a.25.25 0 00.25.25h1.479a.247.247 0 00.089-.017l2.723-1.03h2.624a.25.25 0 00.25-.25V4.4a.252.252 0 00-.254-.251zM4.875 15.932a.411.411 0 01-.411.411H.911a.412.412 0 01-.411-.411V.91A.411.411 0 01.911.499h3.554a.412.412 0 01.411.411v15.022zm33.227-4.387h-2.42a.247.247 0 00-.089.017l-2.723 1.03h-1.181v-.8a.263.263 0 00-.265-.25h-3.062a.247.247 0 00-.089.017l-1.292.492h-.849v-.258a.25.25 0 00-.25-.25h-2.808a.262.262 0 00-.114.027l-.938.481h-1.38v-.467a.25.25 0 00-.5 0v1.148h-10.5a.251.251 0 00-.177.073l-.88.886H5.376V2.499h3.208l.789.793-.023 5.822a.251.251 0 00.249.251.249.249 0 00.25-.249l.022-5.659h10.271v4.821a.25.25 0 10.5 0V4.141h1.38l.939.479a.248.248 0 00.113.027h2.558v3.632a.25.25 0 00.5 0v-4.14h.849l1.292.492a.247.247 0 00.089.017h2.828v3.631a.25.25 0 00.5 0V3.601h1.183l2.723 1.03a.247.247 0 00.089.017h2.417v6.9z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.25}
				/>
				<path
					data-name="Path 728"
					d="M20.391 8.964a.25.25 0 00-.25.25v1.192a.25.25 0 00.5 0V9.214a.25.25 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.25}
				/>
				<path
					data-name="Path 729"
					d="M31.426 8.964a.25.25 0 00-.25.25v1.192a.25.25 0 00.5 0V9.214a.251.251 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.25}
				/>
				<path
					data-name="Path 730"
					d="M25.879 8.964a.25.25 0 00-.25.25v1.192a.25.25 0 00.5 0V9.214a.25.25 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.25}
				/>
			</g>
		</svg>
	)
}

export default IconFittingFlancheSvg
