import { useNavigate } from "react-router";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { Header, Text } from "../../styled/typography";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";

export const CartEmpty = () => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <Header style={{ paddingBottom: 10 }}>Twój koszyk jest pusty</Header>
            <Text style={{ paddingBottom: 40 }}>Skonfigurowane przewody znajdziesz w tym miejscu</Text>
            <AppButtonRegular
                style={{ width: 345 }}
                bgColor={theme.color.app_blue2}
                color={theme.color.white}
                title="Skonfiguruj przewód"
                onClick={() => navigate("/")}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: "100%";
    margin: 100px 0;
`;
