import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../../constants/Theme";

type ButtonSize = "small" | "medium" | "big";

interface ButtonTypes {
    bgColor?: string;
    color?: string;
    title: string;
    onClick?: any;
    margin?: string;
    size?: ButtonSize;
    style?: any;
    type?: "button" | "submit" | "reset";
    isButtonGroup?: boolean;
    textStyles?: any;
}

export const Button = ({
    margin = "0",
    color = theme.color.white,
    bgColor = theme.color.app_blue2,
    title,
    onClick,
    size = "small",
    style,
    type = "button",
    isButtonGroup = false,
    textStyles,
}: ButtonTypes) => {
    return (
        <Wrapper
            type={type}
            style={style}
            size={size}
            margin={margin}
            bgColor={bgColor}
            onClick={onClick}
            isButtonGroup={isButtonGroup}>
            <Text style={textStyles} size={size} color={color}>
                {title}
            </Text>
        </Wrapper>
    );
};

const Text = styled.p<{ color?: string; size: ButtonSize }>`
    color: ${({ color }) => color};
    font-size: ${({ size }) => (size === "small" ? 12 : size === "medium" ? 15 : 18)}px;
    @media (max-width: 1024px) {
        font-size: ${({ size }) => (size === "small" ? 10 : size === "medium" ? 12 : 15)}px;
    }
`;
const Wrapper = styled.button<{
    bgColor?: string;
    margin: string;
    size: ButtonSize;
    isButtonGroup?: boolean;
}>`
    background-color: ${({ bgColor }) => bgColor};
    padding: ${({ size }) => (size === "small" ? "0 10px" : size === "medium" ? "0 34px" : "0 38px")};
    height: ${({ size }) => (size === "small" ? 26 : size === "medium" ? 40 : 48)}px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: start;
    ${({ isButtonGroup }) =>
        isButtonGroup &&
        css`
            width: 100%;
        `}
    margin: ${({ margin }) => margin};
    border: none;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.02);
    }
    @media (max-width: 1024px) {
        padding: ${({ size }) => (size === "small" ? "0 15px" : size === "medium" ? "0 17px" : "0 19px")};
    }
`;
