import { getOrderByIdService } from './../services/order.service';
import { ADD_ORDER, GET_SINGLE_ORDER } from "../constants/actionNames";
import { addOrderService, addOrderTypes } from "../services/order.service";

export const addOrderAction = (props: addOrderTypes) => ({
    type: ADD_ORDER,
    payload: addOrderService(props),
});
export const getOrderByIdAction = (id: any) => ({
    type: GET_SINGLE_ORDER,
    payload: getOrderByIdService(id),
});
