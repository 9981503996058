import { ShoppingCartOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { getUserDataAction, updateHosesListAction } from "../actions/account.action";
import { AppButtonRegular } from "../components/buttons/App_ButtonRegular";
import { AppButtonText } from "../components/buttons/App_ButtonText";
import { CartAddressElement } from "../components/cart/CartAddressElement";
import { CartConfirmModal } from "../components/cart/CartConfirmModal/CartConfirmModal";
import { CartEmpty } from "../components/cart/CartEmpty";
import { CartInformationElement } from "../components/cart/CartInformationElement";
import { CartInvoiceDataElement } from "../components/cart/CartInvoiceDataElement";
import { CartPriceResumeWrap } from "../components/cart/CartPriceResumeWrap";
import { CartShippingPrice } from "../components/cart/CartShippingPrice";
import { ReusableInfoElementWithEmoji } from "../components/elements/ReusableInfoElementWithEmoji";
import { ListItemCart } from "../components/listElements/ListItem_Cart";
import { theme } from "../constants/Theme";
import { useGuestHosesToCart } from "../hooks/localStoreHosesMaintenance/useGetHosesFromLocalStore/useGetHosesFromLocalStore";
import { useCartTotalPrice } from "../hooks/useCartTotalPrice/useCartTotalPrice";
import { useCartTotalQuantity } from "../hooks/useCartTotalQuantity/useCartTotalQuantity";
import { RootState } from "../reducers";
import { ErrorText, Header, Text } from "../styled/typography";
import { RouteWrapper } from "../styled/wrappers";
import { validateCartAddress } from "../utils/payment and order/validation/validateCartAddress";
import { extractSymbolsFromHosesList } from "../utils/updateHosesList.utils";

export const CartRoute = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState<string>("");
    const [confirmModalVisibility, setConfirmModalVisibility] = useState<boolean>(false);
    const guestHoses = useSelector((state: RootState) => state.guestCart.guestCartHoses);
    const { user, loading, isLogged, tempAddress } = useSelector((state: RootState) => state.account);
    const { userData, hosesList, updatedCartHoses } = user;
    const { guestHosesToCart } = useGuestHosesToCart();
    const { cartTotalPrice, shippingIncluded } = useCartTotalPrice(isLogged ? updatedCartHoses : guestHoses);
    const { cartTotalQuantity } = useCartTotalQuantity(isLogged ? updatedCartHoses : guestHoses);

    const fetchUpdatedHoses = async () => {
        if (isLogged && hosesList) {
            const symbolsString = extractSymbolsFromHosesList(hosesList);
            await dispatch(updateHosesListAction(symbolsString));
        }
    };
    useEffect(() => {
        fetchUpdatedHoses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, hosesList, dispatch]);

    useEffect(() => {
        if (isLogged) {
            dispatch(getUserDataAction(userData.id));
        } else {
            guestHosesToCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderCartData = (listOfHoses: any) => {
        if (listOfHoses?.length > 0) {
            const hoseItems = listOfHoses?.map((item: any, index: any) => (
                <ListItemCart
                    key={index}
                    data={item}
                    hosesList={updatedCartHoses.length ? updatedCartHoses : hosesList}
                />
            ));
            return (
                <>
                    {hoseItems}
                    <AppButtonRegular
                        bgColor={theme.color.app_lightBlue}
                        color={theme.color.app_blue2}
                        title="Skonfiguruj kolejny przewód"
                        borderColor={theme.color.app_blue2}
                        onClick={() => navigate("/")}
                        style={{ marginTop: 35 }}
                    />
                </>
            );
        } else {
            return <CartEmpty />;
        }
    };
    const continueToPayment = async () => {
        const isValid = await validateCartAddress(tempAddress);
        if (isValid !== true) {
            setValidationError(isValid);
            return setTimeout(() => setValidationError(""), 5000);
        }
        return setConfirmModalVisibility(true);
    };

    return (
        <CartContainer>
            <RouteWrapper style={{ minHeight: updatedCartHoses.length < 1 ? "100vh" : "auto" }}>
                <Wrapper>
                    <CartBody>
                        <HeaderCustom>
                            <ShoppingCartOutlined style={{ fontSize: 28, marginRight: 10 }} />
                            Koszyk
                        </HeaderCustom>
                        {isLogged && (
                            <LoaderWrap>
                                <MoonLoader color={"black"} loading={loading} size={30} speedMultiplier={0.4} />
                            </LoaderWrap>
                        )}
                        {!loading && renderCartData(isLogged ? updatedCartHoses : guestHoses)}
                    </CartBody>
                </Wrapper>
            </RouteWrapper>
            <SummaryBody>
                <HeaderCustom>Wysyłka i płatność</HeaderCustom>
                <CartAddressElement />
                <CartInvoiceDataElement />
                <CartInformationElement />
                <CartShippingPrice shippingIncluded={shippingIncluded} cartTotalQuantity={cartTotalQuantity} />
                <CartPriceResumeWrap price={cartTotalPrice} description="Do zapłaty" />

                {validationError && (
                    <ErrorText style={{ padding: 10 }}>
                        Uzupełnij adres wysyłki! <br />
                        {validationError}
                    </ErrorText>
                )}

                <AppButtonRegular title="Zamawiam i płacę" onClick={continueToPayment} style={{ marginBottom: 34 }} />

                <ReusableInfoElementWithEmoji
                    infoRowStyle={{ alignItems: "flex-start" }}
                    infos={[
                        {
                            emoji: "📑",
                            description: (
                                <>
                                    <Text style={{ marginBottom: 6, fontWeight: 700 }}>
                                        Płatność zbiorcza raz w miesiącu
                                    </Text>
                                    <Text>
                                        Dla naszych klientów biznesowych oferujemy wygodną opcję płatności zbiorczej.
                                        Raz w miesiącu możemy wystawić Ci zbiorczą fakturę za wszystkie zrealizowane
                                        zamówienia.
                                        <AppButtonText
                                            onClick={() =>
                                                window.open(
                                                    `https://zakuwanie24.pl/zakuwanie24-warunki-kredytu-kupieckiego/`,
                                                    "_blank"
                                                )
                                            }
                                            title={"Dowiedz się więcej"}
                                            style={{ textAlign: "end", marginTop: 5 }}
                                        />
                                    </Text>
                                </>
                            ),
                        },
                    ]}
                />
            </SummaryBody>
            <CartConfirmModal
                cartTotalPrice={cartTotalPrice}
                shippingIncluded={shippingIncluded}
                visibility={confirmModalVisibility}
                setVisibility={(e: any) => setConfirmModalVisibility(e)}
            />
        </CartContainer>
    );
};

const CartContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    @media (min-width: 1000px) {
        flex-direction: row;
    }
`;
const LoaderWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
`;

const HeaderCustom = styled(Header)`
    margin: 20px 0;
`;
const SummaryBody = styled.div`
    box-sizing: border-box;
    padding: 0 17px 17px 17px;
    background-color: ${({ theme }) => theme.color.transparent};
    @media (min-width: 1024px) {
        width: 412px;
        padding: 0;
        padding-left: 42px;
        padding-right: 42px;
    }
    @media (min-width: 1100px) {
        padding-right: 0px;
    }
`;
const CartBody = styled.div`
    padding: 0 17px 17px;
    background-color: ${({ theme }) => theme.color.white};
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.white};
    box-sizing: border-box;
    @media (min-width: 619px) {
        width: 612px;
    }
`;
