import { Link } from "react-router-dom";
import styled from "styled-components";
import packageJson from "../../../package.json";

export const Footer = () => {
    return (
        <FooterWrap>
            <Top>
                <a
                    style={{ textDecoration: "none" }}
                    href="https://zakuwanie24.pl/baza-wiedzy"
                    target="_blank"
                    rel="noreferrer">
                    <Text>Potrzebujesz pomocy?</Text>
                </a>
                <a style={{ textDecoration: "none" }} href="https://zakuwanie24.pl/" target="_blank" rel="noreferrer">
                    <Text>O nas</Text>
                </a>
                <Link style={{ textDecoration: "none" }} to="/">
                    <Text>Konfigurator</Text>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/Regulamin">
                    <Text>Regulamin</Text>
                </Link>
                <Link style={{ textDecoration: "none" }} to="/PrivacyPolicy">
                    <Text>Polityka Prywatności</Text>
                </Link>
            </Top>
            <Bottom>
                <Text>© 2023 zakuwanie24.pl</Text>
                <Version>
                    v{packageJson.version} {process.env.REACT_APP_ENVIRONMENT}
                </Version>
            </Bottom>
        </FooterWrap>
    );
};
const Version = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    color: ${({ theme }) => theme.color.app_lightBlue};
    font-size: 10px;
`;
const Text = styled.p`
    color: white;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 600px) {
        margin-top: 20px;
    }
`;
const Top = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: 600px) {
        flex-direction: column;
        height: 160px;
    }
`;
const Bottom = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;
const FooterWrap = styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.app_blue2};
`;
