import { FETCH_HOSES, FETCH_HOSE_DIAMETERS, FETCH_HOSE_DNS } from "../constants/actionNames";

const INITIAL_STATE = {
    fetched: false,
    loading: false,
    error: false,
    hoseDiameter: [],
    hoseDns: [],
    hoses: [],
};
export const hoseReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_HOSE_DIAMETERS + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_HOSE_DIAMETERS + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_HOSE_DIAMETERS + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                hoseDiameter: action.payload.data,
            };
        case FETCH_HOSE_DNS + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_HOSE_DNS + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_HOSE_DNS + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                hoseDns: parsedData(action.payload.data),
            };
        case FETCH_HOSES + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_HOSES + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_HOSES + "_FULFILLED":
            return { ...state, loading: false, error: false, fetched: true, hoses: action.payload.data };
        default:
            return state;
    }
};

const parsedData = (data: any) =>
    Object.entries(data).map((entry) => {
        return { diameter: entry[0], hoses: entry[1] };
    });
