export const infosList = [
    {
        emoji: "🏷️",
        description:
            "Każdy zamówiony przewód posiada żółtą plakietkę z QR kodem. Zeskanuj ją naszą aplikacją, aby zobaczyć parametry przewodu i zamówić taki sam.",
    },
    {
        emoji: "📑",
        description:
            "W zakładce “Moje Zamówienia” możesz pobrać PDF z dokumentacją zamówionego przewodów (dotyczy tylko zalogowanych użytkowników).",
    },
    {
        emoji: "📦",
        description: "Dla wszystkich zamówień o wartości powyżej 150 złotych, dostawa jest zupełnie darmowa.",
    },
    {
        emoji: "💲",
        description: "Dzięki sprzedaży przez internet nasze ceny są niższe niż u konkurencji.",
    },
    {
        emoji: "📅",
        description: (
            <>
                Dla klientów biznesowych oferujemy płatność zbiorczą raz w miesiącu za wszystkie zamówione węże.{" "}
                <strong>
                    <a
                        href="https://zakuwanie24.pl/zakuwanie24-warunki-kredytu-kupieckiego/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Zobacz więcej
                    </a>
                </strong>
                .
            </>
        ),
    },

    {
        emoji: "📲",
        description: "Uzupełnij numer telefonu w profilu i korzystaj z logowania bez hasła dzięki kodom SMS.",
    },
    {
        emoji: "🔍",
        description:
            "Na każdym etapie konfiguracji widzisz dokładne ceny poszczególnych komponentów – bez ukrytych kosztów, pełna transparentność.",
    },
    {
        emoji: "🔄",
        description:
            "Jeśli któryś z przewodów często ulega awarii zamów od razu dwa egzemplarze. Dzięki kodowi QR, szybko odnajdziesz zapas w magazynie i unikniesz przestoju.",
    },
    {
        emoji: "📱",
        description: "Nasza aplikacja ze skanerem kodów jest darmowa i dostępna w sklepach AppStore i Google Play.",
    },
    {
        emoji: "📞",
        description: (
            <>
                Zauważyłeś błąd lub brakuje Ci czegoś w naszej ofercie? Twój feedback jest dla nas cenny!
                <br />
                Napisz do nas na <strong>info@zakuwanie24.pl</strong> lub zadzwoń <strong>603 304 530</strong>
            </>
        ),
    },
];
