import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconArrowLeftSvg from "../../icons/Icon_ArrowLeftSvg";
import IconArrowRight from "../../icons/Icon_ArrowRight";
import { AppButtonBorderWithIcon } from "../buttons/App_ButtonBorderWithIcon";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { ParamTabHose } from "../elements/ParamTabHose";

const FittingSelectModal = ({ isOpen, setOpen, data, selectFitting }: any) => {
    const dimensions = data?.wymiary_gwintu.split("_").map((item: any) => {
        const arr = item.split(":");
        return { name: arr[0], value: arr[1] };
    });
    // const navigate = useNavigate();
    const selectCurrentFitting = () => {
        selectFitting(data);
    };
    const firstImage = data?.obrazki[0];
    const restImages = data?.obrazki?.slice(1);
    return (
        <div>
            <ReactModal
                className="modalBody"
                overlayClassName="modalAppearance"
                isOpen={isOpen}
                ariaHideApp={false}
                contentLabel="onRequestClose Example"
                onRequestClose={() => setOpen(false)}
                shouldCloseOnOverlayClick={true}>
                <ModalContentWrapper>
                    <FixedWrap>
                        <HeaderWrap>
                            <HeaderTitle>{data?.nazwa}</HeaderTitle>
                            <HeaderSymbol>{data?.symbol}</HeaderSymbol>
                        </HeaderWrap>
                        <DimensionsWrap>
                            <ParamsWrap>
                                {dimensions?.map((item: any, index: any) => (
                                    <ParamTabHose
                                        key={index}
                                        title={item?.name + ":"}
                                        value={item?.value}
                                        marginRight={11}
                                    />
                                ))}
                            </ParamsWrap>
                        </DimensionsWrap>
                    </FixedWrap>
                    <div
                        style={{
                            width: "100%",
                            boxSizing: "border-box",
                            paddingLeft: 5,
                            paddingRight: 5,
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                        <AppButtonBorderWithIcon
                            onClick={() => window.open(`https://zakuwanie24.pl/rodzaje-uszczelnien/`, "_blank")}
                            description="Jak rozpoznać system połączeń?"
                            icon={<IconArrowRight />}
                        />
                    </div>
                    <Image src={`${process.env.REACT_APP_API_URL}/${firstImage}`} alt={"firstImage"} />

                    <Wrapper>
                        <Element
                            style={{
                                borderRightWidth: 1,
                                borderRightColor: theme.color.app_grey,
                                borderRightStyle: "solid",
                            }}>
                            <div style={{ transform: "rotate(-90deg)" }}>
                                <IconArrowLeftSvg COLOR={theme.color.app_blue} SIZE={10} />
                            </div>
                            <Text>Gwint współpracujący</Text>
                        </Element>
                        <Element>
                            <div style={{ transform: "rotate(-90deg)" }}>
                                <IconArrowLeftSvg COLOR={theme.color.app_blue} SIZE={10} />
                            </div>
                            <Text>Wybrane zakucie</Text>
                        </Element>
                    </Wrapper>
                    {restImages?.map((item: string) => (
                        <Image src={`${process.env.REACT_APP_API_URL}/${item}`} alt={"image"} />
                    ))}
                    <ButtonsWrap>
                        <AppButtonRegular
                            title="Wróć"
                            bgColor={theme.color.app_lightBlue}
                            borderColor={theme.color.app_blue2}
                            color={theme.color.app_blue2}
                            onClick={() => setOpen(false)}
                        />
                        <div style={{ width: 8 }} />
                        <AppButtonRegular title="Wybierz" onClick={selectCurrentFitting} />
                    </ButtonsWrap>
                </ModalContentWrapper>
            </ReactModal>
        </div>
    );
};

export default FittingSelectModal;

const Image = styled.img`
    width: 100%;
    object-fit: contain;
`;
const FixedWrap = styled.div`
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 16px;
    width: 100%;
`;
const ParamsWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 6px;
    padding-bottom: 6px;
`;
const DimensionsWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    width: 100%;
    box-sizing: border-box;
`;
const HeaderSymbol = styled.p`
    font-weight: 400;
    font-size: 10px;
    color: ${({ theme }) => theme.color.app_grey2};
    text-transform: uppercase;
    text-align: right;
    margin: 0;
`;
const HeaderTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
    max-width: 66%;
`;
const HeaderWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 11px 16px;
    box-sizing: border-box;
    width: 100%;
`;
export const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -1px 15px ${({ theme }) => theme.color.app_grey6};
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const Element = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
const Wrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    border-top: 1px solid ${({ theme }) => theme.color.app_grey};
`;

const Text = styled.p`
    margin: 0;
    text-align: center;
    font-size: 13px;
    color: ${({ theme }) => theme.color.app_grey2};
    font-weight: 400;
    margin-left: 10px;
`;
