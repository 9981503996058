import styled from "styled-components";

const parseStock = (stockString) => {
    return parseFloat(stockString?.replace(",", "."));
};

export const CartProductAvailability = ({ item }) => {
    const { hoseDetails, leftFittingDetails, rightFittingDetails, quantity, length } = item;
    const hoseStock = parseStock(hoseDetails?.stan_magazynowy);
    const hoseStockInCentimeters = hoseStock * 100;
    const leftFittingStock = parseStock(leftFittingDetails?.stan_magazynowy);
    const rightFittingStock = parseStock(rightFittingDetails?.stan_magazynowy);
    const totalHoseLength = parseFloat((parseFloat(length?.replace(",", ".")) * quantity).toFixed(2));
    const totalLeftFittingQuantity = quantity;
    const totalRightFittingQuantity = quantity;

    const isHoseExceeded = totalHoseLength > hoseStockInCentimeters;
    const isLeftFittingExceeded = totalLeftFittingQuantity > leftFittingStock;

    const isRightFittingExceeded = totalRightFittingQuantity > rightFittingStock;
    const isSameFitting = leftFittingDetails?.symbol === rightFittingDetails?.symbol;

    const fittingsAreTheSame = () => {
        const totalFittingQuantity = totalLeftFittingQuantity * 2;

        const fittingStock = isSameFitting ? leftFittingStock : Math.min(leftFittingStock, rightFittingStock);

        const isFittingExceeded = totalFittingQuantity > fittingStock;
        if (isSameFitting && isFittingExceeded) {
            return (
                <StockStatus>
                    Stan magazynowy przekroczony dla zakucia. {"\n"}Dostępne: {fittingStock}, zamówione:{" "}
                    {totalFittingQuantity}.
                </StockStatus>
            );
        }
    };
    return (
        <>
            {(isHoseExceeded || isLeftFittingExceeded || isRightFittingExceeded) && (
                <Wrapper>
                    {isHoseExceeded && (
                        <StockStatus>
                            Stan magazynowy przekroczony dla węża. <br />
                            Dostępne: {hoseStockInCentimeters} cm, zamówione łącznie: {totalHoseLength} cm.
                        </StockStatus>
                    )}

                    {isLeftFittingExceeded && !isSameFitting && (
                        <StockStatus>
                            Stan magazynowy przekroczony dla lewego zakucia {leftFittingDetails.nazwa}. <br />
                            Dostępne: {leftFittingStock} , zamówione: {totalLeftFittingQuantity}.
                        </StockStatus>
                    )}
                    {isRightFittingExceeded && !isSameFitting && (
                        <StockStatus>
                            Stan magazynowy przekroczony dla prawego zakucia {rightFittingDetails.nazwa}. <br />
                            Dostępne: {rightFittingStock}, zamówione: {totalRightFittingQuantity}.
                        </StockStatus>
                    )}
                    {fittingsAreTheSame()}

                    <StockStatus style={{ marginTop: 5, color: "black" }}>
                        Jeśli zamówiona ilość przekracza stan magazynowy, wysyłka może ulec opóźnieniu. Aby uniknąć
                        opóźnień, rozważ zamówienie dostępnych produktów teraz, a resztę osobno lub zmniejszenie ilości
                        zamówienia.
                    </StockStatus>
                </Wrapper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    padding: 6px;
    margin: 15px 16px 0;
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.color.app_blue};
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;

const StockStatus = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.color.app_red};
    font-weight: 500;
`;
