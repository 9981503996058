import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useExceededItems } from "../../../hooks/useExceededItems/useExceededItems";
import IconCloseSvg from "../../../icons/Icon_CloseSvg";
import { RootState } from "../../../reducers";
import { CartConfirm } from "./CartConfirm";
import { ExceededItemsInfo } from "./ExceededItemsInfo";

export const CartConfirmModal = ({ visibility, setVisibility, cartTotalPrice, shippingIncluded }: any) => {
    const { isLogged } = useSelector((state: RootState) => state.account);

    const { updatedCartHoses } = useSelector((state: RootState) => state.account.user);
    const { guestCartHoses } = useSelector((state: RootState) => state.guestCart);
    const exceededItems = useExceededItems(isLogged ? updatedCartHoses : guestCartHoses);
    const [itemsAreExceeded, setItemsAreExceeded] = useState<boolean>(false);

    useEffect(() => {
        if (exceededItems.length > 0) {
            return setItemsAreExceeded(true);
        } else {
            return setItemsAreExceeded(false);
        }
    }, [visibility, exceededItems]);
    return (
        <ModalOpacity onClick={() => setVisibility(false)} style={{ display: visibility ? "flex" : "none" }}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <CloseBtnWrap>
                    <CloseBtn onClick={() => setVisibility(false)}>
                        <IconCloseSvg />
                    </CloseBtn>
                </CloseBtnWrap>
                {itemsAreExceeded ? (
                    <ExceededItemsInfo
                        setItemsAreExceeded={() => setItemsAreExceeded(false)}
                        exceededItems={exceededItems}
                        setVisibility={setVisibility}
                    />
                ) : (
                    <CartConfirm
                        cartTotalPrice={cartTotalPrice}
                        shippingIncluded={shippingIncluded}
                        setVisibility={setVisibility}
                    />
                )}
            </Wrapper>
        </ModalOpacity>
    );
};

const CloseBtnWrap = styled.div`
    height: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const CloseBtn = styled.div`
    cursor: pointer;
`;
const Wrapper = styled.div`
    height: 100%;
    width: 100vw;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 36px;
    @media (min-width: 1000px) {
        margin-top: 150px;
        height: auto;
        width: 500px;
    }
`;
const ModalOpacity = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000030;
    backdrop-filter: blur(1.2px);
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
    z-index: 2;
`;
