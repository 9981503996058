import axios from "../utils/axiosInterceptor";

export interface addHoseToCartServiceTypes {
    hoseSymbol: string;
    leftFittingSymbol: string;
    rightFittingSymbol: string;
    sleeveSymbol: string;
    length: string;
    ending: string;
    clientId: number;
    cover?: string;
}
export interface getHoseByIdTypes {
    qrCode: string | number;
}
export interface changeHosesUserByIdTypes {
    userId: string;
    hoseId: string;
}

export const addHoseToCartService = async ({
    hoseSymbol,
    leftFittingSymbol,
    rightFittingSymbol,
    sleeveSymbol,
    length,
    ending,
    clientId,
    cover,
}: addHoseToCartServiceTypes) => {
    const coverParam = cover ? `&cover=${cover}` : "";
    console.log("coverParam", coverParam);
    return await axios.get(
        `/add-hose.php?hose=${hoseSymbol}&left_fitting=${leftFittingSymbol}&right_fitting=${rightFittingSymbol}&sleeve=${sleeveSymbol}&length=${length}&ending=${ending}&client-id=${clientId}${coverParam}`
    );
};
export const addHoseWithSameQRToCartService = async ({ hoseSymbol, userId }: any) => {
    return await axios.get(`add-existing-to-cart.php?userId=${userId}&hoseSymbol=${hoseSymbol}`);
};

export const getHoseByIdService = ({ qrCode }: getHoseByIdTypes) => {
    return axios.get(`/get-hose.php?id=${qrCode}`);
};

export const removeHoseByIdService = ({ clientId, hoseId }: any) => {
    return axios.get(`/remove-hose.php?client-id=${clientId}&hose-id=${hoseId}`);
};

export const changeHosesUserByIdService = ({ userId, hoseId }: changeHosesUserByIdTypes) => {
    return axios.get(`/change-user-for-hose.php?user=${userId}&hoses=${hoseId}`);
};
