import React from "react";
import { theme } from "../../constants/Theme";
import IconFittingConicalSvg from "../../icons/Icon_FittingConicalSvg";
import IconFittingEyeSvg from "../../icons/Icon_FittingEyeSvg";
import IconFittingFlancheSvg from "../../icons/Icon_FittingFlancheSvg";
import IconFittingInnerSvg from "../../icons/Icon_FittingInnerSvg";
import IconFittingOuterSvg from "../../icons/Icon_FittingOuterSvg";
import IconFittingUnusualSvg from "../../icons/Icon_FittingUnusualSvg";

export const fittingTypes = [
    {
        type: "wewnetrzne",
        title: "Gwint wewnętrzny",
        icon: <IconFittingInnerSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
    {
        type: "zewnetrzne",
        title: "Gwint zewnętrzny",
        icon: <IconFittingOuterSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
    {
        type: "stozkowe",
        title: "Gwint zewnętrzny stożkowy",
        icon: <IconFittingConicalSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
    {
        type: "flansza",
        title: "Flansza",
        icon: <IconFittingFlancheSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
    {
        type: "oczko",
        title: "Oczko",
        icon: <IconFittingEyeSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
    {
        type: "nietypowe",
        title: "Nietypowe",
        icon: <IconFittingUnusualSvg COLOR={theme.color.app_blue} SIZE={50} />,
    },
];

