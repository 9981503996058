import * as React from "react"

function IconPressureWorkSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 30.132 30.132"
		>
			<path
				data-name="Path 26"
				d="M15.911.001a15.09 15.09 0 0114.221 14.22h-3.247A11.85 11.85 0 0015.911 3.247V.001zm-.845 5.15a9.916 9.916 0 11-9.916 9.916 9.916 9.916 0 019.916-9.916zm.919 7.489a2.585 2.585 0 11-1.84 0V8.693a.92.92 0 011.84 0zm-.92 1.67a.746.746 0 10.746.746.739.739 0 00-.746-.748zm-.843-11.061A11.85 11.85 0 003.248 14.221H.004A15.091 15.091 0 0114.222.001v3.247zm15.91 12.663a15.091 15.091 0 01-14.221 14.22v-3.247A11.85 11.85 0 0026.885 15.91zm-15.91 14.22A15.091 15.091 0 01.004 15.91h3.248a11.85 11.85 0 0010.97 10.975z"
				fill={COLOR}
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default IconPressureWorkSvg
