import { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconAngleSvg from "../../icons/Icon_AngleSvg";
import IconDiameterOuterSvg from "../../icons/Icon_DiameterOuterSvg";
import IconDownloadSvg from "../../icons/Icon_DownloadSvf";
import IconPressureMaxSvg from "../../icons/Icon_PressureMaxSvg";
import IconPressureWorkSvg from "../../icons/Icon_PressureWorkingSvg";
import IconTemperatureSvg from "../../icons/Icon_TemperatureSvg";
import IconWeightSvg from "../../icons/Icon_WeightSvg";
import IconWiresSvg from "../../icons/Icon_WiresSvg";
import { AppButtonBorderWithIcon } from "../buttons/App_ButtonBorderWithIcon";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { HoseParameterInModal } from "../elements/HoseParameterInModal";
import { Specification } from "./Specification";
import "./modalAppearance.css";

const HoseSelectModal = ({ isOpen, setOpen, data, selectHose }: any) => {
    const iconSize = 16;
    const iconColor = theme.color.app_blue;
    const [specificationVisibility, setSpecificationVisibility] = useState<boolean>(false);

    return (
        <div>
            <ReactModal
                className="modalBody"
                overlayClassName="modalAppearance"
                isOpen={isOpen}
                ariaHideApp={false}
                contentLabel="onRequestClose Example"
                onRequestClose={() => setOpen(false)}
                shouldCloseOnOverlayClick={true}>
                {specificationVisibility && (
                    <Specification
                        setSpecificationVisibility={(e: any) => setSpecificationVisibility(e)}
                        images={data?.obrazki}
                    />
                )}

                <ModalContentWrapper>
                    <HeaderWrap>
                        <HeaderTitle>{data?.nazwa}</HeaderTitle>
                        <HeaderSymbol>{data?.symbol}</HeaderSymbol>
                    </HeaderWrap>

                    <ImageWrap>
                        <Image src={`${process.env.REACT_APP_API_URL}/${data?.obrazki[1]}`} alt="hose" />
                    </ImageWrap>
                    <Description>{data?.krotki_opis}</Description>
                    {data?.obrazki[2] ? (
                        <AppButtonBorderWithIcon
                            onClick={() => setSpecificationVisibility(true)}
                            description="Pobierz kartę katalogową i certyfikat przewodu"
                            icon={<IconDownloadSvg />}
                            noSideBorder
                        />
                    ) : (
                        <p style={{ backgroundColor: "red" }}>brak dokumentacji</p>
                    )}
                    <Title>Parametry:</Title>
                    <ParamsWrap>
                        <HoseParameterInModal
                            title="Oploty"
                            icon={<IconWiresSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.oploty}
                        />
                        <HoseParameterInModal
                            title="Ciśnienie robocze"
                            icon={<IconPressureWorkSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.cisnienie_robocze + " bar"}
                        />
                        <HoseParameterInModal
                            title="Ciśnienie rozrywania"
                            icon={<IconPressureMaxSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.cisnienie_rozrywania + " bar"}
                        />
                        <HoseParameterInModal
                            title="Temperatura pracy"
                            icon={<IconTemperatureSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.temperatura_pracy}
                        />
                        <HoseParameterInModal
                            title="Średnica zewnętrzna"
                            icon={<IconDiameterOuterSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.srednica_zewnetrzna + " mm"}
                        />
                        <HoseParameterInModal
                            title="Waga 1 metra"
                            icon={<IconWeightSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.waga + " kg"}
                        />
                        <HoseParameterInModal
                            title="Promień gięcia"
                            icon={<IconAngleSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.promien_giecia + " mm"}
                        />
                        <HoseParameterInModal
                            title="Cena za metr"
                            icon={<IconDiameterOuterSvg SIZE={iconSize} COLOR={iconColor} />}
                            value={data?.cena + " zł"}
                        />
                    </ParamsWrap>
                    <ButtonsWrap>
                        <AppButtonRegular
                            title="Wróć"
                            onClick={() => setOpen(false)}
                            bgColor={theme.color.app_lightBlue}
                            borderColor={theme.color.app_blue2}
                            color={theme.color.app_blue2}
                        />
                        <div style={{ width: 10 }} />

                        <AppButtonRegular title="Wybierz" onClick={() => selectHose(data)} />
                    </ButtonsWrap>
                </ModalContentWrapper>
            </ReactModal>
        </div>
    );
};

export default HoseSelectModal;

export const ButtonsWrap = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 18px 18px 18px 18px;
    position: sticky;
    bottom: 0;
    background-color: white;
    /* @media (min-width: 600px) {
        position: static;
    } */
`;
const ParamsWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const Title = styled.p`
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
    align-self: flex-start;
    padding: 18px;
`;
const Description = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ theme }) => theme.color.black};
    padding: 18px;
    margin: 0;
`;
const Image = styled.img`
    max-height: 75px;
`;
const ImageWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    padding: 15px 0 20px 0;
    width: 100%;
`;
const HeaderSymbol = styled.p`
    font-weight: 400;
    font-size: 10px;
    color: ${({ theme }) => theme.color.app_grey2};
    text-transform: uppercase;
    margin: 0;
`;
const HeaderTitle = styled.p`
    font-weight: 700;
    font-size: 18px;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
    width: 66%;
`;
const HeaderWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 11px 18px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
    box-sizing: border-box;
    width: 100%;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 13px 0 0 0;
`;
