import React from "react";
import styled from "styled-components";
import { theme } from "../../constants/Theme";

interface Types {
    title: string;
    onClick: any;
    color?: any;
    bgColor?: any;
    borderColor?: any;
    disable?: boolean;
    style?: any;
    icon?: any;
    titleStyle?: any;
}
export const AppButtonRegular = ({
    title,
    onClick,
    color = theme.color.white,
    bgColor = theme.color.app_blue2,
    borderColor = theme.color.transparent,
    disable = false,
    style,
    titleStyle,
    icon,
}: Types) => {
    return (
        <Wrap
            style={{ backgroundColor: disable ? theme.color.app_grey7 : bgColor, borderColor: borderColor, ...style }}
            onClick={disable ? undefined : onClick}>
            <Title style={{ color: color, ...titleStyle }}>{title}</Title>
            <Icon>{icon}</Icon>
        </Wrap>
    );
};
const Icon = styled.div``;
const Title = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.white};
    font-size: 12px;
    font-weight: 700;
`;
const Wrap = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1px solid;
    cursor: pointer;
`;
