import React from "react";
import styled from "styled-components";

interface ParamTabLongTypes {
    value: string | number | any;
    icon: any;
    title: string;
}
export const HoseParameterInModal = ({ title, value, icon }: ParamTabLongTypes) => {
    return (
        <Wrapper>
            <Wrap>
                {icon}
                <Title>{title}</Title>
            </Wrap>
            <Value>{value}</Value>
        </Wrapper>
    );
};

const Value = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
`;
const Title = styled.p`
    margin: 0 0 0 15px;
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.black};
`;
const Wrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey6};
    padding: 12px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
