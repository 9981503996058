import { Rating } from "react-simple-star-rating";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { stockAmountFitting } from "../../utils/stockAmount";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { ParamTabHose } from "../elements/ParamTabHose";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { FittingLSAlert } from "../elements/FittingLSAlert";
interface Types {
    openModal: any;
    data: any;
    selectFitting: any;
    isOpen?: boolean;
}

const ListItemFittingSelect = ({ openModal, data, selectFitting, isOpen }: Types) => {
    const { user } = useSelector((state: RootState) => state.account);
    const isLS = data?.krotki_opis.split("_")[1];
    const dimensions = data?.wymiary_gwintu.split("_").map((item: any) => {
        const arr = item.split(":");
        return { name: arr[0], value: arr[1] };
    });
    const stockAmount = parseFloat(data?.stan_magazynowy.replace(",", "."));

    return (
        <Wrapper
            style={{
                borderColor: isOpen ? theme.color.app_blue2 : theme.color.app_grey6,
            }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <ContentWrap>
                    <Title>{data?.nazwa}</Title>
                    <Description> {data?.symbol}</Description>
                    <Params style={{ fontSize: 12, fontWeight: 500 }}> Cena {data?.cena} zł </Params>
                </ContentWrap>
                <RatingWrap>
                    <RatingHeader>popularność</RatingHeader>
                    <Rating
                        size={20}
                        initialValue={data?.krotki_opis.split("_")[0]}
                        fillColor={theme.color.app_blue2}
                        emptyColor={theme.color.app_lightBlue}
                        readonly
                    />
                </RatingWrap>
            </div>
            <ParamsAndImageWrap>
                <ParamsWrapper>
                    <>
                        {dimensions?.map((item: any, index: any) => (
                            <ParamTabHose key={index} title={item?.name + ":"} value={item?.value} />
                        ))}
                    </>
                    <AvailabilityWrapper>
                        <Availability stockAmount={stockAmount}>{stockAmountFitting(stockAmount)}</Availability>
                    </AvailabilityWrapper>
                </ParamsWrapper>
                <div>
                    <ImageWrap onClick={() => openModal({ state: true, symbol: data?.symbol })}>
                        <Image src={`${process.env.REACT_APP_API_URL}/${data?.obrazki[0]}`} alt="hoseExample" />
                    </ImageWrap>
                </div>
            </ParamsAndImageWrap>
            {user?.userData?.id === "72" && (
                <>
                    <p style={{ fontSize: 12 }}>{data?.symbol + " || stan: " + data?.stan_magazynowy}</p>
                </>
            )}
            {isLS && <FittingLSAlert type={isLS} />}
            <ButtonsWrap>
                <AppButtonRegular
                    borderColor={theme.color.app_blue}
                    title="Zobacz szczegóły"
                    bgColor={theme.color.app_lightBlue}
                    color={theme.color.app_blue2}
                    onClick={() => openModal({ state: true, symbol: data?.symbol })}
                />
                <div style={{ width: 8 }} />
                <AppButtonRegular title="Wybierz" onClick={() => selectFitting(data)} />
            </ButtonsWrap>
        </Wrapper>
    );
};

export default ListItemFittingSelect;

const AvailabilityWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    width: 100%;
    margin: 2px 0 10px 0;
`;
const Availability = styled.div<{ stockAmount: any }>`
    text-align: right;
    padding: 4px 10px 6px;
    font-size: 11px;
    background-color: ${({ theme, stockAmount }) => {
        if (stockAmount > 4) {
            return theme.color.app_lightBlue;
        } else if (stockAmount > 0 && stockAmount <= 4) {
            return theme.color.app_yellow;
        } else {
            return theme.color.app_lightRed;
        }
    }};
    color: ${({ theme, stockAmount }) => {
        if (stockAmount > 4) {
            return theme.color.app_blue;
        } else if (stockAmount > 0 && stockAmount <= 4) {
            return theme.color.app_black;
        } else {
            return theme.color.app_white;
        }
    }};
    border-radius: 5px;
    font-weight: 600;
`;
export const DimensionsTitle = styled.p`
    font-size: 10px;
    color: ${({ theme }) => theme.color.black};
    margin: 10px 0 3px 0;
    font-weight: 500;
`;
export const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
`;
export const ButtonsWrapMobile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
`;
const RatingWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
const RatingHeader = styled.p`
    font-size: 11px;
    margin-top: -5px;
    color: ${({ theme }) => theme.color.black};
`;
const ParamsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 16px;
    height: 100%;
`;
const Description = styled.p`
    font-size: 10px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_grey2};
    margin: 0;
`;
const Params = styled(Description)`
    color: ${({ theme }) => theme.color.black};
    margin-top: 8px;
`;
const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    margin: 0;
    margin-bottom: 8px;
`;
const ContentWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
const ParamsAndImageWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 16px;
    margin-bottom: 10px;
`;
const ImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    border: 1px solid ${({ theme }) => theme.color.app_grey};
    height: 180px;
    padding: 0 5px;
    overflow: hidden;
`;
const Image = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
    transition: transform 0.2s;
    cursor: pointer;
    &:hover {
        transform: scale(1.05);
    }
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    /* border: 1px solid ${({ theme }) => theme.color.app_grey5}; */
    border-width: 1px;
    border-style: solid;
    border-top: none;
    width: 100%;
    height: 100%;
`;
