import YouTube from "react-youtube";
import styled from "styled-components";
import appStore from "../../assets/storeButtons/appStore.png";
import playStore from "../../assets/storeButtons/playStore.png";
import { theme } from "../../constants/Theme";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { QrCodeWrite } from "../qrCodeWrite/QrCodeWrite";
import { Text } from "../../styled/typography";

const opts = {
    width: "100%",
    height: "220",
    playerVars: {
        autoplay: 0,
    },
    async: 1,
};

export const Intro = () => {
    return (
        <Wrapper>
            <ReusableInfoElementWithEmoji
                style={{ marginBottom: 10 }}
                infos={[
                    {
                        emoji: "🕚",
                        description:
                            "Ekspresowa wysyłka! Zamówienia złożone do godziny 11:00 są wysyłane tego samego dnia.",
                    },
                    {
                        emoji: "🏷️",
                        description: "Wszystkie przewody które zamawiasz są wyposażone w etykiety z kodami QR.",
                    },
                    {
                        emoji: "📲",
                        description: "Następnym razem zeskanuj kod QR naszą apką, aby zamówić identyczny produkt!",
                    },
                    {
                        emoji: "📞",
                        description: (
                            <div>
                                <Text>
                                    Potrzebujesz pomocy? Zadzwoń do nas od 7:30 do 16:00 pod numer
                                    <span style={{ fontWeight: 600 }}> 885 443 185 </span> lub napisz
                                    <span style={{ fontWeight: 600 }}> info@zakuwanie24.pl</span>
                                </Text>
                            </div>
                        ),
                    },
                ]}
            />
            <ReusableInfoElementWithEmoji
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: theme.color.white,
                    borderColor: theme.color.app_lightBlue,
                }}
                infos={[
                    {
                        emoji: "",
                        description: (
                            <TitleAndImageWrap>
                                <Header style={{ marginBottom: 15 }}>Sprawdź przewód za pomocą kodu</Header>
                                <QrCodeWrite />
                            </TitleAndImageWrap>
                        ),
                    },
                ]}
            />
            <ReusableInfoElementWithEmoji
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: theme.color.white,
                    borderColor: theme.color.app_lightBlue,
                }}
                infos={[
                    {
                        emoji: "",
                        description: (
                            <TitleAndImageWrap>
                                <Header>Poznaj bliżej nasz konfigurator</Header>
                            </TitleAndImageWrap>
                        ),
                    },
                    {
                        emoji: "",
                        description:
                            "Zobacz jak nasze rozwiązanie ułatwia projektowanie węży hydraulicznych, prowadząc Cię krok po kroku przez cały proces konfiguracji.",
                    },
                    {
                        emoji: "",
                        description: (
                            <div style={{ marginRight: 15 }}>
                                <YouTube videoId="OU4-W6wa458" opts={opts} />
                            </div>
                        ),
                    },
                ]}
            />
            <ReusableInfoElementWithEmoji
                style={{
                    backgroundColor: theme.color.white,
                    borderColor: theme.color.app_lightBlue,
                    marginTop: 10,
                }}
                infos={[
                    {
                        emoji: "",
                        description: (
                            <TitleAndImageWrap>
                                <Header>Wypróbuj aplikację mobilną</Header>
                                <TextBigger style={{ marginTop: 15 }}>
                                    Pobierz naszą aplikację mobilną Zakuwanie24. Nakieruj kamerę na plakietkę z kodem
                                    QR, aby zobaczyć szczegóły przewodu i zamówić go ponownie.
                                </TextBigger>
                            </TitleAndImageWrap>
                        ),
                    },
                    {
                        emoji: "",
                        description: (
                            <AppStoreBadges>
                                <StoreImg
                                    onClick={() =>
                                        window.open("https://apps.apple.com/pl/app/zakuwanie24/id6444747885?l=pl")
                                    }
                                    src={appStore}
                                    alt="appStore download"
                                />
                                <div style={{ width: 8 }} />
                                <StoreImg
                                    onClick={() =>
                                        window.open("https://play.google.com/store/apps/details?id=com.supplypipeapp")
                                    }
                                    src={playStore}
                                    alt="playStore download"
                                />
                            </AppStoreBadges>
                        ),
                    },
                ]}
            />
        </Wrapper>
    );
};

const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 480px !important;
    height: 100%;
    box-sizing: border-box;
    padding: 42px 0px 0px 42px;
`;
const Header = styled.h1`
    font-size: 18px;
    font-weight: bold;
`;

const AppStoreBadges = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
`;
const StoreImg = styled.img`
    height: 43px;
    cursor: pointer;
`;

const TextBigger = styled.p`
    font-size: 12px;
    font-weight: 500;
`;
