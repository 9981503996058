import { useSelector } from "react-redux";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { theme } from "../../constants/Theme";
import IconDiameterInnerSvg from "../../icons/Icon_DiameterInnerSvg";
import IconHoseHydraulicSvg from "../../icons/Icon_HoseHydraulicSvg";
import IconPressureWorkSvg from "../../icons/Icon_PressureWorkingSvg";
import IconWiresSvg from "../../icons/Icon_WiresSvg";
import { RootState } from "../../reducers";
import { truncate } from "../../utils/truncate";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { ParamTab } from "../elements/ParamTab";
import { stockAmountHose } from "../../utils/stockAmount";
interface Types {
    openModal: any;
    data: any;
    selectHose: any;
    isLast?: any;
}

const ListItemHoseSelect = ({ openModal, data, selectHose, isLast }: Types) => {
    const { user } = useSelector((state: RootState) => state.account);
    const screenSize = useScreenSize();
    const TabIconSize = screenSize.width > 1024 ? 12 : 12;
    const truncateLength = screenSize.width > 1024 ? 80 : 60;

    const countTabsLength = (data?.oploty + data?.cisnienie_robocze + data?.cena).length;
    const tabFontSize = countTabsLength > 15 ? "10px" : "12px";
    const stockAmount = data?.stan_magazynowy === null ? null : parseFloat(data.stan_magazynowy.replace(",", "."));

    return (
        <Wrapper isLast={isLast}>
            <ContentWrap>
                {data?.obrazki[0] ? (
                    <Image
                        onClick={() => openModal({ state: true, symbol: data?.symbol })}
                        src={`${process.env.REACT_APP_API_URL}/${data?.obrazki[0]}`}
                        alt="hoseExample"
                    />
                ) : (
                    <PlaceholderImage onClick={() => openModal({ state: true, symbol: data?.symbol })}>
                        <div style={{ transform: "rotate(-90deg)" }}>
                            <IconHoseHydraulicSvg />
                        </div>
                    </PlaceholderImage>
                )}

                <DataWrap>
                    <TitleAndAvailabilityWrap>
                        <Title>{data?.nazwa}</Title>
                    </TitleAndAvailabilityWrap>
                    <Description> {truncate(data?.krotki_opis, truncateLength)}</Description>
                    <ParamsWrapper>
                        <ParamTab
                            value={data.oploty}
                            icon={<IconWiresSvg SIZE={TabIconSize} COLOR={theme.color.app_blue2} />}
                            fontSize={tabFontSize}
                        />
                        <ParamTab
                            value={data.cisnienie_robocze + " bar"}
                            icon={<IconPressureWorkSvg SIZE={TabIconSize} COLOR={theme.color.app_blue2} />}
                            fontSize={tabFontSize}
                        />
                        <ParamTab
                            value={data.cena + " zł/mb"}
                            icon={<IconDiameterInnerSvg SIZE={TabIconSize} COLOR={theme.color.app_blue2} />}
                            fontSize={tabFontSize}
                        />
                    </ParamsWrapper>
                </DataWrap>
            </ContentWrap>
            <AvailabilityWrapper>
                {stockAmount && <Availability stockAmount={stockAmount}>{stockAmountHose(stockAmount)}</Availability>}
            </AvailabilityWrapper>
            {user?.userData?.id === "72" && (
                <>
                    <p style={{ fontSize: 12 }}>{data?.symbol + " || stan: " + data?.stan_magazynowy}</p>
                </>
            )}

            <ButtonsWrap>
                <AppButtonRegular
                    title="Zobacz szczegóły"
                    onClick={() => openModal({ state: true, symbol: data?.symbol })}
                    bgColor={theme.color.app_lightBlue}
                    color={theme.color.app_blue}
                    borderColor={theme.color.app_blue}
                />

                <div style={{ width: 8 }} />
                <AppButtonRegular title="Wybierz" onClick={() => selectHose(data)} />
            </ButtonsWrap>
        </Wrapper>
    );
};

export default ListItemHoseSelect;

const AvailabilityWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 2px 0 10px 0;
`;
const Availability = styled.div<{ stockAmount: any }>`
    text-align: right;
    padding: 4px 10px;
    font-size: 11px;
    background-color: ${({ theme, stockAmount }) => {
        if (stockAmount > 4) {
            return theme.color.app_lightBlue;
        } else if (stockAmount > 0 && stockAmount <= 4) {
            return theme.color.app_yellow;
        } else {
            return theme.color.app_lightRed;
        }
    }};
    color: ${({ theme, stockAmount }) => {
        if (stockAmount > 4) {
            return theme.color.app_blue;
        } else if (stockAmount > 0 && stockAmount <= 4) {
            return theme.color.app_black;
        } else {
            return theme.color.app_white;
        }
    }};
    border-radius: 5px;
    font-weight: 600;
`;
const ParamsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
const Description = styled.p`
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: ${({ theme }) => theme.color.grey};
    margin: 0 0 0 3px;
`;
const Title = styled.h3`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    margin: 0 10px 0 0;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
`;
const TitleAndAvailabilityWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;
const Image = styled.img`
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    object-fit: contain;
    width: 20%;
    margin: 7px 15px 0px 4px;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
        transform: scale(1.1);
    }
`;
const DataWrap = styled.div``;
const ContentWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`;
const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`;

const Wrapper = styled.div<{ isLast: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-top: none;
    border-bottom-color: ${({ theme, isLast }) => (isLast ? theme.color.app_blue2 : theme.color.app_grey)};
    border-left-color: ${({ theme }) => theme.color.app_blue2};
    border-right-color: ${({ theme }) => theme.color.app_blue2};
    width: 100%;
    height: 100%;
`;

const PlaceholderImage = styled.div`
    cursor: pointer;
    width: 20%;
    margin: 7px 15px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    :hover {
        transform: scale(1.1);
    }
`;
