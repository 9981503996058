import React from "react";
import styled from "styled-components";

export const LogInRadioButtons = ({ setLogInMethod, logInMethod }: any) => {
    return (
        <RadioButtonsWrapper>
            <RadioSingleWrap onClick={() => setLogInMethod("PHONE")}>
                <RadioButton
                    type="radio"
                    value="phone"
                    name="phone"
                    checked={logInMethod === "PHONE"}
                    onClick={() => setLogInMethod("PHONE")}
                />
                <RadioButtonLabel>Kod SMS</RadioButtonLabel>
            </RadioSingleWrap>
            <div style={{ width: 30 }} />
            <RadioSingleWrap onClick={() => setLogInMethod("EMAIL")}>
                <RadioButton
                    type="radio"
                    value="email"
                    name="email"
                    checked={logInMethod === "EMAIL"}
                    onClick={() => setLogInMethod("EMAIL")}
                />
                <RadioButtonLabel>Adres e-mail</RadioButtonLabel>
            </RadioSingleWrap>
        </RadioButtonsWrapper>
    );
};

const RadioButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const RadioSingleWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const RadioButtonLabel = styled.p`
    font-size: 14px;
    margin-left: 10px;
    width: 100%;
    white-space: nowrap;
    color: ${({ theme }) => theme.color.app_blue};
`;
const RadioButton = styled.input`
    height: 16px;
    width: 16px;
    border-color: ${({ theme }) => theme.color.app_blue};
    color: ${({ theme }) => theme.color.app_blue};
    border-width: 1px;
    appearance: radio;
    appearance: "radio";
    -webkit-appearance: radio;
    -webkit-appearance: "radio";
`;
