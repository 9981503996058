import { FETCH_SLEEVE } from "../constants/actionNames";

const INITIAL_STATE = {
    sleeve: null,
};
export const sleeveReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_SLEEVE + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_SLEEVE + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_SLEEVE + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                sleeve: action.payload.data,
            };
        default:
            return state;
    }
};
