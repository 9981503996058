import axios from "../utils/axiosInterceptor";

export interface logInProps {
    email: string;
    password: string;
}

export interface accountRegisterProps {
    name: string;
    phone: string;
    email: string;
    password: string;
}

export interface accountDetailsProps {
    clientId: string;
}

export const logInService = ({ email, password }: any) => {
    return axios.post(
        `login.php`,
        `mail=${email}&password=${password}`
    );
};
export const phoneSmsVerificationService = ({ phone }: any) => {
    return axios.post(
        `login.php`,
        `phone=${phone}`
    );
};
export const phoneSmsCodeService = ({ phone, verificationCode }: any) => {
    return axios.post(
        `login.php`,
        `phone=${phone}&verificationCode=${verificationCode}`
    );
};
export const accountRegisterService = ({ name, phone, email, password }: accountRegisterProps) => {
    return axios.post(`register.php`, `mail=${email}&password=${password}&name=${name}&phone=${phone}`);
};
export const userDataChangeService = (userData: any) => {
    return axios.post(
        `edit-client.php`,
        `id=${userData?.id}
    &mail=${userData?.mail}
    &phone=${userData?.phone}
    &fname=${userData?.fname}
    &lname=${userData?.lname}
    &individualAddress1=${userData?.individualAddress1}
    &individualAddress2=${userData?.individualAddress2}
    &nip=${userData?.nip}
    &companyName=${userData?.companyName}
    &companyAddress1=${userData?.companyAddress1}
    &companyAddress2=${userData?.companyAddress2}
    `
    );
};
export const getUserDataService = (userId: string | number) => {
    return axios.get(`get-user-info.php?id=${userId}`);
};
export const updateHosesListService = (symbolsString: any) => {
    return axios.post('refresh-item.php', `symbols=${symbolsString}`)
}
