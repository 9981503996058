import React from "react";
import styled from "styled-components";
interface Types {
    icon: object;
    title: string;
    description?: string;
    id: number;
    onClick: any;
    index: number;
}

const ListItemHoseType = ({ icon, title, id, description, onClick, index }: Types) => {
    return (
        <Wrapper isEven={index % 2 === 0} onClick={() => onClick({ title, id })}>
            {icon}
            <TextWrapper>
                <Header>{title.toLowerCase()}</Header>
                <TextTileDescription>{description}</TextTileDescription>
            </TextWrapper>
        </Wrapper>
    );
};

export default ListItemHoseType;

const TextTileDescription = styled.h5`
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    color: ${({ theme }) => theme.color.app_grey2};
`;
const Header = styled.h2`
    font-size: 16px;
    margin: 0 0 6px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    @media (min-width: 1279px) {
        justify-content: flex-start;
        margin-top: 10px;
    }
`;
const Wrapper = styled.div<{ isEven: boolean }>`
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 10px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
    @media (min-width: 1279px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 21px;
        flex: 35%;
        margin-right: ${(props) => (props.isEven ? "26px" : 0)};
        margin-bottom: 13px;
    }
    :hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
        border-color: ${({ theme }) => theme.color.app_blue2};
    }
`;
