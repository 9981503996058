import styled from "styled-components";
import IconArrowRight from "../../icons/Icon_ArrowRight";
import { Text } from "../../styled/typography";

export const ListWrapperSubtitle = ({ description, btnText, url }) => {
    return (
        <div>
            <CustomText>{description}</CustomText>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 4 }}>
                <HelpButton onClick={() => window.open(url, "_blank")}>{btnText}</HelpButton>
                <IconArrowRight />
            </div>
        </div>
    );
};
const CustomText = styled(Text)`
    margin: 0;
    color: ${({ theme }) => theme.color.app_black};
`;
const HelpButton = styled(Text)`
    margin: 0;
    font-weight: 600;
    margin-right: 5px;
    color: ${({ theme }) => theme.color.app_blue2};
    cursor: pointer;
    &:hover {
        margin-right: 7px;
    }
`;
