export const L_FL_0 = require('./L_FL_0.png');
export const L_FL_45 = require('./L_FL_45.png');
export const L_FL_90 = require('./L_FL_90.png');
export const L_GW_0 = require('./L_GW_0.png');
export const L_GW_45 = require('./L_GW_45.png');
export const L_GW_90 = require('./L_GW_90.png');
export const L_GZ_0 = require('./L_GZ_0.png');
export const L_O_0 = require('./L_O_0.png');
export const L_NT_0 = require('./L_NT_0.png');
export const _HOSE2 = require('./_HOSE2.png');
export const L_PLACEHOLDER = require('./L_PLACEHOLDER.png');
