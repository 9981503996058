import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { RootState } from "../../reducers";
import PropertyElementSummary from "../elements/PropertyElement_Summary";
import SummaryRegularElement from "./SummaryRegularElement";

interface Types {
    title: string;
    parameters: any;
}

const SummaryHoseParameters = ({ title, parameters }: Types) => {
    const {
        cena,
        cisnienie_robocze,
        cisnienie_rozrywania,
        nazwa,
        oploty,
        promien_giecia,
        srednica_wewnetrzna,
        srednica_zewnetrzna,
        temperatura_pracy,
        waga,
    } = parameters;
    const screenSize = useScreenSize();
    const { type } = useSelector((state: RootState) => state.currentHose.leftFitting);
    const [open, setOpen] = useState(true);
    const [arrowControl, setArrowControl] = useState(false);
    useEffect(() => {
        if (type) {
            setOpen(false);
        }
    }, [type]);
    return (
        <Collapsible
            open={screenSize.width <= 1200 ? true : open}
            onOpen={() => setArrowControl(true)}
            onClose={() => setArrowControl(false)}
            trigger={
                <SummaryRegularElement
                    isOpen={arrowControl}
                    borderBottom={!open}
                    header="Przewód"
                    title={nazwa}
                    collapsible
                />
            }>
            <ParametersWrap>
                <PropertyElementSummary title={"Średnica wewnętrzna"} value={"DN " + srednica_wewnetrzna} />
                <PropertyElementSummary title={"Oploty"} value={oploty} />
                <PropertyElementSummary title={"Ciśnienie robocze"} value={cisnienie_robocze + " BAR"} />
                <PropertyElementSummary title={"Ciśnienie rozrywania"} value={cisnienie_rozrywania + " BAR"} />
                <PropertyElementSummary title={"Temp. pracy"} value={temperatura_pracy?.replace("/&#43;", "/")} />
                <PropertyElementSummary title={"Średnica zewnętrzna"} value={srednica_zewnetrzna + " mm"} />
                <PropertyElementSummary title={"Promień gięcia"} value={promien_giecia + " mm"} />
                <PropertyElementSummary title={"Waga"} value={waga + " kg"} />
                <PropertyElementSummary title={"Cena"} value={cena + " zł/m"} />
            </ParametersWrap>
        </Collapsible>
    );
};

export default SummaryHoseParameters;

const ParametersWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 186px;
    margin-top: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
`;
