import styled from "styled-components";
import { inox } from "../icons/categories";
import agregat2 from "../icons/categories/agregat2.svg";
import enerpac2 from "../icons/categories/enerpac2.svg";
import hydrauliczne from "../icons/categories/hydrauliczne.svg";
import hydrauliczneWysokocisnieniowe from "../icons/categories/hydraulicznewysokoscinieniowe.svg";
import kompresor from "../icons/categories/kompresor.svg";
import myjacych from "../icons/categories/myjacych.svg";
import pomiarowe from "../icons/categories/pomiarowe.svg";
import teflonowe from "../icons/categories/teflonowe.svg";
const IconWrapper = styled.img`
    height: 60px;
    width: 40px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 18px;
    box-sizing: border-box;
    @media (min-width: 1279px) {
        height: 70px;
        width: 50px;
        margin: 0;
    }
`;
export const hoseCategoriesJson = [
    {
        id: 1,
        name: "Węże hydrauliczne",
        desc: "1SN, 1SC, 2N, 2SN, 4SP, węże termoplastyczne",
        icon: <IconWrapper src={hydrauliczne} alt="hydrauliczne" />,
    },
    {
        id: 2,
        name: "Węże hydrauliczne - wysokie ciśnienie",
        desc: "4SH, R15  do zastosowania w aplikacjach z udarem i z dużym ciśnieniem",
        icon: <IconWrapper src={hydrauliczneWysokocisnieniowe} alt="hydrauliczne-wysokoscisnieniowe" />,
    },
    {
        id: 3,
        name: "Węże do urządzeń myjących - do 1450 bar",
        desc: "zastosowanie do małych myjek, myjni samoobsługowych jak i dużych hydromonitorów",
        icon: <IconWrapper src={myjacych} alt="myjace" />,
    },
    {
        id: 8,
        name: "Węże pomiarowe",
        desc: "Węże pomiarowe o średnicy wewnętrznej 4 mm",
        icon: <IconWrapper src={pomiarowe} alt="pomiarowe" />,
    },
    {
        id: 9,
        name: "Przewody teflonowe",
        desc: "Węże odporne na agresywne substancje chemiczne i temperatur pracy -70C do +260C",
        icon: <IconWrapper src={teflonowe} alt="teflonowe" />,
    },
    {
        id: 11,
        name: "Węże do kompresorów",
        desc: "Węże odporne na olej i wysokie temperatury",
        icon: <IconWrapper src={kompresor} alt="kompresor" />,
    },
    {
        id: 6,
        name: "Węże z końcówkami nierdzewnymi",
        desc: "Węże z końcówkami ze stali nierdzewnej odporne na korozję, ekstremalne temperatury oraz chemikalia w tym kwasy.",
        icon: <IconWrapper src={inox?.default} alt="inox" />,
    },
    {
        id: 12,
        name: "Węże do agregatów malarskich",
        desc: "Węże do przesyłu pod ciśnienim farb, rozpuszczalników i innych chemikalii",
        icon: <IconWrapper src={agregat2} alt="agregat" />,
    },
    {
        id: 16,
        name: "Węże do urządzeń typu ener-pack",
        desc: "Węże o ciśnieniu pracy 700bar do narzędzi hydraulicznych typu ENERPAC",
        icon: <IconWrapper src={enerpac2} alt="enerpac" />,
    },
    {
        id: 4,
        name: "Wszystkie dostępne węże i zakucia",
        desc: "Jeśli nie wiesz czego potrzebujesz możesz wybrać tą kategorię, jednak dostępnych tu będzie bardzo dużo produktów",
        icon: <IconWrapper src={hydrauliczne} alt="wszystkie" />,
    },
];
