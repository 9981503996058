import React from "react";
import styled from "styled-components";
import { _HOSE2 } from "../../../../assets/hoseConfig";

export const HoseCenterWrapper = ({ hoseString, active, selected }: any) => {
    return (
        <HoseWrapper>
            <HoseImage src={_HOSE2.default} alt="Hose" style={{ opacity: selected ? 1 : 0.4 }} />
            {selected && <HoseString>{hoseString}</HoseString>}
            {active && <CenterSquare />}
            {active && <InfoString>Wybierasz przewód</InfoString>}
        </HoseWrapper>
    );
};

const InfoString = styled.p`
    position: absolute;
    top: -16px;
    font-size: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.app_blue2};
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 768px) {
        bottom: 26px;
    }
`;
const CenterSquare = styled.div`
    position: absolute;
    height: 70px;
    width: 100%;
    border-radius: 16px;
    z-index: 1;
    border: 1px solid ${({ theme }) => theme.color.app_blue2};
    top: -3px;
    left: 0px;
    right: 0px;
    margin: auto;
`;
const HoseWrapper = styled.div`
    position: relative;
    border-radius: 4px;
`;
const HoseString = styled.p`
    position: absolute;
    top: 22px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    text-align: center;
`;
const HoseImage = styled.img`
    width: 252px;
    object-fit: contain;
`;
