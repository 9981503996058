import { useEffect, useState } from "react";
import AuthCode from "react-auth-code-input";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getUserDataAction, phoneSmsCodeAction } from "../../actions/account.action";
import { useGuestHosesToUserCart } from "../../hooks/localStoreHosesMaintenance/useGuestHosesToUserCart/useGuestHosesToUserCart";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { setAuthorizationHeader } from "../../utils/auth.utils";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import "./SmsCode.css";
interface LogInData {
    userId: number | string;
    accessToken: string;
    refreshToken: string;
}

export const LogInSmsCodeInputWrap = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { smsLogInPhone } = useSelector((state: RootState) => state.account);
    const { guestHosesToUserCart } = useGuestHosesToUserCart();
    const [result, setResult] = useState<string>();
    const [error, setError] = useState("");
    useEffect(() => {
        const checkCodeAndVerify = async () => {
            if (result?.length === 6) {
                await verifyCode();
            }
        };
        checkCodeAndVerify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);
    const fetchUserData = (userId: string | number) => {
        dispatch(getUserDataAction(userId));
    };

    const saveTokensToAsync = async ({ userId, accessToken, refreshToken }: LogInData) => {
        setAuthorizationHeader(accessToken);
        await window.localStorage.setItem("userData", JSON.stringify(userId));
        await window.localStorage.setItem("accessToken", accessToken);
        await window.localStorage.setItem("refreshToken", refreshToken);
        navigate("/");
    };

    const verifyCode = async () => {
        try {
            const response = await dispatch(phoneSmsCodeAction({ phone: smsLogInPhone, verificationCode: result }));
            if (response.value.data?.accessToken) {
                saveTokensToAsync({
                    userId: response.value.data?.userId,
                    accessToken: response.value.data?.accessToken,
                    refreshToken: response.value.data?.refreshToken,
                });
                fetchUserData(response.value.data?.userId);
                guestHosesToUserCart(response.value.data?.userId);
            }
        } catch (error) {
            setError("Błędny kod");
        }
    };

    return (
        <Wrapper>
            <Title>Wpisz kod SMS</Title>
            <SubTitle>
                Na numer <span style={{ fontWeight: "bold" }}>{smsLogInPhone}</span> wysłaliśmy wiadomość SMS z kodem.
                Przepisz kod aby się zalogować
            </SubTitle>
            {error.length > 1 ? <LogInError>{error}</LogInError> : <LogInError />}

            <AuthCode
                containerClassName="smsCodeContainer"
                inputClassName="smsCodeInput"
                onChange={(e: any) => setResult(e)}
            />
            <div style={{ height: 24 }} />
            <AppButtonRegular title="Zatwierdź" onClick={() => verifyCode()} />
        </Wrapper>
    );
};

const LogInError = styled.p`
    font-size: 12px;
    color: red;
    margin: 11px 5px;
    text-align: center;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Title = styled.div`
    margin: 0;
    font-size: 24px;
    font-weight: 700;
`;
const SubTitle = styled.div`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    margin-top: 17px;
    margin-bottom: 21px;
    color: ${({ theme }) => theme.color.app_grey2};
    max-width: 400px;
    text-align: center;
`;
