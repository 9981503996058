import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { currentResetAction, setCurrentHoseCategoryAction } from "../actions/currentHose.action";
import { setHoseSideAction } from "../actions/inAppState.action";
import { removeProgressAction } from "../actions/progress.action";
import { AppButtonBorderWithIcon } from "../components/buttons/App_ButtonBorderWithIcon";
import { ReusableInfoElementWithEmoji } from "../components/elements/ReusableInfoElementWithEmoji";
import ListItemHoseType from "../components/listElements/ListItem_HoseType";
import ListWrapper from "../components/listWrappers/ListWrapper";
import { ListWrapperSubtitle } from "../components/listWrappers/ListWrapperSubtitle";
import { QrCodeWrite } from "../components/qrCodeWrite/QrCodeWrite";
import { theme } from "../constants/Theme";
import { hoseCategoriesJson } from "../data/categories";
import IconArrowRight from "../icons/Icon_ArrowRight";
import { RouteWrapper } from "../styled/wrappers";

const HoseTypeRoute = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const screenSize = useScreenSize();

    useEffect(() => {
        dispatch(setCurrentHoseCategoryAction(null, null));
        dispatch(currentResetAction());
        dispatch(setHoseSideAction("LEFT"));
        clearProgress_temporary();
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const clearProgress_temporary = () => {
        dispatch(removeProgressAction("hose", "hose"));
        dispatch(removeProgressAction("hose", "left"));
        dispatch(removeProgressAction("hose", "right"));
        dispatch(removeProgressAction("hose", "additions"));
    };

    const selectHoseCategory = (data: any) => {
        dispatch(setCurrentHoseCategoryAction(data?.title, data?.id));
        navigate("/create/hoseDiameter");
    };
    return (
        <RouteWrapper>
            <ListWrapper
                icon={null}
                title={"Wybierz typ przewodu"}
                subtitle={
                    <ListWrapperSubtitle
                        description='Określ do czego potrzebujesz przewodu według zastosowania. Możesz też wybrać ostatnią pozycję
                         "Wszystkie dostępne węże"'
                        btnText="Pomoc - Sprawdź jak wybrać grupę zastosowań"
                        url={"https://zakuwanie24.pl/rodzaje-wezy-hydraulicznych-grupy-zastosowan/"}
                    />
                }
                listHeader={<></>}
                list={
                    <>
                        <AppButtonBorderWithIcon
                            description="Potrzebujesz pomocy? Otwórz bazę wiedzy"
                            onClick={() => window.open("https://zakuwanie24.pl/baza-wiedzy/", "_blank")}
                            icon={<IconArrowRight />}
                        />

                        <FlexPositioningWrap>
                            {hoseCategoriesJson?.map((item: any, index: number) => (
                                <ListItemHoseType
                                    index={index}
                                    key={index}
                                    title={item?.name}
                                    id={item?.id}
                                    onClick={(data: object) => selectHoseCategory(data)}
                                    description={item?.desc}
                                    icon={item.icon}
                                />
                            ))}
                        </FlexPositioningWrap>
                    </>
                }
            />
            {screenSize.width <= 1023 && (
                <ReusableInfoElementWithEmoji
                    style={{
                        marginBottom: 10,
                        marginLeft: 10,
                        marginRight: 10,
                        backgroundColor: theme.color.white,
                        borderColor: theme.color.app_lightBlue,
                    }}
                    infos={[
                        {
                            emoji: "",
                            description: (
                                // eslint-disable-next-line react/jsx-no-undef
                                <TitleAndImageWrap>
                                    <Header style={{ marginBottom: 15 }}>Sprawdź przewód za pomocą kodu</Header>
                                    <QrCodeWrite />
                                </TitleAndImageWrap>
                            ),
                        },
                    ]}
                />
            )}
        </RouteWrapper>
    );
};

export default HoseTypeRoute;

const FlexPositioningWrap = styled.div`
    @media (min-width: 1279px) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 23px;
    }
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const Header = styled.h1`
    font-size: 18px;
    font-weight: bold;
`;
