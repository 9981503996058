import { FETCH_COVERS } from "../constants/actionNames";

const INITIAL_STATE = {
    covers: null,
};
export const coverReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_COVERS + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_COVERS + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_COVERS + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                covers: action.payload.data,
            };
        default:
            return state;
    }
};
