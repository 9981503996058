import { changeHosesUserByIdAction, clearGuestCart } from "../../../actions/addHoseToCart.action";
import { useAppDispatch } from "../../useAppDispatch/useAppDispatch";

export const useGuestHosesToUserCart = () => {
    const dispatch = useAppDispatch();
    const guestHosesToUserCart = (userId: string) => {
        const lsHosesIds = window.localStorage.getItem("hoses");
        if (!lsHosesIds) return false;
        if (lsHosesIds === "undefined") return false;

        const hosesIds = JSON.parse(lsHosesIds);
        const arrayOfQrCodes = hosesIds.map((item: any) => item.id);
        const idsString = arrayOfQrCodes.join(",");

        if (lsHosesIds) {
            dispatch(
                changeHosesUserByIdAction({
                    userId: userId,
                    hoseId: idsString,
                })
                //@ts-ignore
            ).then((r: any) => {
                if (r.action.payload.status === 200) {
                    window.localStorage.removeItem("hoses");
                    dispatch(clearGuestCart());
                }
            });
        }
    };

    return {
        guestHosesToUserCart,
    };
};
