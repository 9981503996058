import { useState } from "react";
import Collapsible from "react-collapsible";
import ListItemHoseDiameter from "./ListItem_HoseDiameter2";
import ListItemHoseSelect from "./ListItem_HoseSelect";

export const ListItemHoseCollapsible = ({ item, index, setOpen, selectHose }: any) => {
    const [collapseOpen, setCollapseOpen] = useState(false);
    const sortedArray = item.hoses.sort((a: any, b: any) => {
        const valueA = a.krotki_opis ? parseInt(a.krotki_opis, 10) : -Infinity;
        const valueB = b.krotki_opis ? parseInt(b.krotki_opis, 10) : -Infinity;

        return valueB - valueA;
    });

    return (
        <Collapsible
            onOpen={() => setCollapseOpen(true)}
            onClose={() => setCollapseOpen(false)}
            trigger={<ListItemHoseDiameter isOpen={collapseOpen} data={item} key={index} />}>
            {sortedArray?.map((item: any, index: any) => (
                <ListItemHoseSelect
                    data={item}
                    key={index}
                    selectHose={(data: any) => selectHose(data)}
                    openModal={({ state, symbol }: any) => {
                        setOpen({
                            state: state,
                            symbol: symbol,
                        });
                    }}
                    isLast={index === sortedArray.length - 1}
                />
            ))}
        </Collapsible>
    );
};
