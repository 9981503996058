import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import IconCloseSvg from "../../icons/Icon_CloseSvg";

export const PriceCountRoute = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                <Header>Jak liczymy cenę</Header>
                <Text>Na cenę składają się:</Text>
                <Text style={{ marginTop: 2, marginBottom: 2, fontSize: 12 }}>Cena przewodu</Text>
                <Text style={{ marginTop: 2, marginBottom: 2, fontSize: 12 }}>Cena zakuć</Text>
                <Text style={{ marginTop: 2, marginBottom: 2, fontSize: 12 }}>Cena tulejek</Text>
                <Text style={{ marginTop: 2, marginBottom: 2, fontSize: 12 }}>Cena usługi zakucia</Text>
                <Text>Cena usługi zakucia rózni się w zalezności od stopnia pracochłonności.</Text>
                <Text style={{ fontSize: 12 }}>
                    Im przewód grubszy (im większa średnica wewnętrzna), oraz im więcej metalowych oplotów, tym więcej
                    pracy wymaga. Niektóre przewody wymagają specjalistycznej obróbki zakończeń przed zakuciem - stąd
                    ich cena wzrasta.
                </Text>
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};

const Text = styled.h1`
    margin: 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 14px;
    font-weight: 500;
    margin: 10px;
    align-self: flex-start;
    line-height: 18px;
`;

const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const Header = styled.h1`
    margin: 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 20px;
    font-weight: 700;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    @media (min-width: 1000px) {
        height: 510px;
        width: 580px;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
`;
