import * as React from "react"

function IconWiresSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			data-name="Group 211"
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 30.033 29.911"
		>
			<path
				data-name="Path 14"
				d="M10.654 4.809L9.105 1.205a15.613 15.613 0 00-3.952 2.466l2.561 2.94a11.664 11.664 0 012.94-1.802z"
				fill={COLOR}
			/>
			<path
				data-name="Path 15"
				d="M5.817 8.793L2.561 6.611a15.085 15.085 0 00-1.992 4.2l3.763 1.11a11.886 11.886 0 011.485-3.128z"
				fill={COLOR}
			/>
			<path
				data-name="Path 16"
				d="M25.102 3.896a15.071 15.071 0 00-3.889-2.564l-1.612 3.572a10.815 10.815 0 012.877 1.892z"
				fill={COLOR}
			/>
			<path
				data-name="Path 17"
				d="M25.734 12.112l3.789-1.014a15.192 15.192 0 00-1.9-4.268l-3.28 2.121a10.839 10.839 0 011.391 3.161z"
				fill={COLOR}
			/>
			<path
				data-name="Path 18"
				d="M3.92 15.02v-.253L0 14.641v.348a16.177 16.177 0 00.6 4.268l3.763-1.11a11.516 11.516 0 01-.443-3.127z"
				fill={COLOR}
			/>
			<path
				data-name="Path 19"
				d="M22.066 23.588l2.5 3.035a14.819 14.819 0 003.1-3.478l-3.292-2.117a12.639 12.639 0 01-2.308 2.56z"
				fill={COLOR}
			/>
			<path
				data-name="Path 20"
				d="M16.313 26.022l.443 3.889a14.867 14.867 0 004.489-1.265l-1.648-3.572a9.635 9.635 0 01-3.284.948z"
				fill={COLOR}
			/>
			<path
				data-name="Path 21"
				d="M28.896 14.957l-2.786.032a11.336 11.336 0 01-.534 3.411l3.734 1.2a15.152 15.152 0 00.727-4.616z"
				fill={COLOR}
			/>
			<path
				data-name="Path 22"
				d="M2.182 22.83a15.386 15.386 0 003 3.541l2.564-2.972a10.972 10.972 0 01-2.213-2.624z"
				fill={COLOR}
			/>
			<path
				data-name="Path 23"
				d="M17.483.193a15.271 15.271 0 00-4.648-.031l.57 3.888a11.449 11.449 0 013.446.032z"
				fill={COLOR}
			/>
			<path
				data-name="Path 24"
				d="M8.441 28.52a14.581 14.581 0 004.456 1.359l.539-3.889a11.016 11.016 0 01-3.288-1.011z"
				fill={COLOR}
			/>
		</svg>
	)
}

export default IconWiresSvg;
