import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setCurrentCoverLengthAction, setCurrentCoverPositionAction } from "../../actions/currentHose.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../../reducers";
import { SubHeader, Text } from "../../styled/typography";
import { Button } from "../buttons/Button";

const coverPositions = [
    { name: "Przy lewym zakuciu", value: "LEFT" },
    { name: "Na środku", value: "CENTER" },
    { name: "Przy prawym zakuciu", value: "RIGHT" },
];
const coverLengths = [0.25, 0.5, 0.75, 1];

export const HoseCoverProperties = () => {
    const dispatch = useAppDispatch();
    const hoseLength = useSelector((state: any) => state.currentHose?.hose?.length);
    const { coverLength, coverPosition } = useSelector((state: RootState) => state.currentHose.cover);
    const [currentCoverLength, setCurrentCoverLength] = useState(coverLength || hoseLength);

    useEffect(() => {
        setCurrentCoverLength(hoseLength);
    }, [hoseLength]);

    const setCoverLength = (percentage: number) => {
        setCurrentCoverLength(Math.ceil(hoseLength * percentage));
        dispatch(
            setCurrentCoverLengthAction({
                length: Math.ceil(hoseLength * percentage),
                percentageLength: percentage,
            })
        );
    };

    const setPositionOfCover = (position: any) => dispatch(setCurrentCoverPositionAction(position));

    return (
        <Wrapper>
            <SubHeader>Długość i położenie osłony</SubHeader>
            <Text style={{ marginTop: 5, marginBottom: 10 }}>
                Osłona moze być na całym wężu - od zakucia do zakucia - lub na jego części. Wybierz jak długa w stosunku
                do węża ma być osłona.
            </Text>
            <ButtonsWrap>
                {coverLengths.map((percentage, index) => (
                    <SingleBtn key={index}>
                        <Button
                            bgColor={
                                currentCoverLength / hoseLength >= percentage
                                    ? theme.color.app_blue2
                                    : theme.color.app_lightBlue
                            }
                            color={
                                currentCoverLength / hoseLength >= percentage
                                    ? theme.color.app_lightBlue
                                    : theme.color.app_blue2
                            }
                            textStyles={
                                currentCoverLength / hoseLength >= percentage
                                    ? { fontWeight: 700 }
                                    : { fontWeight: 500 }
                            }
                            title={(percentage * 100).toString() + " %"}
                            onClick={() => setCoverLength(percentage)}
                            isButtonGroup
                        />
                    </SingleBtn>
                ))}
            </ButtonsWrap>

            {Number(currentCoverLength) !== Number(hoseLength) && (
                <>
                    <Text>Po której stronie znajdować się ma osłona? </Text>
                    <ButtonsWrap>
                        {coverPositions.map((item, index) => (
                            <SingleBtn key={index}>
                                <Button
                                    bgColor={
                                        coverPosition?.value === item?.value
                                            ? theme.color.app_blue2
                                            : theme.color.app_lightBlue
                                    }
                                    color={
                                        coverPosition?.value === item?.value
                                            ? theme.color.app_lightBlue
                                            : theme.color.app_blue2
                                    }
                                    title={item.name}
                                    onClick={() => setPositionOfCover(item)}
                                    isButtonGroup
                                    textStyles={
                                        coverPosition?.value === item?.value ? { fontWeight: 700 } : { fontWeight: 500 }
                                    }
                                />
                            </SingleBtn>
                        ))}
                    </ButtonsWrap>
                </>
            )}
        </Wrapper>
    );
};

const ButtonsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 0;
`;
const SingleBtn = styled.div`
    width: 32%;
    padding-bottom: 5px;
    cursor: pointer;
`;
const Wrapper = styled.div`
    padding: 20px 0;
`;
