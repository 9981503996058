import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Checkbox } from "react-input-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { getUserDataAction, updateHosesListAction } from "../../actions/account.action";
import {
    removeHoseByIdAction,
    removeHoseFromGuestCartAction,
    setCartItemActivityAction,
    setCartItemQuantityAction,
    setGuestCartItemActivityAction,
    setGuestCartItemQuantityAction,
} from "../../actions/addHoseToCart.action";
import { theme } from "../../constants/Theme";
import { RootState } from "../../reducers";
import { getQuantityForSingleHoseFromStorage, updateHoseQuantityInStorage } from "../../utils/cart/HoseQuantityStorage";
import { convertPriceToDisplayFormat } from "../../utils/price/convertPriceToDisplayFormat";
import { extractSymbolsFromHosesList } from "../../utils/updateHosesList.utils";
import { AppButtonText } from "../buttons/App_ButtonText";
import { CartProductAvailability } from "../cart/CartProductAvailability";
import { HoseImage } from "../hoseImages/HoseImage";

export const ListItemCart = ({ data, hosesList }: any) => {
    const dispatch = useDispatch();
    let location = useLocation();
    const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    const { leftFittingDetails, rightFittingDetails, totalPrice, sleeveDetails, hoseDetails, coverDetails } = data;
    const initialQuantity = getQuantityForSingleHoseFromStorage(data?.ID);
    const [quantity, setQuantity] = useState<number | string>(initialQuantity || 1);
    const removeHoseFromCart = async () => {
        if (isLogged) {
            if (window.confirm("Czy na pewno chcesz usunąć przewód z koszyka?")) {
                const handleRemoveAndUpdate = async () => {
                    await dispatch(removeHoseByIdAction({ clientId: data.clientId, hoseId: data.ID }));
                    await dispatch(getUserDataAction(data.clientId));
                    const symbolsString = extractSymbolsFromHosesList(hosesList);
                    await dispatch(updateHosesListAction(symbolsString));
                };
                handleRemoveAndUpdate();
            }
        } else {
            if (window.confirm("Czy na pewno chcesz usunąć przewód z koszyka?")) {
                const storeHoses = window.localStorage.getItem("hoses");
                const hoses = typeof storeHoses === "string" ? JSON.parse(storeHoses) : null;
                const updatedHoses = hoses?.filter((item: any) => item.id !== data.ID);
                window.localStorage.setItem("hoses", JSON.stringify(updatedHoses));
                dispatch(removeHoseFromGuestCartAction(data.ID)); //todo czy toj est dobrze??
                return await dispatch(removeHoseByIdAction({ clientId: "0", hoseId: data.ID }));
            }
        }
    };
    const changeItemActivity = (status: boolean, hoseId: string) => {
        if (isLogged) {
            return dispatch(setCartItemActivityAction(status, hoseId));
        } else {
            return dispatch(setGuestCartItemActivityAction(status, hoseId));
        }
    };
    const changeItemQuantity = (quantity: number | string, hoseId: string) => {
        updateHoseQuantityInStorage(hoseId, quantity);
        setQuantity(quantity);

        if (isLogged) {
            return dispatch(setCartItemQuantityAction(Number(quantity), hoseId));
        } else {
            return dispatch(setGuestCartItemQuantityAction(Number(quantity), hoseId));
        }
    };
    useEffect(() => {
        const quantityFromStorage = getQuantityForSingleHoseFromStorage(data?.ID);
        if (quantityFromStorage !== null && quantityFromStorage !== data.quantity) {
            setQuantity(quantityFromStorage);
            changeItemQuantity(quantityFromStorage, data?.ID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper style={{ backgroundColor: !data.activity ? theme.color.app_grey4 : "white" }}>
            <TopWrap>
                <AppButtonText title="Usuń" onClick={() => removeHoseFromCart()} color={theme.color.app_red} />
                <Link
                    style={{ textDecoration: "none" }}
                    to={`/Modal/HoseDetails`}
                    state={{ backgroundLocation: location, hoseDetailsData: data }}>
                    <AppButtonText title={"Pokaż szczegóły"} onClick={undefined} />
                </Link>
            </TopWrap>
            <ImageWrap>
                <HoseImage
                    leftFitting={{ angle: leftFittingDetails?.kat_zakucia, type: leftFittingDetails?.typ_zakucia }}
                    rightFitting={{ angle: rightFittingDetails?.kat_zakucia, type: rightFittingDetails?.typ_zakucia }}
                />
            </ImageWrap>
            <>
                <InfoElementWrap>
                    <InfoElementTitle>Przewód</InfoElementTitle>
                    <InfoElementValue>{data?.hoseDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                <InfoElementWrap>
                    <InfoElementTitle>Zakucie lewe</InfoElementTitle>
                    <InfoElementValue>{data?.leftFittingDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                <InfoElementWrap>
                    <InfoElementTitle>Zakucie prawe</InfoElementTitle>
                    <InfoElementValue>{data?.rightFittingDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                {coverDetails && (
                    <InfoElementWrap>
                        <InfoElementTitle>Osłona przewodu</InfoElementTitle>
                        <InfoElementValue>
                            {coverDetails?.nazwa + " | " + coverDetails?.length * 100 + "% długości"}
                        </InfoElementValue>
                    </InfoElementWrap>
                )}
                <InfoElementWrap>
                    <InfoElementTitle>Długość</InfoElementTitle>
                    <InfoElementValue>{data?.length + " cm"}</InfoElementValue>
                </InfoElementWrap>
            </>
            <BottomWrap>
                <BottomWrapElement>
                    <TextInfo>Zamów w ilości</TextInfo>

                    <QuantitySelect
                        style={{ opacity: !data.activity ? 0.4 : 1 }}
                        value={quantity}
                        onChange={(e) => changeItemQuantity(e.target.value, data?.ID)}>
                        {options.map((item) => (
                            <option key={item} value={item}>
                                {item + " szt."}
                            </option>
                        ))}
                    </QuantitySelect>
                </BottomWrapElement>
                <BottomWrapElement>
                    <TextInfo>Uwzględnij w zamówieniu</TextInfo>

                    <Checkbox
                        modification="cartItemDisable"
                        theme="fancy-checkbox"
                        disabled={false}
                        value={data.activity}
                        onChange={() => changeItemActivity(!data?.activity, data?.ID)}>
                        <p></p>
                    </Checkbox>
                </BottomWrapElement>
                <BottomWrapElement>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={`/Modal/PriceCount`}
                        state={{ backgroundLocation: location }}>
                        <div
                            style={{
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                            }}>
                            <TextInfo style={{ fontWeight: 600, fontSize: 14, color: "black" }}>Cena brutto</TextInfo>
                            <InfoCircleOutlined style={{ fontSize: 14, color: theme.color.black, marginLeft: 5 }} />
                        </div>
                    </Link>
                    <PriceValue>{data && convertPriceToDisplayFormat(totalPrice * data?.quantity)} zł</PriceValue>
                </BottomWrapElement>
            </BottomWrap>
            <CartProductAvailability item={data} />
            {user?.userData?.id === "72" && (
                <>
                    <div style={{ fontSize: 12, fontWeight: "bold" }}>TULEJKA:</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
                        Symbol: {JSON.stringify(sleeveDetails?.symbol)} || Cena: {JSON.stringify(sleeveDetails?.cena)}{" "}
                        || Stan Magazynowy: {JSON.stringify(sleeveDetails?.stan_magazynowy)}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "bold" }}>Lewe zakucie:</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
                        Symbol: {JSON.stringify(leftFittingDetails?.symbol)} || Cena:{" "}
                        {JSON.stringify(leftFittingDetails?.cena)} || Stan Magazynowy:{" "}
                        {JSON.stringify(leftFittingDetails?.stan_magazynowy)}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "bold" }}>Prawe zakucie:</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
                        Symbol: {JSON.stringify(rightFittingDetails?.symbol)} || Cena:{" "}
                        {JSON.stringify(rightFittingDetails?.cena)} || Stan Magazynowy:{" "}
                        {JSON.stringify(rightFittingDetails?.stan_magazynowy)}
                    </div>
                    <div style={{ fontSize: 12, fontWeight: "bold" }}>Przewód:</div>
                    <div style={{ fontSize: 12, fontWeight: "bold", color: "gray" }}>
                        Symbol: {JSON.stringify(hoseDetails?.symbol)} || Cena: {JSON.stringify(hoseDetails?.cena)} ||
                        Stan Magazynowy: {JSON.stringify(hoseDetails?.stan_magazynowy)}
                    </div>
                </>
            )}
        </Wrapper>
    );
};
const QuantitySelect = styled.select`
    width: 75px;
    height: 28px;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
const PriceValue = styled.div`
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
`;
const TextInfo = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.app_blue2};
`;

const BottomWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: column;
    align-items: flex-start;
`;
const BottomWrapElement = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey5};
`;
const InfoElementTitle = styled.p`
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.app_grey3};
    margin-bottom: 2px;
`;
const InfoElementValue = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const InfoElementWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 16px 8px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey5};
`;
const ImageWrap = styled.div`
    margin: 14px 16px;
    @media (min-width: 550px) {
        padding: 0 25px;
    }
`;
const TopWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    padding-top: 22px;
    padding-bottom: 16px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
`;
