export const dn_units = {
    "4": {
        dn: 4,
        cale: '5/32"',
        mm: "4,0",
    },
    "5": {
        dn: 5,
        cale: '3/16"',
        mm: "4,8",
    },
    "6": {
        dn: 6,
        cale: '1/4"',
        mm: "6,4",
    },
    "8": {
        dn: 8,
        cale: '5/16"',
        mm: "7,9",
    },
    "10": {
        dn: 10,
        cale: '3/8"',
        mm: "9,5",
    },
    "12": {
        dn: 12,
        cale: '1/2"',
        mm: "12,8",
    },
    "13": {
        dn: 13,
        cale: '1/2"',
        mm: "12,8",
    },
    "16": {
        dn: 16,
        cale: '5/8"',
        mm: "16,0",
    },
    "19": {
        dn: 19,
        cale: '3/4"',
        mm: "19,0",
    },
    "25": {
        dn: 25,
        cale: '1"',
        mm: "25,4",
    },
    "31": {
        dn: 31,
        cale: '1" 1/4',
        mm: "31,8",
    },
    "32": {
        dn: 32,
        cale: '1" 1/4',
        mm: "31,8",
    },
    "38": {
        dn: 38,
        cale: '1" 1/2',
        mm: "38,1",
    },
    "51": {
        dn: 51,
        cale: "2",
        mm: "50,8",
    },
};
