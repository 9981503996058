import { GET_SINGLE_ORDER } from "../constants/actionNames";

const INITIAL_STATE = {
    order: null,
    loading: false,
};
export const orderReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case GET_SINGLE_ORDER + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case GET_SINGLE_ORDER + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case GET_SINGLE_ORDER + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                order: action.payload.data,
            };
        default:
            return state;
    }
};
