import * as React from "react"

function IconTemperatureSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			data-name="Group 263"
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 11.706 21.504"
		>
			<path
				data-name="Path 43"
				d="M3.777 21.412a4.682 4.682 0 003.673-8.377V2.777A2.763 2.763 0 004.673 0a2.791 2.791 0 00-2.777 2.777v10.259a4.646 4.646 0 00-1.792 4.748 4.7 4.7 0 003.673 3.628zm-1.3-7.526l.4-.314V2.822a1.749 1.749 0 011.755-1.747 1.788 1.788 0 011.792 1.747v10.751l.4.314a3.629 3.629 0 011.478 2.957 3.7 3.7 0 01-1.075 2.6 3.778 3.778 0 01-2.6 1.075A3.626 3.626 0 011.045 17.6a3.817 3.817 0 011.433-3.713z"
				fill={COLOR}
			/>
			<path
				data-name="Path 44"
				d="M4.673 19.441a2.6 2.6 0 002.643-2.646c0-1.792-1.523-2.374-1.926-2.733V6.495H3.911v7.57c-.314.358-2.285 1.075-1.837 3.27a2.616 2.616 0 002.599 2.106z"
				fill={COLOR}
			/>
			<path
				data-name="Path 45"
				d="M11.168 3.046H8.615v1.03h2.553a.5.5 0 00.493-.493.462.462 0 00-.493-.537z"
				fill={COLOR}
			/>
			<path
				data-name="Path 46"
				d="M10.676 6.272a.5.5 0 00-.493-.493H8.66v1.03h1.523a.573.573 0 00.493-.537z"
				fill={COLOR}
			/>
			<path
				data-name="Path 47"
				d="M11.706 8.959a.5.5 0 00-.493-.493H8.66v1.03h2.553a.538.538 0 00.493-.537z"
				fill={COLOR}
			/>
		</svg>
	)
}

export default IconTemperatureSvg
