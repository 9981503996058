export const stockAmountFitting = (amount: any) => {
    if (amount === 0) {
        return "Oczekiwana dostawa";
    } else if (amount <= 4 && amount > 0) {
        return "Niski stan magazynowy";
    } else {
        return "Produkt dostępny";
    }
};
export const stockAmountHose = (amount: any) => {
    if (amount === 0) {
        return "Oczekiwana dostawa";
    } else if (amount <= 4 && amount > 0) {
        return "Niski stan magazynowy";
    } else {
        return "Produkt dostępny";
    }
};
