import styled from "styled-components";

export const MenuElement = ({ title, onClick, icon }: any) => {
    return (
        <Wrapper onClick={onClick}>
            {icon}
            <Title>{title}</Title>
        </Wrapper>
    );
};

const Title = styled.p`
    font-size: 12px;
    font-weight: 500;
    margin-left: 16px;
    padding: 12px 0;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    &:hover ${Title} {
        color: ${({ theme }) => theme.color.app_blue2};
        font-weight: 600;
    }
`;
