import React from "react";
import styled from "styled-components";

interface Types {
    value?: string | number;
    title?: string | null;
    wrapperStyles?: any;
    onTextChange?: any;
    onBlur?: any;
    type?: string;
    unit?: string;
    inputStyle?: any;
    disabled?: boolean;
    maxLength?: number;
    onInput?: any;
    onKeyDown?: any;
    autoFocus?: boolean;
    placeholder?: string;
    onKeyPress?: any;
}
export const InputAddress = ({
    type = "text",
    inputStyle = null,
    onTextChange,
    onBlur,
    value = "",
    title = null,
    unit = "",
    placeholder = "",
    wrapperStyles = null,
    disabled = false,
    maxLength = 524288,
    onInput = null,
    onKeyDown = null,
    autoFocus = false,
    onKeyPress = null,
}: Types) => {
    const numberInputOnWheelPreventChange = (e: any) => {
        e.target.blur();
        e.stopPropagation();
        setTimeout(() => {
            e.target.focus();
        }, 0);
    };
    return (
        <Wrapper style={wrapperStyles}>
            {title && <Title>{title}</Title>}
            <Input
                onKeyPress={onKeyPress}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                onInput={onInput}
                maxLength={maxLength}
                disabled={disabled}
                placeholder={placeholder}
                type={type}
                onChange={onTextChange}
                value={value}
                unit={unit}
                onBlur={onBlur}
                style={inputStyle}
                onWheel={numberInputOnWheelPreventChange}
            />
            {unit && <Span>{unit}</Span>}
        </Wrapper>
    );
};

const Title = styled.p`
    font-size: 11px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.app_grey2};
    margin-bottom: 3px;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 100%;
`;

const Span = styled.div`
    height: 20px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 25px;
    font-size: 14px;
    font-weight: 600;
`;
const Input = styled.input<{ unit: string }>`
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    text-align: flex-start;
    outline: none;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.color.app_grey7};
    border-radius: 4px;
    padding: 0 25px;
    color: ${({ theme }) => theme.color.black};
    &:focus {
        border-color: ${({ theme }) => theme.color.app_blue2};
        border-width: 2px;
    }
    &::placeholder {
        color: ${({ theme }) => theme.color.app_grey7};
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    @media (min-width: 1279px) {
        font-size: 14px;
        height: 36px;
    }
`;
