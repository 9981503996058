import styled from "styled-components";
import { Text } from "../../styled/typography";

interface Types {
    active?: boolean;
    title: string;
    completeness?: boolean;
}

const ProgressElementDesktop = ({ active = false, completeness = false, title }: Types) => {
    return (
        <Wrapper active={active} completeness={completeness}>
            {/* {completeness && (
                <CheckCircleOutlined
                    color={theme.color.app_blue2}
                    style={{ marginRight: 10, color: theme.color.app_blue2, fontWeight: 700, fontSize: 16 }}
                />
            )} */}
            <CustomText active={active} completeness={completeness}>
                {title}
            </CustomText>
        </Wrapper>
    );
};

export default ProgressElementDesktop;

const CustomText = styled(Text)<{ active: boolean; completeness: boolean }>`
    font-size: 12px;
    white-space: nowrap;
    font-weight: ${(props) => (props.active ? 700 : props.completeness ? 700 : 400)};
    color: ${(props) =>
        props.active
            ? ({ theme }) => theme.color.white
            : props.completeness
            ? ({ theme }) => theme.color.app_blue2
            : ({ theme }) => theme.color.app_grey2};
`;
const Wrapper = styled.div<{ active: boolean; completeness: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 20px;
    width: 100%;
    background-color: ${(props) =>
        props.active ? ({ theme }) => theme.color.app_blue2 : ({ theme }) => theme.color.app_lightBlue};
`;
