import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getUserDataAction } from "../../actions/account.action";
import { useGuestHosesToUserCart } from "../../hooks/localStoreHosesMaintenance/useGuestHosesToUserCart/useGuestHosesToUserCart";
import { logInService } from "../../services/account.service";
import { setAuthorizationHeader } from "../../utils/auth.utils";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import InputRegular from "../inputs/InputRegular";
interface LogInData {
    userId: number | string;
    accessToken: string;
    refreshToken: string;
}

export const LogInEmail = ({ setError }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { guestHosesToUserCart } = useGuestHosesToUserCart();
    const logIn = async (values: any) => {
        try {
            const { data } = await logInService({ email: values.email, password: values.password });
            await saveTokensToAsync(data);
            fetchUserData(data?.userId);
            guestHosesToUserCart(data?.userId);
            navigate("/");
        } catch (error) {
            setError("Błędny login lub hasło");
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    };
    const saveTokensToAsync = async ({ userId, accessToken, refreshToken }: LogInData) => {
        setAuthorizationHeader(accessToken);
        await window.localStorage.setItem("userData", JSON.stringify(userId));
        await window.localStorage.setItem("accessToken", accessToken);
        await window.localStorage.setItem("refreshToken", refreshToken);
        goBack();
    };
    const goBack = () => {
        navigate(-1);
    };
    const fetchUserData = (userId: string | number) => {
        dispatch(getUserDataAction(userId));
    };
    const LoginSchema = Yup.object().shape({
        email: Yup.string().email("Nieprawidłowy adres e-mail").required("Wymagane"),
        password: Yup.string().min(6, "Hasło musi zawierać co najmniej 6 znaków").required("Wymagane"),
    });
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            logIn(values);
        },
    });
    return (
        <div style={{ width: "100%" }}>
            <InputRegular
                onTextChange={formik.handleChange("email")}
                autoFocus={true}
                value={formik.values.email}
                onBlur={formik.handleBlur("email")}
                placeholder="Email"
            />
            <div style={{ height: 10 }} />
            <InputRegular
                onTextChange={formik.handleChange("password")}
                value={formik.values.password}
                onBlur={formik.handleBlur("password")}
                type="password"
                placeholder="Hasło"
                onKeyDown={(e: any) => (e.key === "Enter" ? formik.handleSubmit() : null)}
            />

            {/* <ForgotPassword onClick={() => forgotPassword()}>Nie pamiętasz hasła?</ForgotPassword> */}
            <div style={{ height: 30 }} />
            <AppButtonRegular title="Zaloguj" onClick={formik.handleSubmit} disable={!formik.isValid} />
        </div>
    );
};
