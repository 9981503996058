import React from "react";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconArrowRight from "../../icons/Icon_ArrowRight";
import { Text } from "../../styled/typography";
interface Types {
    title: string;
    header: string;
    borderBottom?: boolean;
    collapsible?: boolean;
    icon?: any;
    isOpen?: boolean;
    width?: any;
}

const SummaryRegularElement = ({
    title,
    header,
    borderBottom = true,
    collapsible = false,
    icon,
    isOpen,
    width = "auto",
}: Types) => {
    return (
        <Wrapper style={{ borderBottomColor: !borderBottom ? "transparent" : theme.color.app_grey, width: width }}>
            <Title>{header}</Title>
            <Text>{title}</Text>
            {collapsible && (
                <div
                    style={{
                        borderRadius: 100,
                        backgroundColor: "white",
                        height: 20,
                        width: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)",
                        transitionProperty: "transform",
                        transitionDuration: "0.2s",
                        marginLeft: 10,
                    }}>
                    <IconArrowRight />
                </div>
            )}
        </Wrapper>
    );
};

export default SummaryRegularElement;

const Title = styled(Text)`
    font-weight: 400;
    margin: 0;
    color: ${({ theme }) => theme.color.app_grey2};
    width: 75px;
    margin-right: 70px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 42px;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: transparent;
    animation: myAnim 500ms ease 0s 1 normal forwards;
    @keyframes myAnim {
        0% {
            opacity: 0;
            transform: translateX(-30px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
`;
