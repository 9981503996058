import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { addHoseToCartAction } from "../actions/addHoseToCart.action";
import { setProgressAction } from "../actions/progress.action";
import { fetchSleeveAction } from "../actions/sleeve.action";
import FittingsPosition from "../components/additionalData/FittingsPosition";
import HoseLength from "../components/additionalData/HoseLength";
import { AppButtonRegular } from "../components/buttons/App_ButtonRegular";
import { CartPriceResumeWrap } from "../components/cart/CartPriceResumeWrap";
import ListWrapper from "../components/listWrappers/ListWrapper";
import { useAppDispatch } from "../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../reducers";
import { addHoseToCartServiceTypes } from "../services/addHoseToCart.service";
import { RouteWrapper } from "../styled/wrappers";
import { coverObjectStringify } from "../utils/coverObjectStringify";
import { countSingleHosePrice } from "../utils/price/countSingleHosePrice";
import { HoseCover } from "../components/additionalData/HoseCover";

const angles = ["90 stopni", "45 stopni"];

const AdditionalDataRoute = () => {
    const currentHose = useSelector((state: any) => state.currentHose);
    const sleeve = useSelector((state: RootState) => state.sleeve.sleeve);
    const { coverData, coverLength, coverPercentageLength, coverPosition } = useSelector(
        (state: RootState) => state.currentHose.cover
    );
    const userData = useSelector((state: RootState) => state.account.user.userData);
    const isLogged = useSelector((state: RootState) => state.account.isLogged);
    const [error, setError] = useState("");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setProgressAction("additions", "right"));
        dispatch(fetchSleeveAction(currentHose.hose.parameters.symbol, currentHose.leftFitting.parameters.symbol));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const hoseData: addHoseToCartServiceTypes = {
        hoseSymbol: currentHose.hose.parameters.symbol,
        leftFittingSymbol: currentHose.leftFitting.parameters.symbol,
        rightFittingSymbol: currentHose.rightFitting.parameters.symbol,
        sleeveSymbol: sleeve?.symbol,
        length: currentHose.hose.length,
        ending: currentHose.hose.orientation,
        clientId: isLogged ? userData?.id : 0,
        cover: coverPercentageLength
            ? coverObjectStringify({
                  symbol: coverData?.Symbol,
                  length: coverPercentageLength,
                  position: coverPosition?.value || "LEFT",
              })
            : null,
    };
    const addHoseIdToLocalStorage = (hoseId: string) => {
        let hosesIdList: string[] = [];
        //@ts-ignore
        hosesIdList = JSON.parse(window.localStorage.getItem("hoses")) || [];
        hosesIdList.push(hoseId);
        window.localStorage.setItem("hoses", JSON.stringify(hosesIdList));
    };

    const addHoseToCart = () => {
        setError("");
        if (currentHose?.hose?.length < 20) {
            setError("Minimalna długość przewodu to 20 centymetrów");
            return setTimeout(() => setError(""), 3000);
        }

        dispatch(addHoseToCartAction(hoseData))
            //@ts-ignore
            .then((r: any) => {
                if (!isLogged) {
                    addHoseIdToLocalStorage(r.action.payload.data);
                }
                navigate("/Cart");
            })
            .catch(() => setError("Ups, coś poszło nie tak. Spróbuj ponownie."));
    };

    const isAngle = (hose: any) => {
        return angles.includes(hose.leftFitting.angle) || angles.includes(hose.rightFitting.angle);
    };
    const isBothAngle = (hose: any) => {
        return angles.includes(hose.leftFitting.angle) && angles.includes(hose.rightFitting.angle);
    };
    const isEyelet = (hose: any) => {
        return hose.leftFitting.type === "oczko" || hose.rightFitting.type === "oczko";
    };
    const isBothEleyet = (hose: any) => {
        return hose.leftFitting.type === "oczko" && hose.rightFitting.type === "oczko";
    };

    const renderFittingsPosition = () => {
        const condition =
            isBothAngle(currentHose) || isBothEleyet(currentHose) || (isAngle(currentHose) && isEyelet(currentHose));

        if (condition) {
            return <FittingsPosition />;
        }
        return null;
    };

    const currentHosePrice = countSingleHosePrice({
        length: currentHose?.hose.length,
        hosePrice: currentHose?.hose.parameters.cena,
        leftFittingPrice: currentHose?.leftFitting?.parameters?.cena,
        rightFittingPrice: currentHose?.rightFitting?.parameters?.cena,
        sleevePrice: sleeve?.cena,
        coverPrice: coverData?.cena,
        coverLength: coverLength,
    });

    return (
        <RouteWrapper>
            <ListWrapper
                showSummary={false}
                title={"Pozostałe parametry"}
                list={
                    <Wrapper>
                        <HoseLength />
                        {renderFittingsPosition()}
                        {currentHose?.hose?.length >= 20 && (
                            <HoseCover hoseSymbol={currentHose.hose.parameters.symbol} />
                        )}
                        {currentHose?.hose.length >= 20 && (
                            <CartPriceResumeWrap price={currentHosePrice} description="Cena przewodu" />
                        )}
                        {error?.length > 1 && <p style={{ color: "red", fontSize: 14, fontWeight: 400 }}>{error}</p>}
                        <BottomWrap>
                            <AppButtonRegular onClick={() => addHoseToCart()} title="Dodaj do koszyka" />
                        </BottomWrap>
                    </Wrapper>
                }
            />
        </RouteWrapper>
    );
};

export default AdditionalDataRoute;

const BottomWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`;
