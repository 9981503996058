export const switchOrderStatus = (status: string) => {
    switch (status) {
        case 'incompleted':
            return '| Nie opłacone';
        case 'pending':
            return 'Zamówienie przyjęte | Opłacone'
        case 'in_progress':
            return 'W przygotowaniu';
        case 'finished':
            return 'Wysłane';
        default:
            return 'nieznany';
    }
}
export const switchHoseStatus = (status: string) => {
    switch (status) {
        case 'in_cart':
            return 'W koszyku';
        case 'pending':
            return 'Dodany do zamówienia'
        case 'in_progress':
            return 'W przygotowaniu';
        case 'active':
            return 'Aktywny';
        default:
            return 'nieznany status';
    }
}