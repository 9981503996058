import styled from "styled-components";
import { getFittingIcon } from "../../../routes/FittingAngleRoute/helpers";
interface Types {
    angle: any;
    selectTypeAndAngle: any;
    type: any;
    isLast: any;
}

const ListItemFittingTypeSelect = ({ angle, selectTypeAndAngle, type, isLast }: Types) => {
    return (
        <Wrapper isLast={isLast} onClick={() => selectTypeAndAngle(type, angle)}>
            <FittingIconContainer>{getFittingIcon(angle, type)}</FittingIconContainer>
            <Text>Zakucie {angle}</Text>
        </Wrapper>
    );
};

export default ListItemFittingTypeSelect;

const FittingIconContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px 0 20px;
`;
const Text = styled.p`
    margin: 0;
    padding: 0 20px 0 20px;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
    width: 120px;
    font-weight: 500;
    font-size: 14px;
`;
const Wrapper = styled.div<{ isLast: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 58px;
    border-width: 1px;
    border-style: solid;
    border-top: none;
    border-bottom-color: ${({ theme, isLast }) => (isLast ? theme.color.app_blue2 : theme.color.app_grey)};
    border-left-color: ${({ theme }) => theme.color.app_blue2};
    border-right-color: ${({ theme }) => theme.color.app_blue2};
    width: 100%;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
    }
`;
