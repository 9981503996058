import * as React from "react"

const IconPosition0Svg = ({SIZE = 30, COLOR = '#FFF', ROTATE_COLOR = '#000'}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={SIZE}
		height={SIZE}
		viewBox="0 0 50 50"
	>
		<path
			fill={COLOR}
			d="M22.162 21.365a.246.246 0 0 1-.177-.074L11.81 11.076a.25.25 0 0 1 .355-.353L22.34 20.938a.25.25 0 0 1-.178.427zM39.572 38.855a.249.249 0 0 1-.177-.073l-10.16-10.21a.25.25 0 1 1 .354-.354l10.16 10.21a.25.25 0 0 1-.177.427zM29.532 21.325a.25.25 0 0 1-.176-.428L39.593 10.74a.25.25 0 1 1 .352.355L29.708 21.252a.248.248 0 0 1-.176.073zM12.032 38.705a.25.25 0 0 1-.176-.428l10.2-10.131a.25.25 0 0 1 .353.356l-10.2 10.131a.256.256 0 0 1-.177.072zM45.522 24.945h-14.67a.25.25 0 0 1 0-.5h14.67a.25.25 0 0 1 0 .5zM20.922 24.945H6.252a.25.25 0 0 1 0-.5h14.67a.25.25 0 0 1 0 .5zM25.892 44.425a.25.25 0 0 1-.25-.25v-13.75a.25.25 0 0 1 .5 0v13.75a.25.25 0 0 1-.25.25zM25.892 8.155a.25.25 0 0 1-.25-.25V5.001a.25.25 0 0 1 .5 0v2.904a.25.25 0 0 1-.25.25z"
		/>
		<path
			fill={COLOR}
			d="M25.872 44.55c-10.973 0-19.9-8.927-19.9-19.9 0-10.973 8.927-19.899 19.9-19.899 10.973 0 19.9 8.927 19.9 19.899 0 10.973-8.927 19.9-19.9 19.9zm0-39.299c-10.697 0-19.4 8.703-19.4 19.399s8.703 19.4 19.4 19.4 19.4-8.703 19.4-19.4-8.703-19.399-19.4-19.399z"
		/>
		<path fill={ROTATE_COLOR}
			d="M25.721 27.225h-.347a.25.25 0 0 1 0-.5h.347c.799 0 1.45-.65 1.45-1.449v-.581a.25.25 0 0 1 .5 0v.581a1.952 1.952 0 0 1-1.95 1.949z"/>
		<path fill={ROTATE_COLOR}
			d="M25.892 30.675a.254.254 0 0 1-.125-.033l-4.971-2.861a.25.25 0 0 1-.125-.216v-5.73c0-.089.047-.171.125-.216l1.24-.72 1.04-.6a.247.247 0 0 1 .336.083v.001l.009.015c.02.037.029.078.029.118v5.39c0 1.291 1.05 2.34 2.34 2.34h.34c1.291 0 2.34-1.05 2.34-2.34v-5.301c0-.041.01-.082.031-.12a.04.04 0 0 0 .005-.009h.001a.245.245 0 0 1 .164-.113h.001a.24.24 0 0 1 .174.029l2.129 1.229a.251.251 0 0 1 .125.217v5.73c0 .09-.048.172-.125.217l-4.96 2.86a.27.27 0 0 1-.123.03zm-3.534-2.566 3.534 2.028 4.71-2.717v-5.441l-1.63-.94v4.866a2.843 2.843 0 0 1-2.84 2.84h-.34a2.843 2.843 0 0 1-2.84-2.84v-4.957l-1.78 1.031v5.442l1.186.688zm6.034-11.644h-4.84a.25.25 0 0 1-.25-.25v-1.204l-.447-.572a.246.246 0 0 1-.053-.154v-.39a.25.25 0 0 1 .25-.25h2.84a.25.25 0 0 1 0 .5h-2.59v.054l.447.572a.246.246 0 0 1 .053.154v1.04h4.34v-.781l-2.251-.009a.25.25 0 0 1-.249-.251c0-.139.134-.234.251-.249l2.5.01a.25.25 0 0 1 .249.25v1.28a.25.25 0 0 1-.25.25z"/>
		<path fill={ROTATE_COLOR}
			d="M26.132 28.745h-.34a2.843 2.843 0 0 1-2.84-2.84v-9.69a.25.25 0 0 1 .25-.25h5.521a.25.25 0 0 1 .25.25v9.69a2.845 2.845 0 0 1-2.841 2.84zm-2.68-12.28v9.44c0 1.291 1.05 2.34 2.34 2.34h.34c1.291 0 2.34-1.05 2.34-2.34v-9.44h-5.02zM30.25 14.143h-.442a1.743 1.743 0 0 1-1.737-1.718l-.032-3.017a1.725 1.725 0 0 1 .501-1.241 1.727 1.727 0 0 1 1.234-.515h.476a.25.25 0 0 1 0 .5h-.476c-.333 0-.645.13-.879.367a1.229 1.229 0 0 0-.357.884l.032 3.017a1.241 1.241 0 0 0 1.237 1.224h.442a.25.25 0 0 1 .001.499z"/>
		<path fill={ROTATE_COLOR}
			d="M26.802 14.145h-1.88c-.948 0-1.728-.77-1.74-1.717l-.03-3.021a1.736 1.736 0 0 1 1.741-1.753h1.88c.942 0 1.718.77 1.729 1.717l.04 3.01c.006.469-.172.912-.502 1.246-.33.334-.769.518-1.238.518zm-1.91-5.99a1.24 1.24 0 0 0-.886.368 1.22 1.22 0 0 0-.354.879l.03 3.021a1.244 1.244 0 0 0 1.24 1.222h1.88a1.236 1.236 0 0 0 1.239-1.256l-.04-3.01a1.223 1.223 0 0 0-1.229-1.223h-1.88z"/>
		<path fill={ROTATE_COLOR}
			d="M21.918 14.142h-.475a.25.25 0 0 1 0-.5h.475c.333 0 .645-.13.879-.367a1.23 1.23 0 0 0 .358-.883l-.033-3.016a1.241 1.241 0 0 0-1.237-1.225h-.442a.25.25 0 0 1 0-.5h.442c.947 0 1.727.771 1.737 1.718l.033 3.017c.005.468-.173.908-.502 1.241a1.727 1.727 0 0 1-1.235.515z"/>
		<path fill={ROTATE_COLOR}
			d="M30.641 14.146h-1.69a.25.25 0 0 1 0-.5h1.69a.524.524 0 0 0 .521-.526V8.675a.52.52 0 0 0-.521-.52h-9.358a.521.521 0 0 0-.521.52v4.445c0 .29.233.526.521.526h1.769a.25.25 0 0 1 0 .5h-1.769c-.562 0-1.021-.46-1.021-1.026V8.675c0-.562.458-1.02 1.021-1.02h9.358c.562 0 1.021.458 1.021 1.02v4.445c0 .565-.458 1.026-1.021 1.026z"/>
		<path fill={ROTATE_COLOR}
			d="M28.952 14.145h-5.9a.25.25 0 0 1 0-.5h5.84c.138 0 .279.112.279.25s-.081.25-.219.25zM28.422 15.185l-4.05-.01a.25.25 0 0 1 0-.5l4.021.01c.138 0 .264.113.264.25s-.098.25-.235.25z"/>
		<path fill={ROTATE_COLOR}
			d="M23.552 16.465a.25.25 0 0 1-.25-.25v-1.204l-.447-.572a.246.246 0 0 1-.053-.154v-.39a.25.25 0 0 1 .5 0v.304l.447.572a.246.246 0 0 1 .053.154v1.29a.25.25 0 0 1-.25.25zM28.392 16.465a.25.25 0 0 1-.25-.25v-1.28c0-.056.019-.12.053-.164l.447-.572v-.304a.25.25 0 0 1 .5 0v.39c0 .056-.019.11-.053.154l-.447.572v1.204a.25.25 0 0 1-.25.25z"/>
	</svg>
)

export default IconPosition0Svg
