import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { setCurrentHoseAction, setCurrentHoseDimensionAction } from "../actions/currentHose.action";
import { fetchHoseDNsAction } from "../actions/hoseDiameters.action";
import { removeProgressAction } from "../actions/progress.action";
import { ListItemHoseCollapsible } from "../components/listElements/ListItem_HoseCollapsible";
import HoseDimensionListHeader from "../components/listHeaders/HoseDimensionListHeader";
import ListWrapper from "../components/listWrappers/ListWrapper";
import HoseSelectModal from "../components/modals/HoseSelect_Modal";
import { dn_units } from "../data/dn_units";
import { RouteWrapper } from "../styled/wrappers";
import { ListWrapperSubtitle } from "../components/listWrappers/ListWrapperSubtitle";

const HoseDiameterRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState<any>({ state: false, symbol: 0 });

    const currentHoseCategory = useSelector((state: any) => state.currentHose.hose.category);
    const { loading, hoseDns } = useSelector((state: any) => state.hose);
    const hoseDiametersExtended = hoseDns?.map((item: any) => {
        return { ...item, ...dn_units[item.diameter] };
    });
    const hoses = hoseDiametersExtended?.map((a: any) => a.hoses).flat(1);

    useEffect(() => {
        dispatch(setCurrentHoseDimensionAction(null));
        dispatch(setCurrentHoseAction(null));
        dispatch(removeProgressAction("hose", "left"));
        dispatch(removeProgressAction("hose", "hose"));
        dispatch(fetchHoseDNsAction(currentHoseCategory.id));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectHose = (data: any) => {
        dispatch(setCurrentHoseDimensionAction(dn_units[data?.srednica_wewnetrzna]));
        dispatch(setCurrentHoseAction(data));
        navigate("/create/fittingType");
    };
    return (
        <RouteWrapper>
            <ListWrapper
                listHeader={<HoseDimensionListHeader />}
                title={"Wybierz DN (średnica wewnętrzna węża)"}
                subtitle={
                    <ListWrapperSubtitle
                        description="Średnicę wewnętrzną (DN) możesz odczytać z posiadanego węża lub zmierzyć suwmiarką jak na rysunku"
                        btnText="Pomoc - Jak zmierzyć DN węża"
                        url={"https://zakuwanie24.pl/jak-rozpoznac-srednice-wewnetrzna-weza/"}
                    />
                }
                list={
                    <>
                        <MoonLoader color={"black"} loading={loading} size={30} speedMultiplier={0.4} />
                        {!loading && (
                            <>
                                {hoseDiametersExtended?.map((item: any, index: number) => (
                                    <ListItemHoseCollapsible
                                        item={item}
                                        key={index + item?.diameter}
                                        index={index + item?.diameter}
                                        setOpen={({ state, symbol }: any) =>
                                            setOpen({
                                                state: state,
                                                symbol: symbol,
                                            })
                                        }
                                        selectHose={(data: any) => selectHose(data)}
                                    />
                                ))}
                            </>
                        )}
                    </>
                }
            />
            {hoseDiametersExtended.length > 0 && (
                <HoseSelectModal
                    data={hoses.find((item: any) => item.symbol === open.symbol)}
                    isOpen={open.state}
                    setOpen={() => setOpen({ state: false, index: 0 })}
                    selectHose={(data: any) => selectHose(data)}
                />
            )}
        </RouteWrapper>
    );
};

export default HoseDiameterRoute;
