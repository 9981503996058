import styled from 'styled-components';

export const Header = styled.h1`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
`;
export const TitleHeader = styled.h2`
    font-size: 17px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
export const SubHeader = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
export const Text = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
export const Helper = styled.p`
    font-size: 10px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.black};
`;
export const ErrorText = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.color.app_red};
    text-align: center;
    font-weight: 500;
`