import IconFittingOuterSvg from "../icons/Icon_FittingOuterSvg";
import IconFittingInnerSvg from "../icons/Icon_FittingInnerSvg";
import IconFittingEyeSvg from "../icons/Icon_FittingEyeSvg";
import IconFittingFlancheSvg from "../icons/Icon_FittingFlancheSvg";
import IconFittingUnusualSvg from "../icons/Icon_FittingUnusualSvg";
import React from "react";

export const switchFittingIconByName = (name: any) => {
    switch (name) {
        case "zewnetrzne":
            return <IconFittingOuterSvg SIZE={25} COLOR={"#000"} />;
        case "wewnetrzne":
            return <IconFittingInnerSvg SIZE={25} COLOR={"#000"} />;
        case "oczko":
            return <IconFittingEyeSvg SIZE={25} COLOR={"#000"} />;
        case "flansza":
            return <IconFittingFlancheSvg SIZE={25} COLOR={"#000"} />;
        case "nietypowe":
            return <IconFittingUnusualSvg SIZE={25} COLOR={"#000"} />;
        default:
            return "wewnetrzne";
    }
};
export const switchFittingTypeName = (name: any) => {
    switch (name) {
        case "zewnetrzne":
            return "gwint zewnętrzny";
        case "wewnetrzne":
            return "gwint wewnętrzny";
        case "oczko":
            return "oczko";
        case "flansza":
            return "flansza";
        case "stozkowe":
            return "stożkowe";
        case "nietypowe":
            return "nietypowe";
        default:
            return "nietypowe";
    }
};
