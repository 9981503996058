import React from "react";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconCloseSvg from "../../icons/Icon_CloseSvg";

export const Specification = ({ images, setSpecificationVisibility }: any) => {
    const imageList = images
        .slice(2)
        .map((img, i) => <Image key={i} src={`${process.env.REACT_APP_API_URL}/${img}`} alt="hose" />);

    return (
        <Wrapper onClick={() => setSpecificationVisibility(false)}>
            <CloseWrap onClick={() => setSpecificationVisibility(false)}>
                <IconCloseSvg COLOR={theme.color.white} SIZE={40} />
            </CloseWrap>
            {imageList}
        </Wrapper>
    );
};

const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10%;
    cursor: pointer;
`;

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    width: 100vw;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 0;
    overflow: scroll;
`;
const Image = styled.img`
    width: 70%;
    margin-bottom: 40px;
`;
