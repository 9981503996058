import { CheckCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppButtonRegular } from "../components/buttons/App_ButtonRegular";
import { ReusableInfoElementWithEmoji } from "../components/elements/ReusableInfoElementWithEmoji";
import { Header } from "../styled/typography";

export const PaymentCompletedRoute = () => {
    const navigate = useNavigate();

    const goToCart = () => {
        navigate("/Cart");
    };

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            //@ts-ignore
            if (window.gtag) {
                console.log("---===--- konwersja poszla a przynajmniej powinna");
                //@ts-ignore
                window.gtag("event", "conversion", {
                    send_to: "AW-11223401130/_2TXCL-hnvkYEKqF3ucp",
                    value: 1.0,
                    currency: "PLN",
                    transaction_id: "",
                });
            }
        }
    }, []);

    return (
        <Wrapper>
            <Content>
                <HeaderCustom>
                    <CheckCircleOutlined style={{ fontSize: 28, marginRight: 10 }} />
                    Twoja transakcja zakończyła się sukcesem
                </HeaderCustom>

                <ReusableInfoElementWithEmoji
                    style={{ backgroundColor: "white", marginTop: 20, marginBottom: 20 }}
                    infoRowStyle={{ alignItems: "center" }}
                    infos={[
                        {
                            emoji: "🚚",
                            description:
                                "Zamówienie zostanie zrealizowane najszybciej jak sie da i dostarczone pod wskazany adres",
                        },
                        {
                            emoji: "💌",
                            description: "Na podany adres email otrzymasz potwierdzenie wysłania przesyłki",
                        },
                    ]}
                />
                <AppButtonRegular title={"Dalej"} onClick={goToCart} />
            </Content>
        </Wrapper>
    );
};
const HeaderCustom = styled(Header)`
    display: flex;
    align-items: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
`;
const Wrapper = styled.div`
    border-radius: 3px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px;
    padding: 50px;
    max-width: 500px;
`;
