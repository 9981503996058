import { combineReducers } from "redux";
import { accountReducer } from "./account.reducer";
import { addHoseToCartReducer } from "./addHoseToCart.reducer";
import { currentHoseReducer } from "./currentHose.reducer";
import { fittingsReducer } from "./fittings.reducer";
import { hoseReducer } from "./hose.reducer";
import { inAppStateReducer } from "./inAppState.reducer";
import { orderReducer } from "./order.reducer";
import { progressReducer } from "./progress.reducer";
import { sleeveReducer } from "./sleeve.reducer";
import { coverReducer } from "./cover.reducer";

export const rootReducer = combineReducers({
    currentHose: currentHoseReducer,
    inAppStateReducer: inAppStateReducer,
    hose: hoseReducer,
    fittings: fittingsReducer,
    progress: progressReducer,
    sleeve: sleeveReducer,
    cover: coverReducer,
    account: accountReducer,
    orders: orderReducer,
    guestCart: addHoseToCartReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
