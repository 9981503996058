import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import useScreenSize from "use-screen-size";
import {
    setLeftFittingAngleAction,
    setLeftFittingTypeAction,
    setRightFittingAngleAction,
    setRightFittingTypeAction,
} from "../../actions/currentHose.action";
import { fetchFittingTypesAndAnglesAction } from "../../actions/fittingTypes.action";
import { setProgressAction } from "../../actions/progress.action";
import { ListItemFittingTypeCollapsible } from "../../components/listElements/collapsible/ListItem_FittingTypeCollapsible";
import { ListHeaderTableDescription } from "../../components/listHeaders/ListHeaderTableDescription";
import ListWrapper from "../../components/listWrappers/ListWrapper";
import { ListWrapperSubtitle } from "../../components/listWrappers/ListWrapperSubtitle";
import { theme } from "../../constants/Theme";
import IconFittingSvg from "../../icons/Icon_FittingSvg";
import { RootState } from "../../reducers";
import { RouteWrapper } from "../../styled/wrappers";

const FittingTypeRoute = () => {
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const side = useSelector((state: any) => state.inAppStateReducer.hoseSide);

    const { parameters, category } = useSelector((state: RootState) => state.currentHose.hose);
    const { loading, typesAndAngles } = useSelector((state: any) => state.fittings);

    const sideString = side === "LEFT" ? "lewego" : "prawego";

    useEffect(() => {
        if (side === "LEFT") {
            dispatch(setLeftFittingTypeAction(null));
            dispatch(setProgressAction("left", "hose"));
        } else if (side === "RIGHT") {
            dispatch(setProgressAction("right", "left"));
            dispatch(setRightFittingTypeAction(null));
        }
        dispatch(fetchFittingTypesAndAnglesAction(parameters.symbol, category.id));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectCurrentTypeAndAngle = (type: any, angle: any) => {
        if (side === "LEFT") {
            dispatch(setLeftFittingTypeAction(type));
            dispatch(setLeftFittingAngleAction(angle));
        } else if (side === "RIGHT") {
            dispatch(setRightFittingTypeAction(type));
            dispatch(setRightFittingAngleAction(angle));
        }
        navigate("/create/fittingDiameter");
    };

    return (
        <RouteWrapper>
            <ListWrapper
                icon={<IconFittingSvg COLOR={theme.color.black} SIZE={screenSize.width > 1120 ? 40 : 25} />}
                title={`Wybierz rodzaj ${sideString} zakucia`}
                subtitle={
                    <ListWrapperSubtitle
                        description="Dla wybranego przewodu dostępne są następujące rodzaje zakuć. W kolejnym kroku wybierzesz kąt"
                        btnText="Pomoc - Jak wybrać rodzaj zakucia?"
                        url={"https://zakuwanie24.pl/jak-wybrac-rodzaj-i-kat-zakonczenia/"}
                    />
                }
                listHeader={
                    <>
                        <ListHeaderTableDescription
                            items={[
                                { text: "Rysunek", width: "100px" },
                                { text: "Rodzaj zakucia", width: "75%" },
                            ]}
                        />
                    </>
                }
                list={
                    <>
                        <MoonLoader color={"white"} loading={loading} size={50} speedMultiplier={0.4} />
                        {!loading && (
                            <>
                                {typesAndAngles?.map((item: any, index: number) => (
                                    <ListItemFittingTypeCollapsible
                                        item={item}
                                        key={index}
                                        index={index}
                                        selectTypeAndAngle={(type, angle) => selectCurrentTypeAndAngle(type, angle)}
                                    />
                                ))}
                            </>
                        )}
                    </>
                }
            />
        </RouteWrapper>
    );
};

export default FittingTypeRoute;
