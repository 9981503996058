import styled from "styled-components";

export const ListHeaderTableDescription = ({ items }: any) => {
    return (
        <TextsWrap>
            {items.map((item: any) => (
                <Text width={item?.width}>{item?.text}</Text>
            ))}
        </TextsWrap>
    );
};

const Text = styled.p<{ width: any }>`
    margin: 0;
    width: ${({ width }) => width || "100%"};
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: ${({ theme }) => theme.color.app_blue2};
`;

const TextsWrap = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 54px 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_blue};
`;
