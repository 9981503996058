export const useCartTotalQuantity = (hoses: any) => {

    const cartTotalQuantity = hoses?.reduce((a: any, b: any) => {
        return a + (!b.activity ? 0 : b.quantity);
    }, 0);

    return {
        cartTotalQuantity,
    };
};
