import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { SummaryMobile } from "../summary/summaryMobile/SummaryMobile";
import ProgressElementDesktop from "./ProgressElementDesktop";

const ProgressBar = () => {
    const screenSize = useScreenSize();
    let location = useLocation();
    const progressState = useSelector((state: any) => state.progress);
    const { active, completed } = progressState;

    const [isScrolled, setScrolled] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = useCallback(() => {
        if (window.scrollY > 20) {
            setScrolled(true);
        } else if (window.scrollY === 0) {
            setScrolled(false);
        }
    }, []);
    return (
        <Wrapper>
            <Section style={{ height: screenSize.width < 1024 && isScrolled ? 32 : 42 }}>
                <ProgressElementDesktop
                    title={"1. Przewód"}
                    active={active === "hose"}
                    completeness={completed.includes("hose")}
                />
                <ProgressElementDesktop
                    title={"2. Lewe zakucie"}
                    active={active === "left"}
                    completeness={completed.includes("left")}
                />
                <ProgressElementDesktop
                    title={"3. Prawe zakucie"}
                    active={active === "right"}
                    completeness={completed.includes("right")}
                />
            </Section>
            {screenSize.width <= 1023 && location.pathname !== "/" && <SummaryMobile isScrolled={isScrolled} />}
        </Wrapper>
    );
};

export default ProgressBar;

const Wrapper = styled.div`
    width: 100%;
    position: sticky;
    top: 48px;
    z-index: 10;
`;
const Section = styled.section`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: scroll;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
    transition: height 0.2s ease-in-out;
`;
