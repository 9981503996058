import axios from "./axiosInterceptor";

export const setDefaultBaseUrl = () => {
    const baseURL = process.env.REACT_APP_API_URL
    axios.defaults.baseURL = baseURL
}
export const setApiToken = () => {
    const apiToken = process.env.REACT_APP_MY_API_KEY;
    axios.defaults.headers.common["api-token"] = apiToken;
}
export const setAuthorizationHeader = (token: string) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

