import { FETCH_FITTING_TYPES, FETCH_FITTING_TYPES_AND_ANGLES } from "../constants/actionNames";
import { fetchFittingType, fetchFittingTypesAndAngles } from "../services/fittingType.service";

export const fetchFittingTypesAction = (hoseSymbol: string, categoryId: string | number) => ({
    type: FETCH_FITTING_TYPES,
    payload: fetchFittingType(hoseSymbol, categoryId),
});
export const fetchFittingTypesAndAnglesAction = (hoseSymbol: string, categoryId: string | number) => ({
    type: FETCH_FITTING_TYPES_AND_ANGLES,
    payload: fetchFittingTypesAndAngles(hoseSymbol, categoryId),
});
