import { ACCOUNT_REGISTER, USER_DATA_CHANGE, GET_USER_DATA, SET_COURIER_INFO, SET_INVOICE_DATA, SET_TEMP_ADDRESS, REMOVE_USER_DATA, SEND_PHONE_SMS_VERIFICATION, SEND_PHONE_SMS_CODE, SET_PHONE_NUMBER_FOR_SMS_VERIFICATION, UPDATE_HOSES_LIST, SET_COMMENT } from "../constants/actionNames";
import {
    accountRegisterProps,
    accountRegisterService,
    getUserDataService,
    phoneSmsCodeService,
    phoneSmsVerificationService,
    updateHosesListService,
    userDataChangeService,
} from "../services/account.service";

export const registerAccountAction = (props: accountRegisterProps) => ({
    type: ACCOUNT_REGISTER,
    payload: accountRegisterService(props),
});
export const userDataChangeAction = (props: any) => ({
    type: USER_DATA_CHANGE,
    payload: userDataChangeService(props),
});
export const getUserDataAction = (userId: string | number) => ({
    type: GET_USER_DATA,
    payload: getUserDataService(userId),
});
export const clearUserData = () => ({
    type: REMOVE_USER_DATA,
});
export const setTempAddress = (props: any) => ({
    type: SET_TEMP_ADDRESS,
    payload: props,
});
export const setTempInvoiceData = (props: any) => ({
    type: SET_INVOICE_DATA,
    payload: props,
});
export const setCourierInfo = (props: any) => ({
    type: SET_COURIER_INFO,
    payload: props,
});
export const setComment = (props: any) => ({
    type: SET_COMMENT,
    payload: props,
});
export const phoneSmsVerificationAction = (props: any) => ({
    type: SEND_PHONE_SMS_VERIFICATION,
    payload: phoneSmsVerificationService(props),
})
export const setPhoneNumberForSmsVerificationAction = (props: any) => ({
    type: SET_PHONE_NUMBER_FOR_SMS_VERIFICATION,
    payload: props,
})
export const phoneSmsCodeAction = (props: any) => ({
    type: SEND_PHONE_SMS_CODE,
    payload: phoneSmsCodeService(props),
})
export const updateHosesListAction = (symbolsString: string) => ({
    type: UPDATE_HOSES_LIST,
    payload: updateHosesListService(symbolsString),
});

