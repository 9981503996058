import * as React from "react";

const IconFittingFlanche90Svg = ({ SIZE = 40, COLOR = "#000" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" xmlSpace="preserve" width={SIZE} height={SIZE}>
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.35}
            d="M29.1 17.3c-.1 0-.2.1-.2.2v1.2c0 .1.1.2.2.2s.2-.1.2-.2v-1.2c.1-.1 0-.2-.2-.2zM40.2 17.3c-.1 0-.2.1-.2.2v1.2c0 .1.1.2.2.2s.2-.1.2-.2v-1.2c0-.1-.1-.2-.2-.2zM34.6 17.3c-.1 0-.2.1-.2.2v1.2c0 .1.1.2.2.2s.2-.1.2-.2v-1.2c.1-.1-.1-.2-.2-.2z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.35}
            d="M47.1 12.5h-2.6l-2.7-1h-1.6c-.1 0-.2.1-.2.2v.8h-2.8l-1.4-.5H34.6c-.1 0-.2.1-.2.2v.3h-2.5l-1-.5h-1.5v-.4c0-.1-.1-.2-.2-.2h-9c-8 0-14.4 6.5-14.4 14.5v4.6l-.9.9s-.1.1-.1.2v3.3H3.6c-.5 0-.9.4-.9.9v3.6c0 .5.4.9.9.9h15c.5 0 .9-.4.9-.9v-3.6c0-.5-.4-.9-.9-.9h-1.7v-3.4c0-.1 0-.1-.1-.2l-.9-.9V27c0-3 2.3-5.5 5.2-5.5h8c.1 0 .2-.1.2-.2v-.4h1.5l.9-.5h2.5v.3c0 .1.1.2.2.2H35.6l1.3-.5h2.8v.8c0 .1.1.2.2.2h1.6l2.7-1h2.6c.1 0 .2-.1.2-.2v-7.4c.3-.2.2-.3.1-.3zM18.6 35.2c.2 0 .4.2.4.4v3.6c0 .2-.2.4-.4.4h-15c-.2 0-.4-.2-.4-.4v-3.6c0-.2.2-.4.4-.4h15zm28.2-15.3h-2.5l-2.7 1h-1.2v-.8c0-.1-.1-.2-.3-.2h-3.2l-1.3.5h-.8v-.3c0-.1-.1-.2-.2-.2h-2.9l-.9.5h-1.4v-.5c0-.1-.1-.2-.2-.2s-.2.1-.2.2V21h-7.9c-3.1 0-5.7 2.8-5.7 6.1V30.5c0 .1 0 .1.1.2l.9.9v3.2H5.2v-3.2l.8-.9h5.8c.1 0 .2-.1.3-.2 0-.1-.1-.2-.2-.2H6.2v-4.5c0-7.7 6.3-14 13.9-14h8.8V16.6c0 .1.1.2.2.2s.2-.1.2-.2v-4.1h1.4l.9.5h2.7v3.6c0 .1.1.2.2.2s.2-.1.2-.2v-4.2h.8l1.3.5h2.9v3.6c0 .1.1.2.2.2s.2-.1.2-.2v-4.7h1.2l2.7 1h2.5v7.1z"
        />
        <path
            fill={COLOR}
            stroke={COLOR}
            strokeWidth={0.35}
            d="M9.7 21c-.2-.1-.3-.2-.2-.3 1.2-2.9 3.7-5.2 6.7-6.2.1 0 .3 0 .3.2s0 .3-.2.3c-2.9.9-5.2 3.1-6.4 5.9 0 .1-.1.1-.2.1z"
        />
    </svg>
);

export default IconFittingFlanche90Svg;
