import axios from "./axiosInterceptor";
export const getNewAccessToken = async () => {
    try {
        const refreshToken = await localStorage.getItem("refreshToken");
        const response = await axios.post("refresh-token.php", `refreshToken=Bearer ${refreshToken}`);

        if (response.status === 200 && response.data === "Signature doesn't match") {
            console.error("Error: Signature doesn't match");
            return "ERROR";
        } else if (response.data !== "Signature doesn't match") {
            const newAccessToken = response.data;
            await localStorage.setItem("accessToken", newAccessToken);
            return newAccessToken;
        } else {
            return "ERROR";
        }
    } catch (error) {
        return "ERROR"
    }
};
