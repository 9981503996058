import {SET_PROGRESS_ACTIVE, REMOVE_PROGRESS_ACTIVE} from '../constants/actionNames'
import {progressTypes} from "../reducers/progress.reducer";

export const setProgressAction = (active: progressTypes["active"] , completed: progressTypes["active"]) => ({
	type: SET_PROGRESS_ACTIVE,
	payload: {active: active, completed: completed}
})
export const removeProgressAction = (active: progressTypes["active"], completed: progressTypes["active"]) => ({
	type: REMOVE_PROGRESS_ACTIVE,
	payload: {active: active, completed: completed}
})
