export const extractSymbolsFromHosesList = (hosesList) => {
    const symbols = new Set();

    hosesList.forEach(hose => {
        ['leftFittingDetails', 'rightFittingDetails', 'sleeveDetails', 'hoseDetails']?.forEach(detailType => {
            symbols?.add(hose[detailType]?.symbol);
        });
    });

    return Array.from(symbols).join(',');
};
