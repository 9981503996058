import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const RegulaminRoute = () => {
    const terms = `
    Regulamin sklepu internetowego konfigurator zakuwanie24.pl

określający m.in. zasady zawierania umów sprzedaży poprzez Sklep, zawierający najważniejsze informacje o Sprzedawcy, Sklepie oraz o prawach Konsumenta

**Postanowienia dotyczące Przedsiębiorcy uprzywilejowanego mają zastosowanie do umów zawartych od dnia 1 stycznia 2021 r.**

**SPIS TREŚCI**

**§ 1** Definicje

**§ 2** Kontakt ze Sprzedawcą

**§ 3** Wymogi techniczne

**§ 4** Zakupy w Sklepie

**§ 5** Płatności

**§ 6** Realizacja zamówienia

**§ 7** Prawo odstąpienia od umowy

**§ 8** Wyjątki od prawa odstąpienia od umowy

**§ 9** Reklamacje

**§ 10** Dane osobowe

**§ 11** Opinie o sklepie internetowym

**§ 12** Zastrzeżenia

**Załącznik nr 1:** Wzór formularza odstąpienia od umowy

## **§ 1 DEFINICJE**

**Dni robocze** – dni od poniedziałku do piątku za wyjątkiem dni ustawowo wolnych od pracy.

**Konsument** – konsument w rozumieniu przepisów Kodeksu cywilnego.

**Konto** – uregulowana odrębnym regulaminem nieodpłatna funkcja Sklepu (usługa świadczona drogą elektroniczną), dzięki której Kupujący może założyć w Sklepie swoje indywidualne konto.

**Kupujący** – każdy podmiot kupujący w Sklepie.

**Kupujący uprzywilejowany** – Konsument lub Przedsiębiorca uprzywilejowany.

**Przedsiębiorca uprzywilejowany** – osoba fizyczna zawierająca ze Sprzedawcą umowę bezpośrednio związaną z jej działalnością gospodarczą, ale nieposiadającą dla niej charakteru zawodowego (definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).

**Regulamin** – niniejszy regulamin.

**Sklep** – sklep internetowy zakuwanie24.pl prowadzony przez Sprzedawcę pod adresem https://konfigurator.zakuwanie24.pl .

**Sprzedawca** – MIROSŁAW ANDRZEJ JACHYM, przedsiębiorca prowadzący działalność gospodarczą pod firmą EWEREST JACHYM MIROSŁAW, wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez ministra właściwego do spraw gospodarki i prowadzenia Centralnej Ewidencji i Informacji o Działalności Gospodarczej, NIP 8171113100, nr REGON 830443781, ul. Wojsławska 43, 39-300 Mielec.

## **§ 2 KONTAKT ZE SPRZEDAWCĄ**

1. Adres pocztowy: ul. Wojsławska 43, 39-300 Mielec
2. Adres e-mail: info@zakuwanie24.pl
3. Telefon: 603304530

## **§ 3 WYMOGI TECHNICZNE**

1. Dla prawidłowego funkcjonowania Sklepu potrzebne jest:
    - urządzenie z dostępem do Internetu
    - przeglądarka internetowa obsługująca JavaScript.
2. Dla złożenia zamówienia w Sklepie, poza wymogami określonymi w ust. 1, niezbędne jest aktywne konto e-mail.

## **§ 4 ZAKUPY W SKLEPIE**

1. Ceny komponentów wyświetlane w Konfiguratorze są całkowitymi cenami za dany komponent (wąż, zakuć, tulejki).
2. Sprzedawca zwraca uwagę, że na całkowitą cenę zamówienia składa się suma cen skonfigurowanych komponentów oraz, jeśli w danym przypadku ma to zastosowanie, koszty dostawy skonfigurowanego przewodu hydraulicznego.
3. Aby złożyć zamówienie, należy skonfigurować przewód hydrauliczny, dodając do niego wymagane komponenty, a następnie dodać skonfigurowany produkt do koszyka.
4. Następnie Kupujący wybiera z dostępnych w Sklepie: sposób dostawy towaru oraz metodę płatności za zamówienie, a także podaje dane niezbędne do zrealizowania złożonego zamówienia.
5. Zamówienie zostaje złożone w momencie potwierdzenia jego treści i zaakceptowania Regulaminu przez Kupującego.
6. Złożenie zamówienia jest tożsame z zawarciem umowy sprzedaży pomiędzy Kupującym a Sprzedawcą.
7. Sprzedawca przekaże Kupującemu uprzywilejowanemu potwierdzenie zawarcia umowy sprzedaży na trwałym nośniku najpóźniej w momencie dostarczenia towaru.

## **§ 5 PŁATNOŚCI**

1. Za złożone zamówienie można zapłacić, w zależności od wyboru Kupującego:
    1. za pośrednictwem platformy płatniczej PayU.
2. Płatności za pośrednictwem platformy płatniczej PayU, podmiotem świadczącym obsługę płatności online jest PayU S.A.
3. Kupujący dokonując zakupów w Sklepie akceptuje stosowanie faktur elektronicznych przez Sprzedawcę.

## **§ 6 REALIZACJA ZAMÓWIENIA**

1. Sprzedawca jest obowiązany do dostarczenia towaru bez wad.
2. Termin realizacji zamówienia wskazany jest w Sklepie.
3. Sprzedawca przystąpi do realizacji zamówienia po jego opłaceniu.
4. W sytuacji, gdy w ramach jednego zamówienia Kupujący zakupił towary o różnym terminie realizacji, zamówienie zostanie zrealizowane w terminie właściwym dla towaru o najdłuższym terminie.
5. Towar dostarczany jest wyłącznie na terytorium Rzeczypospolitej Polskiej.
6. Towary zakupione w Sklepie dostarczane są za pośrednictwem firmy kurierskiej.

## **§ 7 PRAWO ODSTĄPIENIA OD UMOWY**

1. Kupujący uprzywilejowany ma prawo odstąpić od umowy zawartej ze Sprzedawcą za pośrednictwem Sklepu, z zastrzeżeniem § 8 Regulaminu, w terminie 14 dni bez podania jakiejkolwiek przyczyny.
2. Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia:
    1. w którym Kupujący uprzywilejowany wszedł w posiadanie towaru lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Kupującego uprzywilejowanego weszła w posiadanie tego towaru;
    2. w którym Kupujący uprzywilejowany wszedł w posiadanie ostatniej z rzeczy lub w którym osoba trzecia, inna niż przewoźnik i wskazana przez Kupującego uprzywilejowanego, weszła w posiadanie ostatniej z rzeczy w przypadku umowy zobowiązującej do przeniesienia własności wielu rzeczy, które dostarczane są osobno;
    3. zawarcia umowy - w przypadku umowy o dostarczenie treści cyfrowych.
3. Aby Kupujący uprzywilejowany mógł skorzystać z prawa odstąpienia od umowy musi poinformować Sprzedawcę, korzystając z danych podanych w § 2 Regulaminu, o swojej decyzji o odstąpieniu od umowy w drodze jednoznacznego oświadczenia (na przykład pismo wysłane pocztą lub informacja przekazana pocztą elektroniczną).
4. Aby zachować termin do odstąpienia od umowy wystarczy, że Kupujący uprzywilejowany wyśle informację dotyczącą wykonania przysługującego mu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.

**SKUTKI ODSTĄPIENIA OD UMOWY**
5. W przypadku odstąpienia od zawartej umowy Sprzedawca zwraca Kupującemu uprzywilejowanemu wszystkie otrzymane od niego płatności, w tym koszty dostarczenia towaru (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Kupującego uprzywilejowanego sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez Sprzedawcę), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym Sprzedawca został poinformowany o decyzji Kupującego uprzywilejowanego o wykonaniu prawa odstąpienia od umowy.
6. Zwrotu płatności Sprzedawca dokona przy użyciu takich samych sposobów płatności, jakie zostały przez Kupującego uprzywilejowanego użyte w pierwotnej transakcji, chyba że Kupujący uprzywilejowany zgodzi się na inne rozwiązanie, w każdym przypadku Kupujący uprzywilejowany nie poniesie żadnych opłat w związku z tym zwrotem.
7. Sprzedawca może wstrzymać się ze zwrotem płatności do czasu otrzymania towaru lub do czasu dostarczenia mu dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.
8. Sprzedawca prosi o zwracanie towaru na adres: ul. Wojsławska 43, 39-300 Mielec niezwłocznie, a w każdym razie nie później niż 14 dni od dnia, w którym Kupujący uprzywilejowany poinformował Sprzedawcę o odstąpieniu od umowy sprzedaży. Termin jest zachowany, jeżeli Kupujący uprzywilejowany odeśle towar przed upływem terminu 14 dni.
9. Kupujący uprzywilejowany ponosi bezpośrednie koszty zwrotu towaru.
10. Kupujący uprzywilejowany odpowiada tylko za zmniejszenie wartości towaru wynikające z korzystania z niego w sposób inny niż było to konieczne do stwierdzenia charakteru, cech i funkcjonowania towaru.
11. Jeśli towar ze względu na swój charakter nie może zostać odesłany w zwykłym trybie pocztą, Kupujący uprzywilejowany również będzie musiał ponieść bezpośrednie koszty zwrotu towarów.

## **§ 8 WYJĄTKI OD PRAWA ODSTĄPIENIA OD UMOWY**

1. Prawo odstąpienia od umowy zawartej na odległość, o którym mowa w § 7 Regulaminu, nie przysługuje w odniesieniu do umowy:
    1. w której przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Kupującego uprzywilejowanego lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;
    2. w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia;
    3. w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;
    4. w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami;
    5. w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;
    6. o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę;
    7. w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi Sprzedawca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od umowy;
    8. o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Kupującego uprzywilejowanego przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez Sprzedawcę o utracie prawa odstąpienia od umowy.

## **§ 9 REKLAMACJE**

1. W przypadku wystąpienia wady towaru Konsument ma możliwość reklamowania wadliwego towaru na podstawie uregulowanej w Kodeksie cywilnym rękojmi lub gwarancji, o ile gwarancja została udzielona.
2. Korzystając z rękojmi Konsument może, na zasadach oraz w terminach określonych w Kodeksie cywilnym:
    1. złożyć oświadczenie o obniżeniu ceny,
    2. przy wadzie istotnej - złożyć oświadczenie o odstąpieniu od umowy,
    3. żądać wymiany rzeczy na wolną od wad,
    4. żądać usunięcia wady.
3. Sprzedawca prosi o składanie reklamacji na podstawie rękojmi na adres pocztowy lub elektroniczny wskazany w § 2 Regulaminu.
4. Jeśli okaże się, że dla rozpatrzenia reklamacji konieczne jest dostarczenie wadliwego towaru do Sprzedawcy, Konsument jest zobowiązany do dostarczenia tego towaru na koszt Sprzedawcy, na adres ul. Wojsławska 43, 39-300 Mielec.
5. Jeśli na towar została udzielona dodatkowo gwarancja, informacja o niej, a także o jej warunkach, jest dostępna w opisie produktu w Sklepie.
6. Reklamacje dotyczące działania Sklepu należy kierować na adres e-mail wskazany w § 2 Regulaminu.
7. Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14 dni.**POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ**
8. W przypadku, gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez Konsumenta rezultatu, Konsument może skorzystać m.in. z:
    1. mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Co do zasady postępowanie jest bezpłatne. Wykaz Inspektoratów znajduje się tutaj: https://www.uokik.gov.pl/wazne_adresy.php#faq595;
    2. pomocy właściwego terenowo stałego polubownego sądu konsumenckiego działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego należy złożyć wniosek o rozpatrzenie sprawy przed sądem polubownym. Co do zasady postępowanie jest bezpłatne. Wykaz sądów dostępny jest pod adresem: https://www.uokik.gov.pl/wazne_adresy.php#faq596;
    3. bezpłatnej pomocy miejskiego lub powiatowego rzecznika Konsumentów;
    4. internetowej platformy ODR dostępnej pod adresem: https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks.

## **§ 10 DANE OSOBOWE**

1. Administratorem danych osobowych przekazanych przez Kupującego podczas korzystania ze Sklepu jest Sprzedawca. Szczegółowe informacje dotyczące przetwarzania danych osobowych przez Sprzedawcę – w tym o pozostałych celach oraz podstawach przetwarzania danych, a także o odbiorcach danych – znajdują się w dostępnej w Sklepie Polityce prywatności – ze względu na zasadę przejrzystości, zawartą w ogólnym rozporządzeniu Parlamentu Europejskiego i Rady (UE) o ochronie danych – „**RODO**”.
2. Celem przetwarzania danych Kupującego przez Sprzedawcę, podanych przez Kupującego w związku z zakupami w Sklepie, jest realizacja zamówień. Podstawą przetwarzania danych osobowych w tym przypadku jest:
    - umowa sprzedaży lub działania podejmowane na żądanie Kupującego, zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO),
    - ciążący na Sprzedawcy obowiązek prawny związany z rachunkowością (art. 6 ust. 1 lit. c) oraz
    - prawnie uzasadniony interes Sprzedawcy, polegający na przetwarzaniu danych w celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f RODO).
3. Podanie danych przez Kupującego jest dobrowolne, ale jednocześnie konieczne do zawarcia umowy sprzedaży. Niepodanie danych uniemożliwi zawarcie umowy sprzedaży w Sklepie.
4. Dane Kupującego podane w związku z zakupami w Sklepie będą przetwarzane do momentu, w którym:– w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
    1. przestanie obowiązywać umowa sprzedaży zawarta między Kupującym a Sprzedawcą;
    2. na Sprzedawcy przestanie ciążyć obowiązek prawny, zobowiązujący go do przetwarzania danych Kupującego;
    3. ustanie możliwość dochodzenia roszczeń przez Kupującego lub Sprzedawcę, związanych z umową sprzedaży zawartą przez Sklep;
    4. zostanie przyjęty sprzeciw Kupującego wobec przetwarzania jego danych osobowych – w przypadku gdy podstawą przetwarzania danych był uzasadniony interes Sprzedawcy
5. Kupującemu przysługuje prawo żądania:
    1. dostępu do swoich danych osobowych,
    2. ich sprostowania,
    3. usunięcia,
    4. ograniczenia przetwarzania,
    5. przeniesienia danych do innego administratoraa także prawo:
    6. wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych z przyczyn związanych ze szczególną sytuacją Kupującego – wobec przetwarzania dotyczących go danych osobowych, opartego na art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych interesach realizowanych przez administratora).
6. W celu realizacji swoich praw, Kupujący powinien skontaktować się ze Sprzedawcą przy wykorzystaniu danych z § 2 Regulaminu.
7. W przypadku gdy Kupujący uzna, że jego dane są przetwarzane niezgodnie z prawem, Kupujący może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.1.1.Klient Sklepu Internetowego ma możliwość dobrowolnego i nieodpłatnego wystawienia opinii dotyczącej zakupów dokonanych w Sklepie Internetowym. Przedmiotem opinii może być także ocena, zdjęcie lub recenzja zakupionego produktu w Sklepie Internetowym.,1.2.Sprzedawca po dokonanych zakupach w Sklepie Internetowym wysyła do Klienta wiadomość email z prośbą o wystawienie opinii oraz linkiem do formularza online umożliwiającego jej wystawienie – formularz online umożliwia udzielenie odpowiedzi na pytania Sprzedawcy dotyczące zakupów, ich ocenę, dodanie własnego opisu dot. opinii oraz zdjęcia zakupionego produktu. W razie braku wystawienia opinii po otrzymaniu pierwszego zaproszenia do wystawienia opinii Sprzedawca ponawia wysyłkę zaproszenia.,1.3.Opinia może być wystawiona jedynie przez Klienta, który dokonał zakupów w Sklepie Internetowym Sprzedawcy.,1.4.Wystawione przez Klienta opinie są publikowane przez Sprzedawcę w Sklepie Internetowym oraz wizytówce TrustMate.io.,1.5.Wystawienie opinii nie może być wykorzystywane przez Klienta do działań bezprawnych, w szczególności do działań stanowiących czyn nieuczciwej konkurencji wobec Sprzedawcy, czy też działań naruszających dobra osobiste, prawa własności intelektualnej lub inne prawa Sprzedawcy lub osób trzecich.,1.6.Opinia może być wystawiona tylko do faktycznie zakupionych produktów w Sklepie Internetowym Sprzedawcy. Zabronione jest zawieranie fikcyjnych/pozornych umów sprzedaży w celu wystawienia opinii. Autorem opinii nie może być także sam Sprzedawca ani jego pracownicy bez względu na podstawę zatrudnienia.,1.7.Wystawiona opinia może zostać w każdym czasie usunięta przez jej autora.
    
    ## **§ 11 OPINIE W SKLEPIE INTERNETOWYM**
    

## **§ 12 ZASTRZEŻENIA**

1. Zakazane jest dostarczanie przez Kupującego treści o charakterze bezprawnym.
2. Każdorazowo składane w Sklepie zamówienie stanowi odrębną umowę sprzedaży i wymaga osobnej akceptacji Regulaminu. Umowa zawierana jest na czas i w celu realizacji zamówienia.
3. Umowy zawierane na podstawie Regulaminu zawierane są w języku polskim.
4. W przypadku ewentualnego sporu z Kupującym niebędącym Kupującym uprzywilejowanym, sądem właściwym będzie sąd właściwy dla siedziby Sprzedawcy.
5. Postanowienia dotyczące towarów i umowy sprzedaży stosuje się odpowiednio do treści cyfrowych i umowy o dostarczanie treści cyfrowych, o ile Regulamin nie określa tych kwestii odrębnie.
6. Wszelka odpowiedzialność Sprzedawcy w stosunku do Kupującego niebędącego Kupującym uprzywilejowanym, w granicach prawem dopuszczonych, jest wyłączona.
7. Odpowiedzialność z tytułu rękojmi względem Przedsiębiorcy uprzywilejowanego jest wyłączona.

# **Regulamin konta**

w sklepie konfigurator zakuwanie24.pl

**Postanowienia dotyczące Przedsiębiorcy uprzywilejowanego mają zastosowanie do umów zawartych od dnia 1 stycznia 2021 r.**

**SPIS TREŚCI**

**§ 1** Definicje

**§ 2** Kontakt ze Sprzedawcą

**§ 3** Wymogi techniczne

**§ 4** Konto

**§ 5** Reklamacje

**§ 6** Dane osobowe

**§ 7** Zastrzeżenia

## **§ 1 DEFINICJE**

**Konsument** – konsument w rozumieniu przepisów Kodeksu cywilnego.

**Konto** – uregulowana w niniejszym regulaminie nieodpłatna funkcja Sklepu (usługa), dzięki której Kupujący może założyć w Sklepie swoje indywidualne konto.

**Kupujący** - każdy podmiot kupujący w Sklepie.

**Kupujący uprzywilejowany** – Konsument lub Przedsiębiorca uprzywilejowany.

**Przedsiębiorca uprzywilejowany** – osoba fizyczna zawierająca ze Sprzedawcą umowę bezpośrednio związaną z jej działalnością gospodarczą, ale nieposiadającą dla niej charakteru zawodowego (definicja obowiązuje dla umów zawartych od dnia 1 stycznia 2021 r.).

**Sklep** – sklep internetowy konfigurator zakuwanie24.pl prowadzony przez Sprzedawcę pod adresem https://konfigurator.zakuwanie24.pl

**Sprzedawca** – MIROSŁAW ANDRZEJ JACHYM, przedsiębiorca prowadzący działalność gospodarczą pod firmą EWEREST JACHYM MIROSŁAW, wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez ministra właściwego do spraw gospodarki i prowadzenia Centralnej Ewidencji i Informacji o Działalności Gospodarczej, NIP 8171113100, nr REGON 830443781, ul. Wojsławska 43, 39-300 Mielec.

## **§ 2 KONTAKT ZE SPRZEDAWCĄ**

1. Adres pocztowy: ul. Wojsławska 43, 39-300 Mielec
2. Adres e-mail: info@zakuwanie24.pl
3. Telefon: 603304530

## **§ 3 WYMOGI TECHNICZNE**

1. Dla prawidłowego funkcjonowania i założenia Konta potrzebne jest:
    - aktywne konto e-mail
    - urządzenie z dostępem do Internetu
    - przeglądarka internetowa obsługująca JavaScript i pliki cookies

## **§ 4 KONTO**

1. Założenie Konta jest całkowicie dobrowolne i zależne od woli Kupującego.
2. Konto daje Kupującemu dodatkowe możliwości, takie jak: przeglądanie historii zamówień złożonych przez Kupującego w Sklepie, sprawdzenie statusu zamówienia czy samodzielna edycja danych Kupującego.
3. W celu założenia Konta należy wypełnić stosowny formularz w Sklepie.
4. W momencie założenia Konta zawierana jest na czas nieokreślony pomiędzy Kupującym a Sprzedawcą umowa w zakresie prowadzenia Konta na zasadach wskazanych w niniejszym regulaminie.
5. Kupujący może bez ponoszenia jakichkolwiek kosztów w każdym czasie zrezygnować z Konta.
6. W celu dokonania rezygnacji z Konta należy wysłać swoją rezygnację do Sprzedawcy na adres e-mail: info@zakuwanie24.pl, czego skutkiem będzie niezwłoczne usunięcie Konta oraz rozwiązanie umowy w zakresie prowadzenia Konta.

## **§ 5 REKLAMACJE**

1. Reklamacje dotyczące funkcjonowania Konta należy kierować na adres e-mail info@zakuwanie24.pl.
2. Rozpatrzenie reklamacji przez Sprzedawcę nastąpi w terminie do 14 dni.**POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ**
3. W przypadku gdy postępowanie reklamacyjne nie przyniesie oczekiwanego przez Konsumenta rezultatu Konsument może skorzystać m.in. z:
    1. mediacji prowadzonej przez właściwy terenowo Wojewódzki Inspektorat Inspekcji Handlowej, do którego należy się zwrócić z wnioskiem o mediację. Co do zasady postępowanie jest bezpłatne. Wykaz Inspektoratów znajduje się tutaj: https://www.uokik.gov.pl/wazne_adresy.php#faq595;
    2. pomocy właściwego terenowo stałego polubownego sądu konsumenckiego działającego przy Wojewódzkim Inspektoracie Inspekcji Handlowej, do którego należy złożyć wniosek o rozpatrzenie sprawy przed sądem polubownym. Co do zasady postępowanie jest bezpłatne. Wykaz sądów dostępny jest pod adresem: https://www.uokik.gov.pl/wazne_adresy.php#faq596;
    3. bezpłatnej pomocy miejskiego lub powiatowego rzecznika Konsumentów;
    4. internetowej platformy ODR dostępnej pod adresem: https://ec.europa.eu/consumers/odr/main/?event=main.home.howitworks.

## **§ 6 DANE OSOBOWE**

1. Administratorem danych osobowych przekazanych przez Kupującego podczas korzystania z Konta jest Sprzedawca. Szczegółowe informacje dotyczące przetwarzania danych osobowych przez Sprzedawcę – w tym o pozostałych celach oraz podstawach przetwarzania danych, a także o odbiorcach danych, znajdują się w dostępnej w Sklepie Polityce prywatności – ze względu na zasadę przejrzystości, zawartą w ogólnym rozporządzeniu Parlamentu Europejskiego i Rady (UE) o ochronie danych – „**RODO**”.
2. Celem przetwarzania danych Kupującego jest prowadzenie Konta. Podstawą przetwarzania danych osobowych w tym przypadku jest umowa o świadczenie usługi lub działania podejmowane na żądanie Kupującego, zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO), a także prawnie uzasadniony interes Sprzedawcy, polegający na przetwarzaniu danych w celu ustalenia, dochodzenia lub obrony ewentualnych roszczeń (art. 6 ust. 1 lit. f RODO).
3. Podanie danych przez Kupującego jest dobrowolne, ale jednocześnie konieczne do prowadzenia Konta. Niepodanie danych oznacza, że Sprzedawca nie będzie mógł świadczyć usługi prowadzenia Konta.
4. Dane Kupującego będą przetwarzane do momentu, w którym:– w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
    1. Konto zostanie usunięte przez Kupującego lub Sprzedawcę na żądanie Kupującego
    2. ustanie możliwość dochodzenia roszczeń przez Kupującego lub Sprzedawcę, związanych z Kontem;
    3. zostanie przyjęty sprzeciw Kupującego wobec przetwarzania jego danych osobowych – w przypadku gdy podstawą przetwarzania danych był uzasadniony interes Sprzedawcy
5. Kupującemu przysługuje prawo żądania:
    1. dostępu do swoich danych osobowych,
    2. ich sprostowania,
    3. usunięcia,
    4. ograniczenia przetwarzania,
    5. przeniesienia danych do innego administratoraa także prawo:
    6. wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych z przyczyn związanych ze szczególną sytuacją Kupującego – wobec przetwarzania dotyczących go danych osobowych, opartego na art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych interesach realizowanych przez administratora).
6. W celu realizacji swoich praw, Kupujący powinien skontaktować się ze Sprzedawcą.
7. W przypadku gdy Kupujący uzna, że jego dane są przetwarzane niezgodnie z prawem, Kupujący może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych.

## **§ 7 ZASTRZEŻENIA**

1. Zakazane jest dostarczanie przez Kupującego treści o charakterze bezprawnym.
2. Umowa w zakresie prowadzenia Konta zawierana jest w języku polskim.
3. W przypadku zaistnienia ważnych przyczyn, o których mowa w ust. 4, Sprzedawca ma prawo do zmiany niniejszego regulaminu Konta.
4. Ważnymi przyczynami, o których mowa w ust. 3 są:
    1. konieczność dostosowania Sklepu do przepisów prawa mających zastosowanie do działalności Sklepu
    2. poprawa bezpieczeństwa świadczonej usługi
    3. zmiana funkcjonalności Konta wymagająca modyfikacji regulaminu Konta.
5. Kupujący zostanie poinformowany o planowanej zmianie regulaminu Konta co najmniej na 7 dni przed wprowadzeniem zmiany w życie za pośrednictwem wiadomości e-mail wysłanej na przypisany do Konta adres.
6. W przypadku gdy Kupujący nie wyrazi akceptacji dla planowanej zmiany, powinien poinformować o tym Sprzedawcę poprzez wysłanie odpowiedniej wiadomości na adres e-mail Sprzedawcy shop@zakuwanie24.pl, czego skutkiem będzie rozwiązanie umowy w zakresie prowadzenia Konta z chwilą wejścia w życie planowanej zmiany lub wcześniej, jeśli Kupujący zgłosi takie żądanie.
7. W sytuacji gdy Kupujący nie wyrazi sprzeciwu dla planowanej zmiany do chwili wejścia jej w życie przyjmuje się, że akceptuje ją, co nie stanowi żadnej przeszkody do rozwiązania umowy w przyszłości.
8. W przypadku ewentualnego sporu z Kupującym niebędącym Kupującym uprzywilejowanym, sądem właściwym będzie sąd właściwy dla siedziby Sprzedawcy.
    `;
    return (
        <TermsContainer>
            <ReactMarkdown>{terms}</ReactMarkdown>
        </TermsContainer>
    );
};
const TermsContainer = styled.div`
    padding: 40px;
    overflow: auto; // dodane, aby umożliwić przewijanie, jeśli tekst jest zbyt duży

    @media (max-width: 768px) {
        // dla ekranów o szerokości 768px i mniejszych
        font-size: 14px;
        line-height: 1.5;
    }
`;
