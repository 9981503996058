import {REMOVE_PROGRESS_ACTIVE, SET_PROGRESS_ACTIVE} from '../constants/actionNames';

export interface progressTypes {
	active: 'hose' | 'left' | 'right' | 'additions' | 'summary';
	completed: [progressTypes["active"]] | []
}

const INITIAL_STATE: progressTypes = {
	active: 'hose',
	completed: [],
}
export const progressReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case SET_PROGRESS_ACTIVE:
			return {...state, active: action.payload.active, completed: [...state.completed, action.payload.completed]};
		case REMOVE_PROGRESS_ACTIVE:
			return {...state, active: action.payload.active, completed: [...state.completed].filter(item => item !== action.payload.completed)};
		default:
			return state
	}
}

