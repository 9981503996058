import { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { setTempInvoiceData } from "../../actions/account.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { RootState } from "../../reducers";
import { TitleHeader } from "../../styled/typography";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";
import { InputAddress } from "../inputs/InputAddress";

export const CartInvoiceDataElement = () => {
    const dispatch = useAppDispatch();
    const [collapseOpen, setCollapseOpen] = useState(false);
    const { invoiceData, isLogged } = useSelector((state: RootState) => state.account);
    const { companyData } = useSelector((state: RootState) => state.account.user.userData);
    useEffect(() => {
        isLogged && dispatch(setTempInvoiceData(companyData)); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 10,
                marginBottom: 10,
                paddingBottom: 0,
                backgroundColor: theme.color.white,
                borderColor: theme.color.app_lightBlue,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <TitleAndImageWrap>
                            <Collapsible
                                onOpen={() => setCollapseOpen(true)}
                                onClose={() => setCollapseOpen(false)}
                                trigger={
                                    <div
                                        style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}>
                                        <TitleHeader>Dane do faktury</TitleHeader>
                                        <IconWrap
                                            style={{
                                                transform: collapseOpen ? "rotate(0deg)" : "rotate(180deg)",
                                                transitionProperty: "transform",
                                                transitionDuration: "0.2s",
                                            }}>
                                            <IconArrowDownSvg />
                                        </IconWrap>
                                    </div>
                                }>
                                <InputAddress
                                    wrapperStyles={{ marginTop: 10 }}
                                    value={invoiceData?.nip}
                                    onTextChange={(e: any) =>
                                        dispatch(setTempInvoiceData({ ...invoiceData, nip: e.target.value }))
                                    }
                                    title={"NIP"}
                                    placeholder="np. 817000000000"
                                />
                                <InputAddress
                                    wrapperStyles={{ marginTop: 8 }}
                                    value={invoiceData?.companyName}
                                    onTextChange={(e: any) =>
                                        dispatch(setTempInvoiceData({ ...invoiceData, companyName: e.target.value }))
                                    }
                                    title={"Firma"}
                                    placeholder="np. Moja Firma"
                                />
                                <InputAddress
                                    wrapperStyles={{ marginTop: 8 }}
                                    value={invoiceData?.address1}
                                    onTextChange={(e: any) =>
                                        dispatch(setTempInvoiceData({ ...invoiceData, address1: e.target.value }))
                                    }
                                    title={"Ulica i numer"}
                                    placeholder="np. Słowackiego 1/1"
                                />
                                <InputAddress
                                    wrapperStyles={{ marginTop: 8 }}
                                    value={invoiceData?.address2}
                                    onTextChange={(e: any) =>
                                        dispatch(setTempInvoiceData({ ...invoiceData, address2: e.target.value }))
                                    }
                                    title={"Kod pocztowy i miejscowość"}
                                    placeholder="np. 00-000 Warszawa"
                                />
                            </Collapsible>
                        </TitleAndImageWrap>
                    ),
                },
            ]}
        />
    );
};

const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TitleAndImageWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
