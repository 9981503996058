import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { phoneSmsVerificationAction, setPhoneNumberForSmsVerificationAction } from "../../actions/account.action";
import { theme } from "../../constants/Theme";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import InputRegular from "../inputs/InputRegular";

export const LogInSms = ({ setError, showSmsCodeInput }: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let location = useLocation();
    const [phone, setPhone] = useState("");
    const verifyPhoneNumber = async () => {
        dispatch(phoneSmsVerificationAction({ phone: phone }))
            //@ts-ignore
            .then(() => {
                dispatch(setPhoneNumberForSmsVerificationAction(phone));
                return showSmsCodeInput(true);
            })
            .catch(() => {
                return setError("Nie znaleziono takiego numeru telefonu w bazie. Spróbuj zalogować się emailem");
            });
    };

    return (
        <form onSubmit={(event) => event.preventDefault()} style={{ width: "100%" }}>
            <InputRegular
                onTextChange={(e: any) => {
                    setPhone(e.target.value);
                    setError("");
                }}
                value={phone}
                placeholder="Numer Telefonu"
                maxLength={9}
                type="number"
                onInput={(e: any) => (e.target.value = e.target.value.slice(0, 9))}
                autoFocus={true}
                onKeyDown={(e: any) => (e.key === "Enter" ? verifyPhoneNumber() : null)}
            />
            {phone?.length > 1 && (
                <CharsInfo style={{ color: phone?.length === 9 ? theme.color.app_blue : theme.color.app_red }}>
                    {phone?.length}/9 znaków
                </CharsInfo>
            )}
            <div style={{ height: 20 }} />
            <AppButtonRegular title="Wyślij Kod SMS" disable={phone?.length !== 9} onClick={verifyPhoneNumber} />
            <Link
                style={{ textDecoration: "none", width: "100%" }}
                to={`/Modal/Register`}
                state={{ backgroundLocation: location }}>
                <ForgotPassword onClick={() => navigate(-1)}>Lub utwórz konto</ForgotPassword>
            </Link>
        </form>
    );
};

const CharsInfo = styled.p`
    font-size: 10px;
    font-weight: 700;
    margin-left: 10%;
    margin-top: 5px;
`;
const ForgotPassword = styled.div`
    margin: 15px 0 20px 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.color.app_blue2};
    font-size: 12px;
    font-weight: 400;
`;
