import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === "production") {
            ReactGA.initialize("G-Q85PG6MZVD");
            ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
        }
    }, [location]);
}

export default usePageViews;
