import React from "react";
import styled from "styled-components";

export const ParamTabHose = ({ value, title, marginRight = 0 }: any) => {
    return (
        <Wrapper style={{ marginRight: marginRight }}>
            <Title>{title}</Title>
            <Value>{value}</Value>
        </Wrapper>
    );
};

const Title = styled.p`
    margin: 0;
    font-weight: 700;
    font-size: 13px;
    color: ${({ theme }) => theme.color.app_blue};
`;
const Value = styled.p`
    margin: 0 0 0 5px;
    font-weight: 400;
    font-size: 13px;
    color: ${({ theme }) => theme.color.black};
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 6px 0;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
`;
