export const ACCOUNT_REGISTER = "ACCOUNT_REGISTER";
export const LOG_OUT = "LOG_OUT";
export const USER_DATA_CHANGE = "USER_DATA_CHANGE";

export const TOGGLE_HELP = "TOGGLE_HELP";
export const FETCH_HOSE_DIAMETERS = "FETCH_HOSE_DIAMETERS";
export const FETCH_HOSE_DNS = "FETCH_HOSE_DNS";
export const FETCH_HOSES = "FETCH_HOSES";
export const FETCH_FITTING_DIAMETERS = "FETCH_FITTING_DIAMETERS";
export const FETCH_FITTING = "FETCH_FITTING";
export const UPDATE_HOSES_LIST = 'UPDATE_HOSES_LIST';

export const CURRENT_RESET = "CURRENT_RESET";
export const CURRENT_HOSE_CATEGORY = "CURRENT_HOSE_CATEGORY";
export const CURRENT_HOSE_DIMENSION = "CURRENT_HOSE_DIMENSION";
export const CURRENT_HOSE_LENGTH = "CURRENT_HOSE_LENGTH";
export const CURRENT_HOSE_ORIENTATION = "CURRENT_HOSE_ORIENTATION";
export const CURRENT_HOSE = "CURRENT_HOSE";
export const CURRENT_HOSE_COVER = "CURRENT_HOSE_COVER";
export const CURRENT_HOSE_COVER_LENGTH = "CURRENT_HOSE_COVER_LENGTH";
export const CURRENT_HOSE_COVER_POSITION = "CURRENT_HOSE_COVER_POSITION";
export const SET_PROGRESS_ACTIVE = "SET_PROGRESS_ACTIVE";
export const REMOVE_PROGRESS_ACTIVE = "REMOVE_PROGRESS_ACTIVE";
export const LEFT_FITTING_TYPE = "LEFT_FITTING_TYPE";
export const RIGTH_FITTING_TYPE = "RIGTH_FITTING_TYPE";
export const LEFT_FITTING_ANGLE = "LEFT_FITTING_ANGLE";
export const RIGTH_FITTING_Angle = "RIGTH_FITTING_Angle";
export const LEFT_FITTING_DIAMETER = "LEFT_FITTING_DIAMETER";
export const RIGHT_FITTING_DIAMETER = "RIGHT_FITTING_DIAMETER";
export const LEFT_FITTING_PARAMETERS = "LEFT_FITTING_PARAMETERS";
export const RIGHT_FITTING_PARAMETERS = "RIGHT_FITTING_PARAMETERS";

export const FETCH_FITTING_TYPES = "FETCH_FITTING_TYPES";
export const FETCH_FITTING_TYPES_AND_ANGLES = "FETCH_FITTING_TYPES_AND_ANGLES";
export const FETCH_FITTING_ANGLES = "FETCH_FITTING_ANGLES";

export const FETCH_FITTINGS = "FETCH_FITTINGS";
export const SET_HOSE_SIDE = "SET_HOSE_SIDE";
export const FETCH_SLEEVE = "FETCH_SLEEVE";
export const FETCH_COVERS = "FETCH_COVERS";
export const ADD_HOSE_TO_CART = "ADD_HOSE_TO_CART";
export const ADD_HOSE_WITH_SAME_QR_TO_CART = "ADD_HOSE_WITH_SAME_QR_TO_CART";
export const SET_TEMP_ADDRESS = "SET_TEMP_ADDRESS";
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const SET_COURIER_INFO = "SET_COURIER_INFO";
export const SET_COMMENT = "SET_COMMENT";
export const GET_USER_DATA = "GET_USER_DATA";
export const REMOVE_USER_DATA = "REMOVE_USER_DATA";
export const GET_HOSE_BY_ID = "GET_HOSE_BY_ID";
export const REMOVE_HOSE_BY_ID = "REMOVE_HOSE_BY_ID";
export const ADD_ORDER = "ADD_ORDER";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const SET_CART_ITEM_ACTIVITY = "SET_CART_ITEM_ACTIVITY";
export const SET_CART_ITEM_QUANTITY = "SET_CART_ITEM_QUANTITY";
export const CHANGE_HOSES_USER_BY_ID = "CHANGE_HOSES_USER_BY_ID";
export const GUEST_CART_HOSES = "GUEST_CART_HOSES";
export const SET_GUEST_CART_ITEM_QUANTITY = "SET_GUEST_CART_ITEM_QUANTITY";
export const SET_GUEST_CART_ITEM_ACTIVITY = "SET_GUEST_CART_ITEM_ACTIVITY";
export const GUEST_CART_HOSE_REMOVE = "GUEST_CART_HOSE_REMOVE";
export const CLEAR_GUEST_CART = "CLEAR_GUEST_CART";

export const SEND_PHONE_SMS_VERIFICATION = "SEND_PHONE_SMS_VERIFICATION";
export const SEND_PHONE_SMS_CODE = "SEND_PHONE_SMS_CODE";
export const SET_PHONE_NUMBER_FOR_SMS_VERIFICATION = "SET_PHONE_NUMBER_FOR_SMS_VERIFICATION";