import { useDispatch } from "react-redux";
import { addHoseToGuestCartAction, getHoseByIdAction } from "../../../actions/addHoseToCart.action";

interface HosesFromLSTypes {
    qrCode: string;
    id: string;
}
export const useGuestHosesToCart = () => {
    const dispatch = useDispatch();
    const removeFailedHoses = (array) => {
        return array.filter(item => item !== "Blad zapisu w bazie danych");
    };
    const hoseDataFromStorage = window.localStorage.getItem("hoses");

    const guestHosesToCart = () => {
        if (!hoseDataFromStorage) return false;
        if (hoseDataFromStorage === "undefined") return false;
        const hoses = JSON.parse(hoseDataFromStorage);
        const hosesFromStorage = removeFailedHoses(hoses);
        if (hosesFromStorage.length === 0) return;
        hosesFromStorage.map((hose: HosesFromLSTypes) => {
            const qrCode: string = hose.qrCode;
            //@ts-ignore
            return dispatch(getHoseByIdAction({ qrCode })).then((r: any) => {
                if (r.action.type === "GET_HOSE_BY_ID_FULFILLED") {
                    //@ts-ignore
                    return dispatch(addHoseToGuestCartAction(r.action.payload.data));
                }
            });
        });
    };
    return {
        guestHosesToCart: () => guestHosesToCart(),
    };
};
