import styled from "styled-components";
import { theme } from "../../../constants/Theme";
import IconArrowDownSvg from "../../../icons/Icon_ArrowDownSvg";

const ListItemFittingType = ({ icon, title, isOpen }: any) => {
    return (
        <Wrapper
            isOpen={isOpen}
            style={{
                borderBottomLeftRadius: isOpen ? 0 : 5,
                borderBottomRightRadius: isOpen ? 0 : 5,
                borderBottom: isOpen ?? "none",
                borderColor: isOpen ? theme.color.app_blue2 : theme.color.app_grey6,
            }}>
            <FittingIconContainer>{icon}</FittingIconContainer>

            <Text>{title}</Text>
            <IconWrap
                style={{
                    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
                    transitionProperty: "transform",
                    transitionDuration: "0.2s",
                }}>
                <IconArrowDownSvg />
            </IconWrap>
        </Wrapper>
    );
};

export default ListItemFittingType;

const FittingIconContainer = styled.div`
    height: 100%;
    width: 100px;
    border-right: 1px solid ${({ theme }) => theme.color.app_grey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Text = styled.p`
    margin: 0;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
    text-align: center;
    font-weight: 500;
    border-right: 1px solid ${({ theme }) => theme.color.app_grey};
`;
const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
export const Title = styled.h3`
    font-size: 16px;
    margin: 0;
    margin-left: 30px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;

const Wrapper = styled.div<{ isOpen: boolean }>`
    /* background-color: ${({ isOpen, theme }) => (isOpen ? theme.color.app_lightBlue : theme.color.white)}; */
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
        border-color: ${({ theme }) => theme.color.app_blue2} !important;
    }
`;
