import React from "react";
import styled from "styled-components";
import IconArrowDownSvg from "../../icons/Icon_ArrowDownSvg";
import { theme } from "../../constants/Theme";

const ListItemHoseDiameter = ({ data, isOpen }: any) => {
    return (
        <Wrapper
            style={{
                borderBottomLeftRadius: isOpen ? 0 : 5,
                borderBottomRightRadius: isOpen ? 0 : 5,
                borderBottom: isOpen ?? "none",
                borderColor: isOpen ? theme.color.app_blue2 : theme.color.app_grey6,
            }}>
            <TextsWrap>
                <TextContainer>
                    <Text>{data?.dn}</Text>
                </TextContainer>
                <TextContainer>
                    <Text>{data?.cale}</Text>
                </TextContainer>
                <TextContainer>
                    <Text>{data?.mm}</Text>
                </TextContainer>
            </TextsWrap>
            <IconWrap
                style={{
                    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
                    transitionProperty: "transform",
                    transitionDuration: "0.2s",
                }}>
                <IconArrowDownSvg />
            </IconWrap>
        </Wrapper>
    );
};

export default ListItemHoseDiameter;

const IconWrap = styled.div`
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const Text = styled.p`
    margin: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.color.black};
    text-align: center;
    font-weight: 500;
`;
const TextsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const TextContainer = styled.div`
    width: 100%;
    height: 100%;
    border-right: 1px solid ${({ theme }) => theme.color.app_grey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    height: 52px;
    width: 100%;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    :hover {
        background-color: ${({ theme }) => theme.color.app_lightBlue};
        border-color: ${({ theme }) => theme.color.app_blue2} !important;
    }
`;
