import * as React from "react"

function IconDiameterOuterSvg({SIZE = 40, COLOR = '#000'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 20.078 20.078"
		>
			<path
				data-name="Path 38"
				d="M11.972 9.526a1.982 1.982 0 01-2.447 2.447l-1.522 1.522a4.008 4.008 0 005.491-5.491z"
				fill={COLOR}
			/>
			<path
				data-name="Path 39"
				d="M8.106 10.552a1.982 1.982 0 012.447-2.447l1.522-1.522a4.008 4.008 0 00-5.491 5.491z"
				fill={COLOR}
			/>
			<path
				data-name="Path 40"
				d="M10.039 20.078a10.039 10.039 0 1110.039-10.039 10.05 10.05 0 01-10.039 10.039zm0-18.07a8.031 8.031 0 108.031 8.031 8.041 8.041 0 00-8.031-8.031z"
				fill={COLOR}
			/>
			<path
				data-name="Path 41"
				d="M10.394 10.394l3.549-3.549.71.71 1.065-3.194-3.194 1.064.71.71-3.55 3.549-3.549 3.549-.71-.71-1.065 3.194 3.194-1.065-.71-.71z"
				fill={COLOR}
			/>
		</svg>
	)
}

export default IconDiameterOuterSvg
