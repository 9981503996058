import { useState } from "react";
import Collapsible from "react-collapsible";
import ListItemFittingDiameter from "./ListItem_FittingDiameter";
import ListItemFittingSelect from "./ListItem_FittingSelect";

export const ListItemFittingCollapsible = ({ item, index, setOpen, selectFitting }: any) => {
    const [collapseOpen, setCollapseOpen] = useState(false);
    const sortedArray = item.fittings.sort((a: any, b: any) => {
        const valueA = a.krotki_opis ? parseInt(a.krotki_opis, 10) : -Infinity;
        const valueB = b.krotki_opis ? parseInt(b.krotki_opis, 10) : -Infinity;

        return valueB - valueA;
    });

    return (
        <Collapsible
            onOpen={() => setCollapseOpen(true)}
            onClose={() => setCollapseOpen(false)}
            trigger={<ListItemFittingDiameter isOpen={collapseOpen} data={item} key={index} />}>
            {sortedArray.map((item: any, index: any) => (
                <ListItemFittingSelect
                    isOpen={collapseOpen}
                    data={item}
                    key={index}
                    selectFitting={(data: any) => selectFitting(data)}
                    openModal={({ state, symbol }: any) =>
                        setOpen({
                            state: state,
                            symbol: symbol,
                        })
                    }
                />
            ))}
        </Collapsible>
    );
};
