import { FilePdfOutlined } from "@ant-design/icons";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { AppButtonText } from "../components/buttons/App_ButtonText";
import { theme } from "../constants/Theme";
import { setLeftPdfImage, setRightPdfImage } from "./SwitchPdfHoseImage";
import { LogoPdf } from "./base64/LogoPdf";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const OpenPdfButton = ({ hoseData }: any) => {
    const leftFittingDetails = hoseData?.leftFittingDetails;
    const rightFittingDetails = hoseData?.rightFittingDetails;
    // const coverDetails = hoseData?.coverDetails;
    const LeftPdfImage = setLeftPdfImage(leftFittingDetails);
    const RightPdfImage = setRightPdfImage(rightFittingDetails);
    const getDocDefinition = () => {
        return {
            watermark: { text: "Zakuwanie24.pl", color: "blue", opacity: 0.05, bold: false, italics: false },
            pageMargins: [20, 40, 20, 40],
            header: {
                stack: [
                    {
                        style: "header",
                        columns: [
                            {
                                image: LogoPdf,
                                fit: [160, 70],
                            },
                            // {
                            //     text: "Tu moze coś sobie kient napisac np nazwe swojej firmy",
                            //     style: "headerText",
                            // },
                        ],
                    },
                ],
            },

            content: [
                {
                    canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
                    margin: [0, 10, 0, 10],
                },
                { text: "Dokumentacja przewodu hydraulicznego", margin: [0, 0, 0, 4], bold: true },
                {
                    columns: [
                        {
                            width: "auto",
                            image: LeftPdfImage,
                            fit: [150, 150],
                            margin: [0, 0, 0, 0],
                        },
                        {
                            width: "auto",
                            image: RightPdfImage,
                            fit: [150, 150],
                            margin: [0, 0, 0, 0],
                        },
                        {
                            width: "auto",
                            canvas: [
                                { type: "line", x1: 0, y1: 0, x2: 0, y2: 100, lineWidth: 1, lineColor: "#A9A9A9" },
                            ],
                            margin: [10, 0, 20, 0],
                        },
                        // { text: "tu klient moze cos sobie napisac" },
                    ],
                    columnGap: 0,
                },

                {
                    canvas: [{ type: "line", x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }],
                    margin: [0, 10, 0, 5],
                },

                { text: "Podstawowe dane", style: "subheader" },

                {
                    columns: [
                        {
                            table: {
                                widths: [120, 280],
                                body: [
                                    [
                                        { text: "Długość", style: "text" },
                                        { text: hoseData?.length + " cm", style: "value" },
                                    ],
                                    [
                                        { text: "Orientacja zakończeń", style: "text" },
                                        { text: hoseData?.ending + " °", style: "value" },
                                    ],
                                    [
                                        { text: "Osłona", style: "text" },
                                        { text: "Brak", style: "value" },
                                    ],
                                ],
                            },
                        },
                        [
                            { text: "Zamów taki sam na zakuwanie24.pl", style: { fontSize: 8 }, margin: [3, 0, 3, 3] },
                            { qr: hoseData?.qrCode, fit: "50", margin: [10, 0, 0, 0] },
                            { text: hoseData?.qrCode, style: { fontSize: 9 }, margin: [13, 0, 0, 0] },
                        ],
                    ],
                },
                { text: "Przewód", style: "subheader" },
                {
                    table: {
                        widths: [120, "*"],
                        body: [
                            [
                                { text: "Nazwa", style: "text" },
                                { text: hoseData?.hoseDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "DN", style: "text" },
                                { text: hoseData?.hoseDetails?.srednica_wewnetrzna, style: "value" },
                            ],
                            [
                                { text: "Oploty", style: "text" },
                                { text: hoseData?.hoseDetails?.oploty, style: "value" },
                            ],
                            [
                                { text: "Ciśnienie robocze", style: "text" },
                                { text: hoseData?.hoseDetails?.cisnienie_robocze + " BAR", style: "value" },
                            ],
                            [
                                { text: "Ciśnienie rozrywania", style: "text" },
                                { text: hoseData?.hoseDetails?.cisnienie_rozrywania + " BAR", style: "value" },
                            ],
                            [
                                { text: "Temperatura pracy", style: "text" },
                                { text: hoseData?.hoseDetails?.temperatura_pracy, style: "value" },
                            ],
                            [
                                { text: "Średnica zewnętrzna", style: "text" },
                                { text: hoseData?.hoseDetails?.srednica_zewnetrzna + " mm", style: "value" },
                            ],
                            [
                                { text: "Promień gięcia", style: "text" },
                                { text: hoseData?.hoseDetails?.promien_giecia + " mm", style: "value" },
                            ],
                            [
                                { text: "Waga 1 metra", style: "text" },
                                { text: hoseData?.hoseDetails?.waga + " kg", style: "value" },
                            ],
                        ],
                    },
                },
                { text: "Lewe zakucie", style: "subheader" },
                {
                    table: {
                        widths: [120, "*"],
                        body: [
                            [
                                { text: "Nazwa", style: "text" },
                                { text: leftFittingDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "System", style: "text" },
                                { text: leftFittingDetails?.system, style: "value" },
                            ],
                            [
                                { text: "Typ zakucia", style: "text" },
                                { text: leftFittingDetails?.typ_zakucia, style: "value" },
                            ],
                            [
                                { text: "Kąt zakucia", style: "text" },
                                { text: leftFittingDetails?.kat_zakucia, style: "value" },
                            ],
                            [
                                { text: "Średnica gwintu", style: "text" },
                                { text: leftFittingDetails?.srednica_gwintu.split("_")[0], style: "value" },
                            ],
                        ],
                    },
                },
                { text: "Prawe zakucie", style: "subheader" },
                {
                    table: {
                        widths: [120, "*"],
                        body: [
                            [
                                { text: "Nazwa", style: "text" },
                                { text: rightFittingDetails?.nazwa, style: "value" },
                            ],
                            [
                                { text: "System", style: "text" },
                                { text: rightFittingDetails?.system, style: "value" },
                            ],
                            [
                                { text: "Typ zakucia", style: "text" },
                                { text: rightFittingDetails?.typ_zakucia, style: "value" },
                            ],
                            [
                                { text: "Kąt zakucia", style: "text" },
                                { text: rightFittingDetails?.kat_zakucia, style: "value" },
                            ],
                            [
                                { text: "Średnica gwintu", style: "text" },
                                { text: rightFittingDetails?.srednica_gwintu.split("_")[0], style: "value" },
                            ],
                        ],
                    },
                },
                // coverDetails && { text: "Osłona przewodu", style: "subheader" },
                // coverDetails && {
                //     table: {
                //         widths: [120, "*"],
                //         body: [
                //             [
                //                 { text: "Nazwa", style: "text" },
                //                 { text: coverDetails?.data?.nazwa, style: "value" },
                //             ],
                //             [
                //                 { text: "Długość osłony", style: "text" },
                //                 { text: coverDetails?.data?.length * 100 + "% długości przewodu", style: "value" },
                //             ],
                //             coverDetails?.data?.position && [
                //                 { text: "Połozenie osłony ", style: "text" },
                //                 { text: coverDetails?.data?.position, style: "value" },
                //             ],
                //         ],
                //     },
                // },
            ],
            styles: {
                header: { margin: [20, 20, 20, 20] },
                headerText: {
                    fontSize: 12,
                },
                subheader: {
                    fontSize: 12,
                    margin: [0, 5, 0, 1],
                },
                text: {
                    fontSize: 10,
                    bold: false,
                    margin: [10, 1, 10, 1],
                },
                value: {
                    fontSize: 11,
                    bold: true,
                    margin: [10, 1, 10, 1],
                },
            },
        };
    };
    const createAndOpenPdf = () => {
        const uniqueName = new Date().getTime();
        const docDefinition = getDocDefinition();

        pdfMake.createPdf(docDefinition).open({}, window.open("", uniqueName.toString()));
    };
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <FilePdfOutlined style={{ color: theme.color.app_blue2, marginRight: 5 }} />
            <AppButtonText title={"Dokumentacja PDF"} onClick={createAndOpenPdf} />
        </div>
    );
};

export default OpenPdfButton;
