import { InboxOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getOrderByIdAction } from "../actions/orders.action";
import { ListItemOrderHoses } from "../components/listElements/ListItem_OrderHoses";
import { useAppDispatch } from "../hooks/useAppDispatch/useAppDispatch";
import { RootState } from "../reducers";
import { RouteWrapper } from "../styled/wrappers";

export const SingleOrderRoute = () => {
    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const { order, loading } = useSelector((state: RootState) => state.orders);
    const hosesInOrder = order?.[2];
    useEffect(() => {
        dispatch(getOrderByIdAction(orderId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RouteWrapper>
            <Wrapper>
                <CartBody>
                    {!loading && hosesInOrder && (
                        <>
                            <Header>
                                <InboxOutlined style={{ fontSize: 28, marginRight: 10 }} />
                                Zamówienie 0000-{orderId}
                            </Header>
                            <Line />
                            <ElementValue>Data zamówienia {order?.[0]?.orderDate}</ElementValue>
                            <Line />
                            {hosesInOrder?.map((item: any, index: number) => (
                                <ListItemOrderHoses data={item} key={index} />
                            ))}
                        </>
                    )}
                </CartBody>
            </Wrapper>
        </RouteWrapper>
    );
};

const Header = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin: 24px 0;
    display: flex;
    align-items: center;
`;
const CartBody = styled.div`
    border-radius: 15px;
    padding: 17px;
    background-color: ${({ theme }) => theme.color.white};
    @media (max-width: 1024px) {
        width: auto;
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.white};
    box-sizing: border-box;
    min-height: 100vh;
    @media (min-width: 619px) {
        width: 612px;
    }
`;
const ElementValue = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.app_grey5};
    margin: 7px 0 13px 0;
`;
