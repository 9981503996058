import * as React from "react";
import { theme } from "../constants/Theme";

const IconArrowRight = ({ COLOR = theme.color.app_blue2 }) => (
    <svg width={5} height={9} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.15761 0.320827C1.253 0.320521 1.34724 0.340429 1.43342 0.379087C1.51961 0.417746 1.59553 0.474174 1.65563 0.544224L4.73952 4.16686C4.83343 4.2749 4.88477 4.41041 4.88477 4.55026C4.88477 4.69011 4.83343 4.82562 4.73952 4.93366L1.54709 8.5563C1.43871 8.6796 1.28298 8.75714 1.11414 8.77186C0.945312 8.78658 0.77721 8.73727 0.646821 8.63479C0.516431 8.53231 0.434434 8.38504 0.418868 8.22539C0.403302 8.06573 0.455442 7.90677 0.563817 7.78347L3.41785 4.54724L0.65959 1.31102C0.581514 1.22239 0.531918 1.11447 0.516671 1.00003C0.501425 0.885583 0.521165 0.769403 0.573556 0.665235C0.625948 0.561067 0.708798 0.473272 0.812303 0.412236C0.915808 0.3512 1.03564 0.319479 1.15761 0.320827Z"
            fill={COLOR}
        />
    </svg>
);

export default IconArrowRight;
