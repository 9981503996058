import * as React from "react"

function IconFittingInner90Svg({SIZE = 40, COLOR = '#000'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 28.745 44.51"
			transform="rotate(270)"
		>
			<path
				data-name="Path 682"
				d="M23.6 14.59v.17a.24.24 0 00.25.22h0a.24.24 0 00.22-.27v-.18a7 7 0 00-5.17-6 .249.249 0 00-.13.48 6.54 6.54 0 014.83 5.58z"
				fill={COLOR}
				stroke={COLOR}
				strokeWidth={0.35}
			/>
			<path
				data-name="Path 683"
				d="M22.58 36.93h-1.19a.25.25 0 100 .5h1.19a.25.25 0 100-.5z"
				fill={COLOR}
				stroke={COLOR}
				strokeWidth={0.35}
			/>
			<path
				data-name="Path 684"
				d="M22.58 31.38h-1.19a.25.25 0 100 .5h1.19a.25.25 0 00.25-.25.26.26 0 00-.25-.25z"
				fill={COLOR}
				stroke={COLOR}
				strokeWidth={0.35}
			/>
			<path
				data-name="Path 685"
				d="M22.58 25.89h-1.19a.25.25 0 00-.25.25.26.26 0 00.25.25h1.19a.25.25 0 00.25-.25.26.26 0 00-.25-.25z"
				fill={COLOR}
				stroke={COLOR}
				strokeWidth={0.35}
			/>
			<path
				data-name="Path 686"
				d="M28.44 36.94h-.8v-2.78l.49-1.29a.29.29 0 000-.09v-1.14a.25.25 0 00-.25-.25h-.26v-2.5l.48-.94a.2.2 0 000-.11v-1.69a.25.25 0 00-.25-.25h-.4v-7.73a13.61 13.61 0 00-13.6-13.6h-3.3l-.85-.71h-.47v-2.4A1.37 1.37 0 007.86.1h-6.4A1.36 1.36 0 00.1 1.46v.56h0v7.75h0v4.93h0v.22a1.36 1.36 0 001.36 1.36h6.4a1.37 1.37 0 001.37-1.36V12.8h.46l.86-.67h3.21a6.28 6.28 0 016.28 6.28v7.54h-.55a.25.25 0 00-.25.25v1.69a.36.36 0 000 .11l.48.94v2.5h-.26a.25.25 0 00-.25.25v1.14a.29.29 0 000 .09l.49 1.29v2.78h-.79a.25.25 0 00-.25.25v1.48a.28.28 0 000 .09l1 2.72v2.63a.25.25 0 00.25.25h7.48a.25.25 0 00.25-.25v-2.68l1-2.72a.29.29 0 000-.09v-1.44a.26.26 0 00-.2-.29zM.6 6.99a1.89 1.89 0 011.86-1.84h4.35A1.89 1.89 0 018.7 7.04v2.71a1.9 1.9 0 01-1.87 1.89H2.49A1.86 1.86 0 01.58 9.79zm8.13-1.44a2.75 2.75 0 00-.22-.26 2.33 2.33 0 00-.68-.46 2.55 2.55 0 00.9-.73zm0 5.58v1.45a2.75 2.75 0 00-.22-.26 2.63 2.63 0 00-.68-.46 2.46 2.46 0 00.9-.73zM1.46.6h6.4a.87.87 0 01.87.86v1.2a1.89 1.89 0 01-1.87 1.89H2.52A1.83 1.83 0 011.16 4a1.86 1.86 0 01-.55-1.3V1.46A.86.86 0 011.46.6zM.6 4.16a2.43 2.43 0 00.2.25 2.21 2.21 0 00.68.46 2.47 2.47 0 00-.88.72zm0 7a2.31 2.31 0 00.2.24 2.43 2.43 0 00.68.47 2.35 2.35 0 00-.88.71zm7.26 4.6h-6.4a.86.86 0 01-.86-.86v-.92a1.89 1.89 0 011.86-1.75h4.35a2 2 0 011.35.55 1.88 1.88 0 01.56 1.34v.69h0v.22a.87.87 0 01-.86.77zm20.33 22.87l-1 2.72a.29.29 0 000 .09v2.42h-6.9v-2.42a.28.28 0 000-.09l-1-2.72v-1.19h.79a.26.26 0 00.25-.26v-3.06a.25.25 0 000-.09l-.5-1.3v-.84h.26a.25.25 0 00.25-.25v-2.81a.5.5 0 000-.12l-.48-.93V26.4h.52a.25.25 0 00.25-.25v-7.79a6.79 6.79 0 00-6.78-6.78h-3.3a.22.22 0 00-.15.06l-.86.66h-.22v-8h.22l.76.64h0v5a.25.25 0 00.5 0V5.07h3.19a13.12 13.12 0 0113.1 13.1v7.73H23.6a.25.25 0 000 .5h4.14v1.38l-.48.93a.27.27 0 000 .12v2.56h-3.62a.25.25 0 000 .5h4.13v.84l-.49 1.3a.24.24 0 000 .09v2.83h-3.62a.25.25 0 00-.25.25.24.24 0 00.24.25h4.68z"
				fill={COLOR}
				stroke={COLOR}
				strokeWidth={0.35}
			/>
		</svg>
	)
}

export default IconFittingInner90Svg
