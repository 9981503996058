import axios from "../utils/axiosInterceptor";

export interface fittingDiametersRequestTypes {
    hoseSymbol: string;
    fittingType: string;
    fittingAngle: string;
}

export const fetchFittingDiameter = (hoseSymbol: string, fittingType: string, fittingAngle: string, categoryId: string | number) => {
    return axios.get(`configurator.php?hose=${hoseSymbol}&fitting_type=${fittingType}&fitting_angle=${fittingAngle}&category=${categoryId}`);
};
