import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import OpenPdfButton from "../../pdf/OpenPdfButton";
import { AppButtonText } from "../buttons/App_ButtonText";
import { HoseImage } from "../hoseImages/HoseImage";

export const ListItemOrderHoses = ({ data }: any) => {
    let location = useLocation();
    const hoseData = data?.hose_details?.[0];

    return (
        <Wrapper style={{ backgroundColor: "white" }}>
            <TopWrap>
                <Link
                    style={{ textDecoration: "none" }}
                    to={`/Modal/HoseDetails`}
                    state={{ backgroundLocation: location, hoseDetailsData: hoseData, showOrderbutton: true }}>
                    <AppButtonText title={"Pokaż szczegóły"} onClick={undefined} />
                </Link>
                <OpenPdfButton hoseData={hoseData} />
            </TopWrap>
            <ImageWrap>
                <HoseImage
                    leftFitting={{
                        angle: hoseData?.leftFittingDetails?.kat_zakucia,
                        type: hoseData?.leftFittingDetails?.typ_zakucia,
                    }}
                    rightFitting={{
                        angle: hoseData?.rightFittingDetails?.kat_zakucia,
                        type: hoseData?.rightFittingDetails?.typ_zakucia,
                    }}
                />
            </ImageWrap>
            <>
                <InfoElementWrap>
                    <InfoElementTitle>Przewód</InfoElementTitle>
                    <InfoElementValue>{hoseData?.hoseDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                <InfoElementWrap>
                    <InfoElementTitle>Zakucie lewe</InfoElementTitle>
                    <InfoElementValue>{hoseData?.leftFittingDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                <InfoElementWrap>
                    <InfoElementTitle>Zakucie prawe</InfoElementTitle>
                    <InfoElementValue>{hoseData?.rightFittingDetails?.nazwa}</InfoElementValue>
                </InfoElementWrap>
                {hoseData?.coverDetails?.data && (
                    <InfoElementWrap>
                        <InfoElementTitle>Osłona przewodu</InfoElementTitle>
                        <InfoElementValue>{hoseData?.coverDetails?.data?.nazwa}</InfoElementValue>
                    </InfoElementWrap>
                )}
                <InfoElementWrap>
                    <InfoElementTitle>Długość</InfoElementTitle>
                    <InfoElementValue>{hoseData?.length + " cm"}</InfoElementValue>
                </InfoElementWrap>
                <InfoElementWrap>
                    <InfoElementTitle>Ilość przewodów</InfoElementTitle>
                    <InfoElementValue>{data?.quantity}</InfoElementValue>
                </InfoElementWrap>
            </>
        </Wrapper>
    );
};

const InfoElementTitle = styled.p`
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.app_grey3};
    margin-bottom: 2px;
`;
const InfoElementValue = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const InfoElementWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 16px 8px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey5};
`;
const ImageWrap = styled.div`
    margin: 14px 16px;
    @media (min-width: 550px) {
        padding: 0 25px;
    }
`;
const TopWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`;
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    padding-top: 22px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
    border-bottom: 0;
`;
