export const updateHoseQuantityInStorage = (hoseId: string, quantity: number | string) => {
    const currentList = JSON.parse(localStorage.getItem('listOfIdsAndQuantity')) || [];

    const hoseIndex = currentList.findIndex((item: any) => item.id === hoseId);

    if (hoseIndex >= 0) {
        currentList[hoseIndex].quantity = quantity;
    } else {
        currentList.push({ id: hoseId, quantity: quantity });
    }
    localStorage.setItem('listOfIdsAndQuantity', JSON.stringify(currentList));
};

export const getQuantityForSingleHoseFromStorage = (singleHoseID: any) => {
    const listOfIdsAndQuantity = localStorage.getItem('listOfIdsAndQuantity');
    if (listOfIdsAndQuantity) {
        const listAsObject = JSON.parse(listOfIdsAndQuantity);
        const hose = listAsObject.find(hose => hose.id === singleHoseID);
        const quantity = hose ? hose.quantity : null;
        return quantity;
    }
    return null;
};
export const cleanUpHosesInLocalStorage = (listOfHosesInCart) => {
    const listOfIdsAndQuantity = JSON.parse(localStorage.getItem('listOfIdsAndQuantity')) || [];
    const cartHoseIds = listOfHosesInCart.map(hose => hose.ID);
    const updatedList = listOfIdsAndQuantity.filter(hose => cartHoseIds.includes(hose.id));
    localStorage.setItem('listOfIdsAndQuantity', JSON.stringify(updatedList));
};
