import { useState } from "react";
import Collapsible from "react-collapsible";
import { fittingTypes } from "../../../routes/FittingTypeRoute/helpers";
import ListItemFittingType from "./ListItem_FittingType";
import ListItemFittingTypeSelect from "./ListItem_FittingTypeSelect";

export const ListItemFittingTypeCollapsible = ({ item, selectTypeAndAngle }: any) => {
    const [collapseOpen, setCollapseOpen] = useState(false);

    const getFittingComponent = (type) => {
        const fitting = fittingTypes.find((f) => f.type === type);
        if (!fitting) {
            return null;
        }
        return <ListItemFittingType icon={fitting.icon} title={fitting.title} isOpen={collapseOpen} />;
    };

    return (
        <Collapsible
            transitionTime={300}
            onOpen={() => setCollapseOpen(true)}
            onClose={() => setCollapseOpen(false)}
            trigger={getFittingComponent(item?.type)}>
            {item?.angles?.map((angle: any, index: any) => (
                <ListItemFittingTypeSelect
                    angle={angle}
                    type={item?.type}
                    key={index}
                    selectTypeAndAngle={(type, angle) => selectTypeAndAngle(type, angle)}
                    isLast={index === item.angles.length - 1}
                />
            ))}
        </Collapsible>
    );
};
