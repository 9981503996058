export const useCartTotalPrice = (hoses: any) => {
    const countTotalPrice = hoses?.reduce((a: any, b: any) => {
        return a + (!b.activity ? 0 : b.totalPrice * b.quantity);
    }, 0);
    const shippingIncluded = countTotalPrice > 15000;
    const cartTotalPrice = countTotalPrice < 15000 ? countTotalPrice + 2000 : countTotalPrice;
    return {
        cartTotalPrice,
        shippingIncluded
    };
};
