import {SET_HOSE_SIDE, TOGGLE_HELP} from '../constants/actionNames';

interface Types {
	helpBox: boolean,
	hoseSide: 'LEFT' | 'RIGHT'
}

const INITIAL_STATE : Types = {
	helpBox: true,
	hoseSide: 'LEFT',
}
export const inAppStateReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case TOGGLE_HELP:
			return {...state, helpBox: action.payload};
		case SET_HOSE_SIDE:
			return {...state, hoseSide: action.payload};
		default:
			return state
	}
}

