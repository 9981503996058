import { useDispatch } from "react-redux";
import { removeHoseFromGuestCartAction } from "../../../actions/addHoseToCart.action";

export const useRemoveHosesFromLocalStore = () => {
    const dispatch = useDispatch();

    const hoseDataFromStorage = window.localStorage.getItem("hoses");
    const removeHosesFromLocalStore = (listOfHosesToDelete: any) => {
        let listOfHosesFromStorage = JSON.parse(hoseDataFromStorage || "[]");

        if (!Array.isArray(listOfHosesToDelete)) {
            console.error("listOfHosesToDelete must be an array");
            return;
        }

        listOfHosesToDelete.forEach((hoseToDelete: any) => {
            listOfHosesFromStorage = listOfHosesFromStorage.filter((hose: any) => hose.id !== hoseToDelete.id);
            dispatch(removeHoseFromGuestCartAction(hoseToDelete.id));
        });

        window.localStorage.setItem("hoses", JSON.stringify(listOfHosesFromStorage));
    };
    return {
        removeHosesFromLocalStore,
    };
};
