import styled from "styled-components";
import { theme } from "../../constants/Theme";
import { TitleHeader } from "../../styled/typography";
import { convertPriceToDisplayFormat } from "../../utils/price/convertPriceToDisplayFormat";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";

export const CartPriceResumeWrap = ({ price, description }: any) => {
    return (
        <ReusableInfoElementWithEmoji
            style={{
                marginTop: 10,
                marginBottom: 10,
                backgroundColor: theme.color.white,
            }}
            infos={[
                {
                    emoji: "",
                    description: (
                        <Wrapper>
                            <TitleHeader>{description}</TitleHeader>
                            <TitleHeader>{convertPriceToDisplayFormat(price)} zł</TitleHeader>
                        </Wrapper>
                    ),
                },
            ]}
        />
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
