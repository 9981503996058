import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const PrivacyPolicyRoute = () => {
    const terms = `Polityka prywatności
Polityka prywatności serwisu www.konfigurator.zakuwanie24.pl

1. Informacje ogólne
Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych
przekazanych przez Użytkowników w związku z korzystaniem przez nich usług
konfigurator.zakuwanie24.pl poprzez Serwis.
2. Administratorem danych osobowych zawartych w serwisie jest
Ewerest Mirosław Jachym z siedzibą w 39-300 Mielec, ulica Sienkiewicza 42/8
NIP 8171113100 REGON 830443781
3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne
procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom
nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z
odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o
świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i
aktach prawa wspólnotowego.
4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz
w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania
danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy
stronami umowy.
5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w
następujący sposób:
a) poprzez dobrowolnie wprowadzone w formularzach informacje
b) poprzez gromadzenie plików “cookies” [patrz polityka plików “cookies”].
6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.
7. dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego
formularza np. w celu dokonania procesu obsługi kontaktu informacyjnego
8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione
osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.
9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam
umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich
danych w dowolnym momencie.
10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które
może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie
ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich
zmianach będziemy informować w sposób widoczny i zrozumiały.
11. W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony
internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez
serwis [zakuwanie24.pl](http://zakuwanie24.pl/) . Strony te mogą posiadać własne polityki dotyczące prywatności oraz
regulaminy, z którymi zalecamy się zapoznać.
W razie wątpliwości do któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do
dyspozycji pod adresem email info@zakuwanie24.pl .`;
    return (
        <TermsContainer>
            <ReactMarkdown>{terms}</ReactMarkdown>
        </TermsContainer>
    );
};
const TermsContainer = styled.div`
    padding: 40px;
    overflow: auto; // dodane, aby umożliwić przewijanie, jeśli tekst jest zbyt duży

    @media (max-width: 768px) {
        // dla ekranów o szerokości 768px i mniejszych
        font-size: 14px;
        line-height: 1.5;
    }
`;
