import styled from "styled-components";
import IconDiameterInnerSvg from "../../icons/Icon_DiameterInnerSvg";
import SummaryHoseParameters from "./SummaryHoseParameters";
import SummaryRegularElement from "./SummaryRegularElement";

const SummaryHoseSection = ({ parameters, dimension, category }: any) => {
    return (
        <>
            {category && (
                <>
                    <SubHeader>1. Wąż</SubHeader>
                    {category && <SummaryRegularElement header="Typ węza:" title={category.category} />}
                    {dimension && (
                        <SummaryRegularElement
                            icon={<IconDiameterInnerSvg SIZE={20} COLOR={"#000"} />}
                            header={"Średnica wewnętrzna:"}
                            title={`DN ${dimension?.dn}  |  ${dimension?.cale}  |  ${dimension?.mm}mm`}
                        />
                    )}
                    {parameters && <SummaryHoseParameters title={`Przewód`} parameters={parameters} />}
                </>
            )}
        </>
    );
};

export default SummaryHoseSection;

const SubHeader = styled.h2`
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.black};
    padding: 12px 42px;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey};
`;
