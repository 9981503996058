import * as React from "react"

function IconPressureMaxSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 20.024 20.024"
		>
			<path
				data-name="Path 25"
				d="M10.578 0a10.028 10.028 0 019.451 9.451h-2.163a7.875 7.875 0 00-7.288-7.293zm-.561 3.422a6.59 6.59 0 11-6.59 6.59 6.59 6.59 0 016.59-6.59zm1.711 6.494a1.718 1.718 0 11-.491-1.119l2.4-1.053a.611.611 0 01.491 1.12zm-1.261-.114a.5.5 0 10-.255.653.491.491 0 00.249-.653zm-1.01-7.644a7.875 7.875 0 00-7.293 7.293H0A10.028 10.028 0 019.451 0v2.158zm10.567 8.416a10.028 10.028 0 01-9.446 9.45v-2.158a7.875 7.875 0 007.288-7.292zm-10.573 9.45A10.028 10.028 0 010 10.574h2.158a7.875 7.875 0 007.293 7.293z"
				fill={COLOR}
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default IconPressureMaxSvg
