import * as React from "react"

function IconDiameterInnerSvg({COLOR = '#000', SIZE = 40}) {
	return (
		<svg
			data-name="noun_inner diameter_2384094"
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 20.036 20.036"
		>
			<path
				data-name="Path 1"
				d="M3.62 14.622a7.839 7.839 0 0111-11l1.4-1.4a9.808 9.808 0 00-13.8 13.8z"
				fill={COLOR}
			/>
			<path
				data-name="Path 2"
				d="M16.416 5.414a7.839 7.839 0 01-11 11l-1.4 1.4a9.808 9.808 0 0013.8-13.8z"
				fill={COLOR}
			/>
			<path
				data-name="Path 3"
				d="M14.04 3.918l-1.041 3.123 3.121-1.043-.694-.694 3.47-3.47-.694-.694-3.47 3.47z"
				fill={COLOR}
			/>
			<path
				data-name="Path 4"
				d="M5.998 16.12l1.041-3.123-3.121 1.043.694.694-3.47 3.47.694.694 3.47-3.47z"
				fill={COLOR}
			/>
			<path
				data-name="Path 5"
				d="M12.392 6.898a3.919 3.919 0 00-5.494 5.494 3.953 3.953 0 00.746.746 3.919 3.919 0 005.494-5.493 3.987 3.987 0 00-.746-.747zm-2.373 5.083a1.963 1.963 0 111.963-1.963 1.965 1.965 0 01-1.964 1.963z"
				fill={COLOR}
			/>
		</svg>
	)
}

export default IconDiameterInnerSvg
