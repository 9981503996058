import { useState, useEffect } from "react";
const parseStock = (stockString) => {
    return parseFloat(stockString?.replace(",", "."));
};
export const useExceededItems = (updatedCartHoses) => {
    const [exceededItems, setExceededItems] = useState([]);

    useEffect(() => {
        let items = {};

        for (let hose of updatedCartHoses) {
            if (hose.activity) {
                if (!items[hose?.hoseDetails?.symbol]) {
                    items[hose.hoseDetails?.symbol] = {
                        ordered: 0,
                        stock: parseStock(hose.hoseDetails.stan_magazynowy) * 100,
                        nazwa: hose.hoseDetails.nazwa,
                        kat_zakucia: hose.hoseDetails.kat_zakucia,
                        typ_zakucia: hose.hoseDetails.typ_zakucia,
                    };
                }
                items[hose.hoseDetails.symbol].ordered += parseFloat(
                    (parseFloat(hose.length.replace(",", ".")) * hose.quantity).toFixed(2)
                );

                ["leftFittingDetails", "rightFittingDetails"].forEach((fittingType) => {
                    if (!items[hose[fittingType].symbol]) {
                        items[hose[fittingType].symbol] = {
                            ordered: 0,
                            stock: parseStock(hose[fittingType].stan_magazynowy),
                            nazwa: hose[fittingType].nazwa,
                            kat_zakucia: hose[fittingType].kat_zakucia,
                            typ_zakucia: hose[fittingType].typ_zakucia,
                        };
                    }
                    items[hose[fittingType].symbol].ordered += hose.quantity;
                });
            }
        }

        let exceededItemsTemp = [];
        for (let symbol in items) {
            if (items[symbol].ordered > items[symbol].stock) {
                exceededItemsTemp.push({ symbol, ...items[symbol] });
            }
        }

        setExceededItems(exceededItemsTemp);
    }, [updatedCartHoses]);

    return exceededItems;
};

