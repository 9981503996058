import { useState } from "react";
import { Checkbox } from "react-input-checkbox";
import styled from "styled-components";
import { theme } from "../../constants/Theme";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { PaymentDataTypes } from "../../types/paymentDataTypes";
import { preparePaymentData } from "../../utils/payment and order/preparePaymentData";
import { startApiPayProcess } from "../../utils/payment and order/startApiPayProcess";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import { Link } from "react-router-dom";

export const OrderPayConfirmModal = ({ visibility, setVisibility, order, ip }: any) => {
    const [regulamin, setRegulamin] = useState(false);
    const [regulamin2, setRegulamin2] = useState(false);
    const orderData = order;

    const paymentData: PaymentDataTypes = preparePaymentData({
        userIpAddress: ip ? ip : "1.1.12.1",
        updatedCartHoses: orderData?.hoses, //tu będą jaja czy rzeczy sa rezerwowane czy nie
        userData: { ...orderData?.userData?.clientData, mail: orderData?.userData?.clientData.email },
        cartTotalPrice: Number(orderData?.userData?.price),
        orderData: orderData,
        existingOrder: true,
    });

    const handleSubmit = async () => {
        try {
            const paymentUrl = await startApiPayProcess({
                orderId: orderData?.userData?.id,
                paymentData: paymentData,
            });
            window.location.href = paymentUrl;
        } catch (err) {
            console.error(`Wystąpił błąd: ${err}`);
            alert(err);
        }
    };
    return (
        <ModalOpacity onClick={() => setVisibility(false)} style={{ display: visibility ? "flex" : "none" }}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <CloseBtnWrap>
                    <CloseBtn onClick={() => setVisibility(false)}>
                        <IconCloseSvg />
                    </CloseBtn>
                </CloseBtnWrap>
                <Header>Potwierdzenie płatności</Header>
                <SubHeader style={{ marginTop: 20 }}>
                    Za chwilę nastąpi przekierowanie do systemu płatności PayU, gdzie będziesz mógł sfinalizować
                    transakcję.
                </SubHeader>
                <SubHeader style={{ fontWeight: 40, fontSize: 12 }}>
                    Pamiętaj, jeżeli z jakiegoś powodu proces płatności zostanie przerwany, Twoje zamówienie będzie
                    nadal dostępne i czekać na finalizację płatności w sekcji "Moje Zamówienia".
                </SubHeader>
                <SubHeader style={{ marginTop: 30 }}>Czy jesteś gotów kontynuować?</SubHeader>{" "}
                <CheckboxWrapper onClick={() => setRegulamin(!regulamin)} style={{ marginTop: 30, marginBottom: 10 }}>
                    <Checkbox
                        modification="cartItemDisable"
                        theme="fancy-checkbox"
                        disabled={false}
                        value={regulamin}
                        onChange={() => setRegulamin(!regulamin)}>
                        <p></p>
                    </Checkbox>
                    <CheckboxText>
                        Akceptuję <Link to="/Regulamin">Regulamin</Link> sprzedaży
                    </CheckboxText>
                </CheckboxWrapper>
                <CheckboxWrapper onClick={() => setRegulamin2(!regulamin2)}>
                    <Checkbox
                        modification="cartItemDisable"
                        theme="fancy-checkbox"
                        disabled={false}
                        value={regulamin2}
                        onChange={() => setRegulamin2(!regulamin2)}>
                        <p></p>
                    </Checkbox>
                    <CheckboxText>
                        Akceptuję <Link to="/PrivacyPolicy">Politykę Prywatności</Link>
                    </CheckboxText>
                </CheckboxWrapper>
                <BtnWrap>
                    <AppButtonRegular disable={!regulamin && !regulamin2} onClick={handleSubmit} title="Zapłać" />
                    <div style={{ height: 10 }} />
                    <AppButtonRegular
                        onClick={() => setVisibility(false)}
                        borderColor={theme.color.app_blue2}
                        bgColor={theme.color.app_lightBlue}
                        color={theme.color.app_blue2}
                        title="Anuluj"
                    />
                </BtnWrap>
            </Wrapper>
        </ModalOpacity>
    );
};
const CheckboxText = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.color.black};
    text-align: center;
    margin-left: 10px;
`;
const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;
const CloseBtnWrap = styled.div`
    height: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
const CloseBtn = styled.div`
    cursor: pointer;
`;
const BtnWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30;
    padding: 26px 0;
`;
const Header = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin-top: 20px;
`;
const SubHeader = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.black};
    margin: 5px 0;
`;
const Wrapper = styled.div`
    height: 100%;
    width: 100vw;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 36px;
    @media (min-width: 1000px) {
        margin-top: 65px;
        height: auto;
        width: 500px;
    }
`;
const ModalOpacity = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000030;
    backdrop-filter: blur(1.2px);
    align-items: flex-start;
    justify-content: center;
    overflow: scroll;
    z-index: 2;
`;
