import { L_GW_0, L_GW_90, L_GW_45, L_GZ_0, L_O_0, L_FL_0, L_FL_90, L_FL_45, L_NT_0 } from "../../../assets/hoseConfig";

export const hoseConfigImage = (fitting: any) => {
    let source;
    switch (fitting?.type) {
        case "wewnetrzne":
            source = fitting.angle === "proste" ? L_GW_0 :
                fitting.angle === "90 stopni" ? L_GW_90 :
                    L_GW_45;
            break;
        case "zewnetrzne":
            source = L_GZ_0;
            break;
        case "stozkowe":
            source = L_GZ_0; // nie ma stozkowych
            break;
        case "oczko":
            source = L_O_0;
            break;
        case "flansza":
            source = fitting.angle === "proste" ? L_FL_0 :
                fitting.angle === "90 stopni" ? L_FL_90 :
                    L_FL_45;
            break;
        case "nietypowe":
            source = L_NT_0;
            break;
    }
    return source;
};
