import {
    FETCH_FITTINGS,
    FETCH_FITTING_ANGLES,
    FETCH_FITTING_DIAMETERS,
    FETCH_FITTING_TYPES,
    FETCH_FITTING_TYPES_AND_ANGLES,
} from "../constants/actionNames";

const INITIAL_STATE = {
    fetched: false,
    loading: false,
    error: false,
    fittingDiameters: null,
    types: [],
    typesAndAngles: [],
    angles: null,
    fittings: [],
};
export const fittingsReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case FETCH_FITTING_DIAMETERS + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_FITTING_DIAMETERS + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_FITTING_DIAMETERS + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                fittingDiameters: fittingDiametersFilter(action.payload.data),
            };
        case FETCH_FITTING_TYPES + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_FITTING_TYPES + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_FITTING_TYPES + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                types: action.payload.data,
            };
        case FETCH_FITTING_TYPES_AND_ANGLES + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_FITTING_TYPES_AND_ANGLES + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_FITTING_TYPES_AND_ANGLES + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                typesAndAngles: action.payload.data,
            };
        case FETCH_FITTING_ANGLES + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_FITTING_ANGLES + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_FITTING_ANGLES + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                angles: action.payload.data,
            };

        case FETCH_FITTINGS + "_PENDING":
            return { ...state, loading: true, error: false, fetched: false };
        case FETCH_FITTINGS + "_REJECTED":
            return { ...state, loading: false, error: true, fetched: false };
        case FETCH_FITTINGS + "_FULFILLED":
            return {
                ...state,
                loading: false,
                error: false,
                fetched: true,
                fittings: fittingsToObjects(action.payload.data),
            };
        default:
            return state;
    }
};

const fittingsToObjects = (data: any) => {
    return data;
};

const fittingDiametersFilter = (data: Array<string>) => {
    const parsedData = Object.entries(data).map((entry) => {
        return { diameter: entry[0], fittings: entry[1] };
    });
    const updatedDiameters = parsedData.map((item: any) => ({
        ...item,
        full_diameter_name: item.diameter,
        inner_diameter_catalogue: item.diameter.split("_")[0].toLowerCase(),
        inner_diameter_mm: item.diameter.split("_")[1],
    }));
    return updatedDiameters.length
        ? updatedDiameters.sort(
            (a, b) =>
                parseFloat(a.inner_diameter_mm.replace(/,/g, ".")) -
                parseFloat(b.inner_diameter_mm.replace(/,/g, "."))
        )
        : [];
};
