import { addOrderAction } from "../../actions/orders.action";
import { getXAndContinueCreatingOrder } from "../../services/order.service";

export const createInternalOrder = async (
    dispatch: any,
    hosesIds: any,
    OrderData: any,
    setError: any,
    isLogged: any,
) => {
    setError("");
    if (!hosesIds) {
        return setError("error");
    }
    if (isLogged) {
        return createOrderAsLoggedUser({ setError, dispatch, OrderData });
    } else {
        return createOrderAsGuest({ setError, OrderData });
    }
};

const createOrderAsLoggedUser = async ({ setError, dispatch, OrderData }) => {
    try {
        const response = await dispatch(addOrderAction(OrderData));

        if (
            response.action.payload &&
            Array.isArray(response.action.payload.data) &&
            response.action.payload.data[0].id
        ) {
            return response.action.payload.data[0].id;
        } else {
            const errorMessage = "Nie udało się utworzyć zamówienia. Spróbuj ponownie później.";
            setError(errorMessage);
            throw new Error(errorMessage);
        }
    } catch (err) {
        const errorMessage = "Wystąpił błąd. Spróbuj ponownie";
        setError(errorMessage);
        throw new Error(errorMessage);
    }
}
const createOrderAsGuest = async ({ setError, OrderData }) => {
    try {
        const data = await getXAndContinueCreatingOrder(OrderData);
        if (data && Array.isArray(data) && data[0].id) {
            return data[0].id;
        } else {
            const errorMessage = "Nie udało się utworzyć zamówienia. Spróbuj ponownie później.";
            setError(errorMessage);
            throw new Error(errorMessage);
        }
    } catch (err) {
        const errorMessage = "Wystąpił błąd. Spróbuj ponownie2";
        setError(errorMessage);
        throw new Error(errorMessage);
    }
}
