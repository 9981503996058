import { theme } from "../../../constants/Theme";
import { Header } from "../../../styled/typography";
import { AppButtonRegular } from "../../buttons/App_ButtonRegular";
import { CartOrderExceededItems } from "../CartOrderExceededItems";

type Types = {
    setVisibility: (arg: boolean) => void;
    exceededItems: any;
    setItemsAreExceeded: (arg: boolean) => void;
};
export const ExceededItemsInfo = ({ setVisibility, exceededItems, setItemsAreExceeded }: Types) => {
    return (
        <>
            <Header>Przekroczono dostępne stany magazynowe</Header>
            <CartOrderExceededItems exceededItems={exceededItems} />
            <AppButtonRegular onClick={() => setItemsAreExceeded(false)} title="Kontynuuj" />
            <AppButtonRegular
                onClick={() => setVisibility(false)}
                borderColor={theme.color.app_blue2}
                bgColor={theme.color.app_lightBlue}
                color={theme.color.app_blue2}
                style={{ marginTop: 10 }}
                title="Wstecz"
            />
        </>
    );
};
