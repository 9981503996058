import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import { registerAccountAction } from "../../actions/account.action";
import { AppButtonRegular } from "../../components/buttons/App_ButtonRegular";
import InputRegular from "../../components/inputs/InputRegular";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { accountRegisterProps } from "../../services/account.service";
import { theme } from "../../constants/Theme";

export const RegisterModalRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState<string>("");

    const checkIfRegisterSuccessfull = (r: any) => {
        if (r.length > 6) {
            setError(r);
        } else {
            alert("Twoje konto zostało zarejestrowane - przejdź do logowania");
            navigate(-1);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            phone: "",
            password: "",
            passwordConfirmation: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Niepoprawny email").required("Wprowadź email"),
            password: Yup.string().required("Wprowadź hasło").min(6, "Hasło musi mieć co najmniej 6 znaków"),
            passwordConfirmation: Yup.string()
                .required("Wpisz hasło ponownie")
                .oneOf([Yup.ref("password")], "Hasła muszą być takie same"),
            phone: Yup.string()
                .matches(/^[0-9]{9}$/, "Numer telefonu musi składać się z 9 cyfr")
                .nullable(),
        }),
        validateOnBlur: false,
        validateOnChange: true,
        isInitialValid: false,
        validateOnMount: true,
        onSubmit: () => {
            register();
        },
    });

    const registerUserData: accountRegisterProps = {
        email: formik.values.email,
        name: "",
        phone: formik.values.phone,
        password: formik.values.password,
    };
    const register = () => {
        //@ts-ignore
        dispatch(registerAccountAction(registerUserData)).then((r) => checkIfRegisterSuccessfull(r.value.data));
    };

    const goBack = () => {
        navigate(-1);
    };

    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                <Title>Utwórz konto</Title>
                {error.length > 1 ? <LogInError>{error}</LogInError> : <LogInError />}
                <InputRegular
                    onTextChange={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    value={formik.values.email}
                    placeholder="np. nowak@mail.com"
                    title="Email"
                />
                {formik.touched.email && formik.errors.email ? <LogInError>{formik.errors.email}</LogInError> : null}
                <div style={{ height: 10 }} />

                <InputRegular
                    onTextChange={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    value={formik.values.password}
                    type="password"
                    placeholder="Hasło"
                    title="Hasło"
                />
                {formik.touched.password && formik.errors.password ? (
                    <LogInError>{formik.errors.password}</LogInError>
                ) : null}
                <div style={{ height: 10 }} />

                <InputRegular
                    type="password"
                    onTextChange={formik.handleChange("passwordConfirmation")}
                    onBlur={formik.handleBlur("passwordConfirmation")}
                    value={formik.values.passwordConfirmation}
                    placeholder="Powtórz hasło"
                />
                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                    <LogInError>{formik.errors.passwordConfirmation}</LogInError>
                ) : null}
                <div style={{ height: 10 }} />
                <InputRegular
                    onTextChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                    value={formik.values.phone}
                    placeholder="np. 000 000 000"
                    title="Numer Telefonu"
                    maxLength={9}
                    type="number"
                    onInput={(e: any) => (e.target.value = e.target.value.slice(0, 9))}
                />
                {formik.touched.phone && formik.errors.phone ? <LogInError>{formik.errors.phone}</LogInError> : null}
                <div style={{ height: 20 }} />
                <Consent>
                    Klikając "Załóż konto" akceptujesz{" "}
                    <Link style={{ color: theme.color.app_blue }} to="/Regulamin">
                        Regulamin
                    </Link>
                </Consent>
                <AppButtonRegular title="Załóż konto" onClick={formik.handleSubmit} disable={!formik.isValid} />
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};

const Consent = styled.p`
    font-size: 12px;
    color: ${({ theme }) => theme.color.black};
    font-weight: 500;
    margin-bottom: 5px;
`;
const LogInError = styled.p`
    font-size: 12px;
    color: red;
    margin: 11px 5px;
    text-align: center;
`;
const Title = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 20px;
    font-weight: 700;
`;

const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    @media (min-width: 1000px) {
        height: auto;
        min-width: 580px;
        width: auto;
        padding: 30px;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
`;
