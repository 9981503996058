import {SET_HOSE_SIDE, TOGGLE_HELP} from '../constants/actionNames'

export const helpAlertToggleAction = (state: boolean) => ({
	type: TOGGLE_HELP,
	payload: state
})

export const setHoseSideAction = (state: 'LEFT' | 'RIGHT') => ({
	type: SET_HOSE_SIDE,
	payload: state
})
