import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { setLeftFittingParamsAction, setRightFittingParamsAction } from "../actions/currentHose.action";
import { fetchFittingDiametersAction } from "../actions/fittingDiameters.action";
import { setHoseSideAction } from "../actions/inAppState.action";
import { removeProgressAction } from "../actions/progress.action";
import { ListItemFittingCollapsible } from "../components/listElements/ListItem_FittingCollapsible";
import FittingDiameterListHeader from "../components/listHeaders/FittingDiameterListHeader";
import ListWrapper from "../components/listWrappers/ListWrapper";
import FittingSelectModal from "../components/modals/FittingSelect_Modal";
import { RootState } from "../reducers";
import { RouteWrapper } from "../styled/wrappers";
import { ListWrapperSubtitle } from "../components/listWrappers/ListWrapperSubtitle";

const FittingDiameterRoute = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState<any>({ state: false, symbol: 0 });

    const side = useSelector((state: any) => state.inAppStateReducer.hoseSide);
    const rightFittingAngle = useSelector((state: RootState) => state.currentHose.rightFitting.angle);
    const { parameters, category } = useSelector((state: RootState) => state.currentHose.hose);
    const { rightFitting, leftFitting } = useSelector((state: RootState) => state.currentHose);
    const { fittingDiameters, loading } = useSelector((state: any) => state.fittings);
    const fittings = fittingDiameters?.map((a: any) => a.fittings).flat(1);

    useEffect(() => {
        if (side === "LEFT") {
            dispatch(setLeftFittingParamsAction(null));
            dispatch(fetchFittingDiametersAction(parameters.symbol, leftFitting.type, leftFitting.angle, category.id));
            dispatch(removeProgressAction("left", "right"));
        } else if (side === "RIGHT") {
            if (!rightFittingAngle) {
                dispatch(setHoseSideAction("LEFT"));
                dispatch(setLeftFittingParamsAction(null));
                dispatch(removeProgressAction("left", "right"));
            } else {
                dispatch(setRightFittingParamsAction(null));
                dispatch(removeProgressAction("right", "additions"));
                dispatch(
                    fetchFittingDiametersAction(parameters.symbol, rightFitting.type, rightFitting.angle, category.id)
                );
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const selectFitting = (params: any) => {
        if (side === "LEFT") {
            dispatch(setLeftFittingParamsAction(params));
            dispatch(setHoseSideAction("RIGHT"));
            navigate("/create/fittingType");
        } else if (side === "RIGHT") {
            dispatch(setRightFittingParamsAction(params));
            navigate("/create/additionalData");
        }
    };
    return (
        <RouteWrapper>
            <ListWrapper
                title={"Wybierz średnicę gwintu"}
                subtitle={
                    <ListWrapperSubtitle
                        description="Zmierz suwmiarką lub znajdź w katalogu. To chyba najtrudniejszy moment! Dokładnie przeanalizuj rysunki i wymiary."
                        btnText="Pomoc - Jak dobrać odpowiedni gwint?"
                        url={"https://zakuwanie24.pl/jak-zidentyfikowac-gwint/"}
                    />
                }
                listHeader={<FittingDiameterListHeader />}
                list={
                    <>
                        <MoonLoader color={"white"} loading={loading} size={50} speedMultiplier={0.4} />
                        {!loading && (
                            <>
                                {fittingDiameters?.map((item: any, index: number) => (
                                    <ListItemFittingCollapsible
                                        item={item}
                                        key={index + item?.diameter}
                                        index={index + item?.diameter}
                                        setOpen={({ state, symbol }: any) =>
                                            setOpen({
                                                state: state,
                                                symbol: symbol,
                                            })
                                        }
                                        selectFitting={(data: any) => selectFitting(data)}
                                    />
                                ))}
                            </>
                        )}
                    </>
                }
            />
            {fittingDiameters && (
                <FittingSelectModal
                    data={fittings.find((item: any) => item.symbol === open.symbol)}
                    isOpen={open.state}
                    setOpen={() => setOpen({ state: false, symbol: open.symbol })}
                    selectFitting={(data: any) => selectFitting(data)}
                />
            )}
        </RouteWrapper>
    );
};

export default FittingDiameterRoute;
