import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import { setCurrentHoseLengthAction } from "../../actions/currentHose.action";
import { RootState } from "../../reducers";
import { HoseImage } from "../hoseImages/HoseImage";
import InputRegular from "../inputs/InputRegular";
import { ListWrapperSubtitle } from "../listWrappers/ListWrapperSubtitle";

const HoseLength = () => {
    const dispatch = useDispatch();
    const screenSize = useScreenSize();
    const { leftFitting, rightFitting } = useSelector((state: RootState) => state.currentHose);
    const hoseLength = useSelector((state: RootState) => state.currentHose.hose.length);

    return (
        <Wrapper>
            <Header>Długość</Header>
            <ListWrapperSubtitle
                description="Podaj długość przewodu w centymetrach, od zakucia do zakucia - według podniższego schematu"
                btnText="Pomoc - Jak poprawnie zmierzyc długość przewodu?"
                url={"https://zakuwanie24.pl/jak-poprawnie-zmierzyc-dlugosc-przewodu/"}
            />
            <div style={{ height: 15 }} />
            <HoseImage
                leftFitting={leftFitting}
                rightFitting={rightFitting}
                styleWrapper={{ height: screenSize.width > 600 ? 120 : 100 }}
            />
            <InputRegular
                onTextChange={(e: any) => dispatch(setCurrentHoseLengthAction(e.target.value))}
                value={hoseLength}
                placeholder="Wpisz długość przewodu"
                type={"number"}
                unit="cm"
                wrapperStyles={{ width: 325, alignSelf: "flex-start" }}
                onKeyPress={(event) => {
                    const charCode = event.charCode;
                    const decimalPointCharCode = 46;
                    const commaCharCode = 44;

                    if (charCode === decimalPointCharCode || charCode === commaCharCode) {
                        event.preventDefault();
                    }
                }}
            />
        </Wrapper>
    );
};

export default HoseLength;

const Header = styled.h2`
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 28px;
`;
