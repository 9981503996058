import { theme } from "../../constants/Theme";
import IconFittingConicalSvg from "../../icons/Icon_FittingConicalSvg";
import IconFittingEyeSvg from "../../icons/Icon_FittingEyeSvg";
import IconFittingFlancheSvg from "../../icons/Icon_FittingFlancheSvg";
import IconFittingInnerSvg from "../../icons/Icon_FittingInnerSvg";
import IconFittingOuterSvg from "../../icons/Icon_FittingOuterSvg";
import IconFittingUnusualSvg from "../../icons/Icon_FittingUnusualSvg";
import IconHoseHydraulicSvg from "../../icons/Icon_HoseHydraulicSvg";

export const renderFittingIcon = (type) => {
    switch (type) {
        case "wewnetrzne":
            return <IconFittingInnerSvg COLOR={theme.color.app_blue} SIZE={50} />;
        case "zewnetrzne":
            return <IconFittingOuterSvg COLOR={theme.color.app_blue} SIZE={50} />;
        case "stozkowe":
            return <IconFittingConicalSvg COLOR={theme.color.app_blue} SIZE={50} />;
        case "flansza":
            return <IconFittingFlancheSvg COLOR={theme.color.app_blue} SIZE={50} />;
        case "oczko":
            return <IconFittingEyeSvg COLOR={theme.color.app_blue} SIZE={50} />;
        case "nietypowe":
            return <IconFittingUnusualSvg COLOR={theme.color.app_blue} SIZE={50} />;
        default:
            return (
                <div style={{ transform: "rotate(90deg)" }}>
                    <IconHoseHydraulicSvg COLOR={theme.color.app_blue} SIZE={50} />
                </div>
            );
    }
};
