import React from "react";
import styled from "styled-components";
import LwymiarFlansza_45 from "../../assets/hoseLength/L wymiar_FLANSZA_45-1.png";
import LwymiarFlansza_90 from "../../assets/hoseLength/L wymiar_FLANSZA_90-1.png";
import LwymiarFlansza_prosta from "../../assets/hoseLength/L wymiar_FLANSZA_prosta-1.png";
import LwymiarGW_45 from "../../assets/hoseLength/L wymiar_GW_45-1.png";
import LwymiarGW_90 from "../../assets/hoseLength/L wymiar_GW_90-1.png";
import LwymiarGW_prosta from "../../assets/hoseLength/L wymiar_GW_prosta-1.png";
import LwymiarGZ_prosta from "../../assets/hoseLength/L wymiar_GZ_prosta-1.png";
import LwymiarOczko from "../../assets/hoseLength/L wymiar_OCZKO-1.png";
import PwymiarFlansza_45 from "../../assets/hoseLength/P wymiar_FLANSZA_45-1.png";
import PwymiarFlansza_90 from "../../assets/hoseLength/P wymiar_FLANSZA_90-1.png";
import PwymiarFlansza_prosta from "../../assets/hoseLength/P wymiar_FLANSZA_prosta-1.png";
import PwymiarGW_45 from "../../assets/hoseLength/P wymiar_GW_45-1.png";
import PwymiarGW_90 from "../../assets/hoseLength/P wymiar_GW_90-1.png";
import PwymiarGW_prosta from "../../assets/hoseLength/P wymiar_GW_prosta-1.png";
import PwymiarGZ_prosta from "../../assets/hoseLength/P wymiar_GZ_prosta-1.png";
import PwymiarOczko from "../../assets/hoseLength/P wymiar_OCZKO-1.png";
import PwymiarNietypowe from "../../assets/hoseLength/P wymiar_NIETYPOWE-1.png";
import LwymiarNietypowe from "../../assets/hoseLength/L wymiar_NIETYPOWE-1.png";

export const HoseImage = ({ leftFitting, rightFitting, styleWrapper }: any) => {
    const setLeftImage = () => {
        switch (leftFitting?.type) {
            case "wewnetrzne":
                if (leftFitting.angle === "proste") {
                    return <Image src={LwymiarGW_prosta} alt="lengthExample" />;
                } else if (leftFitting.angle === "90 stopni") {
                    return <Image src={LwymiarGW_90} alt="lengthExample" />;
                } else {
                    return <Image src={LwymiarGW_45} alt="lengthExample" />;
                }
            case "zewnetrzne":
                return <Image src={LwymiarGZ_prosta} alt="lengthExample" />;
            case "stozkowe":
                return <Image src={LwymiarGZ_prosta} alt="lengthExample" />;
            case "oczko":
                return <Image src={LwymiarOczko} alt="lengthExample" />;
            case "flansza":
                if (leftFitting.angle === "proste") {
                    return <Image src={PwymiarFlansza_prosta} alt="left_flansza_prosta" />;
                } else if (leftFitting.angle === "90 stopni") {
                    return <Image src={LwymiarFlansza_90} alt="lengthExample" />;
                } else {
                    return <Image src={LwymiarFlansza_45} alt="lengthExample" />;
                }
            case "nietypowe":
                return <Image src={LwymiarNietypowe} alt="left_nietypowe" />;
        }
    };
    const setRightImage = () => {
        switch (rightFitting?.type) {
            case "wewnetrzne":
                if (rightFitting.angle === "proste") {
                    return <Image src={PwymiarGW_prosta} alt="lengthExample" />;
                } else if (rightFitting.angle === "90 stopni") {
                    return <Image src={PwymiarGW_90} alt="lengthExample" />;
                } else {
                    return <Image src={PwymiarGW_45} alt="lengthExample" />;
                }
            case "zewnetrzne":
                return <Image src={PwymiarGZ_prosta} alt="lengthExample" />;
            case "stozkowe":
                return <Image src={PwymiarGZ_prosta} alt="lengthExample" />;
            case "oczko":
                return <Image src={PwymiarOczko} alt="lengthExample" />;
            case "flansza":
                if (rightFitting.angle === "proste") {
                    return <Image src={LwymiarFlansza_prosta} alt="right_flansza_prosta" />;
                } else if (rightFitting.angle === "90 stopni") {
                    return <Image src={PwymiarFlansza_90} alt="lengthExample" />;
                } else {
                    return <Image src={PwymiarFlansza_45} alt="lengthExample" />;
                }
            case "nietypowe":
                return <Image src={PwymiarNietypowe} alt="right_nietypowe" />;
        }
    };
    return (
        <ImageWrap style={styleWrapper}>
            {setLeftImage()}
            {setRightImage()}
        </ImageWrap>
    );
};

const ImageWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.app_grey5};
    height: 124px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 8px;
    @media (min-width: 1279px) {
        height: 210px;
        margin-bottom: 20px;
    }
`;
const Image = styled.img`
    object-fit: contain;
    height: 124px;
    width: 45%;
    cursor: pointer;
    @media (max-width: 1279px) {
        width: 38%;
    }
    @media (max-width: 400px) {
        width: 45%;
    }
`;
