import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "../reducers/index";
import promise from "redux-promise-middleware";
import { logger } from "redux-logger/src";

const middleware = [promise];

if (process.env.NODE_ENV !== "production") {
    middleware.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

export default store;
