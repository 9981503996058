import { useSelector } from "react-redux";
import styled from "styled-components";
import imgFLANSZA from "../../assets/pomiar/FLANSZA-1.png";
import imgGW from "../../assets/pomiar/GW-1.png";
import imgGZ from "../../assets/pomiar/GZ-1.png";
import imgNIETYPOWA from "../../assets/pomiar/NIETYPOWA-1.png";
import imgOCZKO from "../../assets/pomiar/OCZKO-1.png";
import { RootState } from "../../reducers";
import { ListHeaderTableDescription } from "./ListHeaderTableDescription";

const FittingDiameterListHeader = () => {
    const { active } = useSelector((state: RootState) => state.progress);
    const { leftFitting, rightFitting } = useSelector((state: RootState) => state.currentHose);

    const selectImage = () => {
        const switchParam = active === "left" ? leftFitting.type : rightFitting.type;
        switch (switchParam) {
            case "wewnetrzne":
                return <Image src={imgGW} alt="image" />;
            case "zewnetrzne":
                return <Image src={imgGZ} alt="image" />;
            case "oczko":
                return <Image src={imgOCZKO} alt="image" />;
            case "flansza":
                return <Image src={imgFLANSZA} alt="image" />;
            case "stozkowe":
                return <Image src={imgGZ} alt="image" />;
            case "nietypowe":
                return <Image src={imgNIETYPOWA} alt="image_nietypowe" />;
            default:
                return "wewnetrzne";
        }
    };

    return (
        <Container>
            {selectImage()}
            <ListHeaderTableDescription
                items={[
                    { text: "Typ gwintu", width: "100%" },
                    { text: "Pomiar suwmiarką", width: "100%" },
                ]}
            />
        </Container>
    );
};

export default FittingDiameterListHeader;

const Image = styled.img`
    object-fit: contain;
    width: 100%;
    min-height: 140px;
    box-sizing: border-box;
`;
const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
