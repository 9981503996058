import { theme } from "../../constants/Theme";
import IconFittingConicalSvg from "../../icons/Icon_FittingConicalSvg";
import IconFittingEyeSvg from "../../icons/Icon_FittingEyeSvg";
import IconFittingInner45Svg from "../../icons/Icon_FittingInner45Svg";
import IconFittingInner90Svg from "../../icons/Icon_FittingInner90Svg";
import IconFittingInnerSvg from "../../icons/Icon_FittingInnerSvg";
import IconFittingOuterSvg from "../../icons/Icon_FittingOuterSvg";
import IconFittingUnusualSvg from "../../icons/Icon_FittingUnusualSvg";
import IconFittingFlancheSvg from "../../icons/Icon_FittingFlancheSvg";
import IconFittingFlanche45Svg from "../../icons/Icon_FittingFlanche45Svg";
import IconFittingFlanche90Svg from "../../icons/Icon_FittingFlanche90Svg";

export function getFittingIcon(angle, fittingType) {
    const commonProps = { SIZE: 50, COLOR: theme.color.app_blue };
    const prosteProps = { SIZE: 50, COLOR: theme.color.app_blue };
    switch (angle) {
        case "proste":
            return fittingType === "wewnetrzne" ? (
                <IconFittingInnerSvg { ...prosteProps } />
            ) : fittingType === "zewnetrzne" ? (
                <IconFittingOuterSvg { ...prosteProps } />
            ) : fittingType === "oczko" ? (
                <IconFittingEyeSvg { ...prosteProps } />
            ) : fittingType === "flansza" ? (
                <IconFittingFlancheSvg { ...prosteProps } />
            ) : fittingType === "nietypowe" ? (
                <IconFittingUnusualSvg { ...prosteProps } />
            ) : fittingType === "stozkowe" ? (
                <IconFittingConicalSvg  {...prosteProps}  />
            ) : (
                <IconFittingInnerSvg { ...prosteProps } />
            );
        case "45 stopni":
            return fittingType === "wewnetrzne" ? (
                <IconFittingInner45Svg { ...commonProps } />
            ) : fittingType === "flansza" ? (
                <IconFittingFlanche45Svg { ...commonProps } />
            ) : (
                <IconFittingInner45Svg { ...commonProps } />
            );
        case "90 stopni":
            return fittingType === "wewnetrzne" ? (
                <IconFittingInner90Svg { ...commonProps } />
            ) : fittingType === "flansza" ? (
                <IconFittingFlanche90Svg { ...commonProps } />
            ) : (
                <IconFittingInner90Svg { ...commonProps } />
            );
        default:
            return null;
    }
}
