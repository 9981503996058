import { Helmet } from "react-helmet";

export const AppHelmet = () => {
    return (
        <Helmet>
            <title>Konfigurator Przewodów Hydraulicznych - Węże z QR Kodem | zakuwanie24.pl</title>
            <link rel="icon" href="/favicon.ico" />
            <link rel="icon" type="image/png" sizes="192x192" href="/logo192.png" />
            <meta
                name="description"
                content="Zakuwanie24.pl - konfigurator przewodów hydraulicznych z QR kodem. Konfiguruj węże online. Zeskanuj kod QR naszą apką i zamów taki sam!"
            />
            <meta
                name="keywords"
                content="przewody hydrauliczne, konfigurator przewodów, zakuwanie24.pl, zamów przewody online, węże hydrauliczna, kody qr, węże z kodami qr, zakuwanie 24"
            />
            <meta name="robots" content="index,follow" />
            <meta property="og:url" content="https://konfigurator.zakuwanie24.pl/" />
            <meta property="og:locale" content="pl_PL" />
            <meta property="og:title" content="zakuwanie24.pl konfigurator węży hydraulicznych z qr kodem" />
            <meta
                property="og:description"
                content="Konfiguruj i zamawiaj przewody hydrauliczne z kodem QR. Prosty konfigurator przewodów hydraulicznych. Węże hydrauliczne z QR kodem. Zakuwanie24.pl"
            />
            <meta
                property="og:image"
                content="https://zakuwanie24.pl/wp-content/uploads/2023/06/Open-graph-image.png"
            />
            <link rel="canonical" href="https://konfigurator.zakuwanie24.pl/" />
        </Helmet>
    );
};
