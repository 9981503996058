import styled from "styled-components";

export const RouteWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    min-height: 100vh;
    z-index: 1;
    box-shadow: 0px 0px 30px -25px #464646;
`;
