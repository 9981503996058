import styled from "styled-components";
import { L_PLACEHOLDER } from "../../../../assets/hoseConfig";
import { hoseConfigImage } from "../HoseConfigImage";

export const RightFittingWrapper = ({ active, selected, fitting }: any) => {
    const source = hoseConfigImage(fitting);

    return (
        <FittingWrapper>
            <RightImage
                src={fitting?.parameters ? source?.default : L_PLACEHOLDER.default}
                alt="Right"
                style={{ opacity: selected ? 1 : 0.4 }}
            />
            {active && <LeftSquare />}
            {active && <InfoString>Wybierasz prawe zakucie</InfoString>}
        </FittingWrapper>
    );
};

const FittingWrapper = styled.div`
    border-radius: 4px;
    position: relative;
`;
const RightImage = styled.img`
    transform: scaleX(-1);
    width: 80px;
    object-fit: contain;
    opacity: 0.4;
`;
const InfoString = styled.p`
    position: absolute;
    top: -28px;
    font-size: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.app_blue2};
    width: 110%;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: 768px) {
        top: auto;
        bottom: -16px;
    }
`;
const LeftSquare = styled.div`
    position: absolute;
    height: 110px;
    width: 110px;
    border-radius: 16px;
    z-index: 1;
    border: 1px solid ${({ theme }) => theme.color.app_blue2};
    right: -15px;
    bottom: 10px;
`;
