import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
    getUserDataAction,
    setTempAddress,
    setTempInvoiceData,
    userDataChangeAction,
} from "../../actions/account.action";
import { AppButtonRegular } from "../../components/buttons/App_ButtonRegular";
import InputRegular from "../../components/inputs/InputRegular";
import { useAppDispatch } from "../../hooks/useAppDispatch/useAppDispatch";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { RootState } from "../../reducers";

export const ProfileModalRoute = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state: RootState) => state.account.user.userData);
    const { tempAddress, invoiceData } = useSelector((state: RootState) => state.account);
    const { individualData, mail, phone } = userData;
    const { companyData } = useSelector((state: RootState) => state.account.user.userData);
    const [phoneNumber, setPhoneNumber] = useState(phone);
    useEffect(() => {
        dispatch(setTempInvoiceData(companyData)); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [error] = useState<string>("");

    const goBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        dispatch(setTempAddress({ ...individualData })); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveProfileChanges = () => {
        dispatch(
            userDataChangeAction({
                id: userData?.id,
                mail: userData?.mail,
                phone: phoneNumber,
                fname: tempAddress?.fname,
                lname: tempAddress?.lname,
                individualAddress1: tempAddress?.address1,
                individualAddress2: tempAddress?.address2,
                nip: invoiceData?.nip,
                companyName: invoiceData?.companyName,
                companyAddress1: invoiceData?.address1,
                companyAddress2: invoiceData?.address2,
            })
        );
        setTimeout(() => {
            dispatch(getUserDataAction(userData.id));
            navigate("/");
        }, 1000);
    };

    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                <Header>
                    <UserOutlined style={{ fontSize: 28, marginRight: 10 }} />
                    Dane konta
                </Header>
                <InfoTextMain>{mail}</InfoTextMain>
                <InfoText>Uzupełnij dane, aby nie wpisywać ich przy każdym zakupie</InfoText>
                {error.length > 1 ? <LogInError>{error}</LogInError> : <LogInError />}
                <InputRegular
                    onTextChange={(e: any) => setPhoneNumber(e.target.value)}
                    value={phoneNumber}
                    placeholder="np. 000 000 000"
                    title="Numer Telefonu"
                    maxLength={9}
                    type="number"
                    onInput={(e: any) => (e.target.value = e.target.value.slice(0, 9))}
                />
                <div style={{ height: 20 }} />

                <SectionsWrapper>
                    <Section>
                        <SubTitle>Dane do wysyłki</SubTitle>
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempAddress({ ...tempAddress, fname: e.target.value }))
                            }
                            value={tempAddress.fname}
                            placeholder="np. Stanisław"
                            title="Imię"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempAddress({ ...tempAddress, lname: e.target.value }))
                            }
                            value={tempAddress.lname}
                            placeholder="np. Nowak"
                            title="Nazwisko"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempAddress({ ...tempAddress, address1: e.target.value }))
                            }
                            value={tempAddress.address1}
                            placeholder="np. Słowackiego 1/1"
                            title="Ulica i numer budynku"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempAddress({ ...tempAddress, address2: e.target.value }))
                            }
                            value={tempAddress.address2}
                            placeholder="np. 00-000 Warszawa"
                            title="Kod pocztowy i miejscowość"
                        />
                        <div style={{ height: 20 }} />
                    </Section>
                    <div style={{ height: 20, width: 40 }} />
                    <Section>
                        <SubTitle>Dane do faktury</SubTitle>
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempInvoiceData({ ...invoiceData, nip: e.target.value }))
                            }
                            value={invoiceData.nip}
                            placeholder="np. 8170000000"
                            title="NIP"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempInvoiceData({ ...invoiceData, companyName: e.target.value }))
                            }
                            value={invoiceData.companyName}
                            placeholder="np. Moja Firma"
                            title="Nazwa firmy"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempInvoiceData({ ...invoiceData, address1: e.target.value }))
                            }
                            value={invoiceData.address1}
                            placeholder="np. Słowackiego 1/1"
                            title="Ulica i numer budynku"
                        />
                        <div style={{ height: 10 }} />
                        <InputRegular
                            onTextChange={(e: any) =>
                                dispatch(setTempInvoiceData({ ...invoiceData, address2: e.target.value }))
                            }
                            value={invoiceData.address2}
                            placeholder="np. 00-000 Warszawa"
                            title="Kod pocztowy i miejscowość"
                        />
                        <div style={{ height: 20 }} />
                    </Section>
                </SectionsWrapper>

                <AppButtonRegular title="Zatwierdź" onClick={() => saveProfileChanges()} />
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};
const InfoText = styled.p`
    margin-top: 5px;
    color: ${({ theme }) => theme.color.black};
    font-size: 12px;
    font-weight: 500;
`;
const InfoTextMain = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: 16px;
    font-weight: 600;
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
const LogInError = styled.p`
    font-size: 12px;
    color: red;
    margin: 11px 5px;
    text-align: center;
`;
const Header = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
`;
const SubTitle = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 14px;
    font-weight: 600;
    align-self: flex-start;
`;
const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    height: auto;
    background-color: white;
    @media (min-width: 1000px) {
        width: 640px;
        margin-top: 50px;
        margin-bottom: 100px;
        padding: 40px;
        height: auto;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow: scroll;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 11;
`;
