import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../reducers";
import { HoseCenterWrapper } from "./parts/HoseCenterWrapper";
import { LeftFittingWrapper } from "./parts/LeftFittingWrapper";
import { RightFittingWrapper } from "./parts/RightFittingWrapper";

export const HoseConfig = () => {
    const { hose, leftFitting, rightFitting } = useSelector((state: RootState) => state.currentHose);
    const { active } = useSelector((state: RootState) => state.progress);

    const hoseString = "DN" + hose?.parameters?.srednica_wewnetrzna + " " + hose?.parameters?.oploty;
    return (
        <Wrapper>
            <LeftFittingWrapper active={active === "left"} selected={leftFitting?.parameters} fitting={leftFitting} />
            <HoseCenterWrapper active={active === "hose"} selected={hose?.parameters} hoseString={hoseString} />
            <RightFittingWrapper
                active={active === "right"}
                selected={rightFitting?.parameters}
                fitting={rightFitting}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 32px 0px 0px 42px;
    @media (max-width: 768px) {
        margin: 5px 0 20px;
        width: 100%;
        transform: scale(0.8);
    }
`;
