import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LogInEmail } from "../../components/login/LogInEmail";
import { LogInRadioButtons } from "../../components/login/LogInRadioButtons";
import { LogInSms } from "../../components/login/LogInSms";
import { LogInSmsCodeInputWrap } from "../../components/login/LogInSmsCodeInputWrap";
import IconCloseSvg from "../../icons/Icon_CloseSvg";

export const LogInModalRoute = () => {
    const navigate = useNavigate();
    const [logInMethod, setLogInMethod] = useState("PHONE");
    const [smsCodeInput, showSmsCodeInput] = useState(false);
    const [error, setError] = useState<string>("");

    const goBack = () => {
        navigate(-1);
    };

    return (
        <ModalRouteWrapper onClick={() => goBack()}>
            <ModalContentWrapper onClick={(e) => e.stopPropagation()}>
                <CloseWrap>
                    <div style={{ cursor: "pointer" }} onClick={() => goBack()}>
                        <IconCloseSvg />
                    </div>
                </CloseWrap>
                {!smsCodeInput && (
                    <>
                        <Header>Zaloguj się</Header>
                        <LogInRadioButtons logInMethod={logInMethod} setLogInMethod={(e: any) => setLogInMethod(e)} />
                        {error.length > 1 ? <LogInError>{error}</LogInError> : <LogInError />}
                        {logInMethod === "EMAIL" && <LogInEmail setError={(e: any) => setError(e)} />}
                        {logInMethod === "PHONE" && (
                            <LogInSms
                                showSmsCodeInput={(e: boolean) => showSmsCodeInput(e)}
                                setError={(e: any) => setError(e)}
                            />
                        )}
                    </>
                )}
                {!!smsCodeInput && <LogInSmsCodeInputWrap />}
            </ModalContentWrapper>
        </ModalRouteWrapper>
    );
};

const CloseWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 70px;
`;

const LogInError = styled.p`
    font-size: 12px;
    color: red;
    margin: 11px 5px;
    text-align: center;
`;

const Header = styled.h1`
    margin: 0;
    color: ${({ theme }) => theme.color.black};
    font-size: 20px;
    font-weight: 700;
`;
const ModalContentWrapper = styled.div`
    box-sizing: border-box;
    padding: 15px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    @media (min-width: 1000px) {
        height: 510px;
        width: 580px;
    }
`;
const ModalRouteWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: rgba(107, 112, 121, 0.4);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
`;
