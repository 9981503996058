import axios from "axios";
import { clearUserData } from "../actions/account.action";
import { setAuthorizationHeader } from "./../utils/auth.utils";
import { getNewAccessToken } from "./getNewAuthToken";

export const setupAxiosInterceptors = (dispatch) => {
    const logOut = async () => {
        await window.localStorage.removeItem("userData");
        await window.localStorage.removeItem("accessToken");
        await window.localStorage.removeItem("refreshToken");
        dispatch(clearUserData());
        return;
    };
    const retriedRequests = new Set();

    axios.interceptors.response.use(async (response) => {
        if (
            response.status === 200 &&
            (response.data === "Signature doesn't match" || response.data === "Token expired") &&
            !retriedRequests.has(response.config.url) &&
            response.config.url !== "refresh-token.php"
        ) {
            retriedRequests.add(response.config.url);
            try {
                const newAccessToken = await getNewAccessToken();
                if (
                    newAccessToken === "ERROR" ||
                    newAccessToken === "Token expired" ||
                    newAccessToken.includes("Warning")
                ) {
                    await logOut();
                    alert("Twoja sesja wygasła. Zaloguj się ponownie.");
                    window.location.href = "/";
                } else {
                    setAuthorizationHeader(newAccessToken);
                    response.config.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axios.request(response.config);
                }
            } catch (error) {
                await logOut();
                window.location.href = "/"; // Przekieruj użytkownika na stronę główną
            }
        }
        return response;
    });
};

export default axios;
