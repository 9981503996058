import { convertPricetoMinorUnitFormat } from "./convertPricetoMinorUnitFormat";

export const countSingleHosePrice = ({ length, hosePrice, leftFittingPrice, rightFittingPrice, sleevePrice, coverPrice = 0, coverLength = 0 }: any) => {
    const hose = Math.round(convertPricetoMinorUnitFormat(hosePrice) * (length / 100));
    const cover = coverPrice ? Math.round(convertPricetoMinorUnitFormat(coverPrice) * (coverLength / 100)) : 0;
    const sleeve = convertPricetoMinorUnitFormat(sleevePrice) * 2;
    const singleHosePrice =
        hose +
        cover +
        sleeve +
        convertPricetoMinorUnitFormat(leftFittingPrice) +
        convertPricetoMinorUnitFormat(rightFittingPrice);
    return singleHosePrice;
};
