import * as React from "react"

function IconWeightSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 21.202 17.393"
		>
			<path
				data-name="Path 42"
				d="M21.2 8.24a.166.166 0 000-.028.255.255 0 000-.028.285.285 0 00-.012-.035L17.544.199v-.01a.277.277 0 00-.04-.061l-.012-.015a.392.392 0 00-.063-.055.345.345 0 00-.071-.031h-.017a.3.3 0 00-.118-.025.285.285 0 00-.065 0l-5.2 1.047a1.491 1.491 0 00-2.849.57l-5.2 1.034h-.04a.368.368 0 00-.063.03h-.013a.313.313 0 00-.061.053l-.013.017a.28.28 0 00-.038.06v.01L.036 10.771a.285.285 0 00-.012.035.255.255 0 000 .028.166.166 0 000 .028.344.344 0 00-.024.071c0 .5.5.933 1.429 1.251a8.378 8.378 0 002.546.406 8.378 8.378 0 002.546-.406c.933-.318 1.429-.754 1.429-1.251a.344.344 0 000-.038.166.166 0 000-.028.255.255 0 00-.008-.028.285.285 0 00-.012-.035L4.45 3.225l4.792-.957a1.491 1.491 0 00.331.472l.7 2.769v7.909H6.957a.994.994 0 00-.994.994v.994h-.331a.994.994 0 00-.994.994v.331h-.994a.332.332 0 000 .663h13.913a.332.332 0 000-.663h-.994V16.4a.994.994 0 00-.994-.994h-.331v-.995a.994.994 0 00-.994-.994h-3.312V5.511l.692-2.769a1.491 1.491 0 00.467-1.042l4.565-.913-3.376 7.359a.284.284 0 00-.012.035.255.255 0 000 .028.166.166 0 000 .028.345.345 0 00-.018.046c0 .5.5.933 1.429 1.251a8.189 8.189 0 005.092 0c.933-.318 1.429-.754 1.429-1.251a.344.344 0 000-.043zm-5.3 8.159v.331H5.3v-.331a.331.331 0 01.331-.331h9.938a.331.331 0 01.331.331zm-1.324-1.988v.994H6.625v-.994a.331.331 0 01.331-.331h7.288a.331.331 0 01.332.331zm5.777-6.46H14.1l3.127-6.824zM10.6.83a.828.828 0 11-.828.828A.828.828 0 0110.6.83zM3.975 3.778L7.1 10.602H.848zm0 8.149a6.464 6.464 0 01-2.995-.663h5.99a6.464 6.464 0 01-2.995.663zM10.6 4.101l-.243-.974a1.388 1.388 0 00.487 0zm6.625 5.176a6.464 6.464 0 01-2.995-.663h5.99a6.464 6.464 0 01-2.994.663z"
				fill={COLOR}
			/>
		</svg>
	)
}

export default IconWeightSvg
