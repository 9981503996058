import {
    CURRENT_HOSE,
    CURRENT_HOSE_LENGTH,
    CURRENT_HOSE_CATEGORY,
    CURRENT_HOSE_DIMENSION,
    CURRENT_RESET,
    LEFT_FITTING_ANGLE,
    LEFT_FITTING_DIAMETER,
    LEFT_FITTING_PARAMETERS,
    LEFT_FITTING_TYPE,
    RIGHT_FITTING_DIAMETER,
    RIGHT_FITTING_PARAMETERS,
    RIGTH_FITTING_Angle,
    RIGTH_FITTING_TYPE,
    CURRENT_HOSE_ORIENTATION,
    CURRENT_HOSE_COVER,
    CURRENT_HOSE_COVER_LENGTH,
    CURRENT_HOSE_COVER_POSITION,
} from "../constants/actionNames";

export const currentResetAction = () => ({
    type: CURRENT_RESET,
});
export const setCurrentHoseCategoryAction = (category: string | null, id: number | null) => ({
    type: CURRENT_HOSE_CATEGORY,
    payload: { category, id },
});
export const setCurrentHoseDimensionAction = (dimension: number | string | null) => ({
    type: CURRENT_HOSE_DIMENSION,
    payload: dimension,
});
export const setCurrentHoseLengthAction = (length: number) => ({
    type: CURRENT_HOSE_LENGTH,
    payload: length,
});
export const setCurrentHoseOrientationAction = (length: number) => ({
    type: CURRENT_HOSE_ORIENTATION,
    payload: length,
});
export const setCurrentHoseAction = (parameters: any) => ({
    type: CURRENT_HOSE,
    payload: parameters,
});
export const setLeftFittingTypeAction = (type: string | null) => ({
    type: LEFT_FITTING_TYPE,
    payload: type,
});
export const setRightFittingTypeAction = (type: string | null) => ({
    type: RIGTH_FITTING_TYPE,
    payload: type,
});
export const setLeftFittingAngleAction = (angle: string | null) => ({
    type: LEFT_FITTING_ANGLE,
    payload: angle,
});
export const setRightFittingAngleAction = (angle: string | null) => ({
    type: RIGTH_FITTING_Angle,
    payload: angle,
});
export const setLeftFittingDiameterAction = (diameter: string | null) => ({
    type: LEFT_FITTING_DIAMETER,
    payload: diameter,
});
export const setRightFittingDiameterAction = (diameter: string | null) => ({
    type: RIGHT_FITTING_DIAMETER,
    payload: diameter,
});
export const setLeftFittingParamsAction = (params: any) => ({
    type: LEFT_FITTING_PARAMETERS,
    payload: params,
});
export const setRightFittingParamsAction = (params: any) => ({
    type: RIGHT_FITTING_PARAMETERS,
    payload: params,
});
export const setCurrentCoverAction = (params: any) => ({
    type: CURRENT_HOSE_COVER,
    payload: params,
});
export const setCurrentCoverLengthAction = (params: any) => ({
    type: CURRENT_HOSE_COVER_LENGTH,
    payload: params,
});
export const setCurrentCoverPositionAction = (params: any) => ({
    type: CURRENT_HOSE_COVER_POSITION,
    payload: params,
});
