import {
    AppstoreOutlined,
    DesktopOutlined,
    InboxOutlined,
    ShoppingCartOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { slide as Menu } from "react-burger-menu";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { clearUserData } from "../../actions/account.action";
import { theme } from "../../constants/Theme";
import IconCloseSvg from "../../icons/Icon_CloseSvg";
import { RootState } from "../../reducers";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
import "./BurgerMenu.css";
import { MenuElement } from "./MenuElement";

const BurgerMenu = ({ setMenuOpen, isMenuOpen }: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLogged, user } = useSelector((state: RootState) => state.account);
    let location = useLocation();

    const logOut = async () => {
        await window.localStorage.removeItem("userData");
        await window.localStorage.removeItem("accessToken");
        await window.localStorage.removeItem("refreshToken");
        dispatch(clearUserData());
        navigate("/");
    };

    return (
        <MenuWrapper
            customCrossIcon={false}
            disableOverlayClick
            right
            onClose={() => setMenuOpen(!isMenuOpen)}
            isOpen={isMenuOpen}>
            <OverlayToClose onClick={() => setMenuOpen(!isMenuOpen)}>
                <MenuContent onClick={(e) => e.stopPropagation()}>
                    <TopWrap>
                        <TitleWrap>
                            <Title>{!isLogged ? "Zakuwanie24.pl" : "Witaj,"}</Title>
                            {!isLogged ? <></> : <SubTitle>{user?.userData.mail}</SubTitle>}
                        </TitleWrap>
                        <div style={{ cursor: "pointer" }} onClick={() => setMenuOpen(false)}>
                            <IconCloseSvg />
                        </div>
                    </TopWrap>
                    {!isLogged && (
                        <ButtonsWrap>
                            <Link
                                style={{ textDecoration: "none", width: "100%" }}
                                to={`/Modal/Login`}
                                state={{ backgroundLocation: location }}>
                                <AppButtonRegular title={"Zaloguj się"} onClick={() => setMenuOpen(false)} />
                            </Link>

                            <div style={{ width: 10 }} />
                            <Link
                                style={{ textDecoration: "none", width: "100%" }}
                                to={`/Modal/Register`}
                                state={{ backgroundLocation: location }}>
                                <AppButtonRegular
                                    title={"Utwórz konto"}
                                    color={theme.color.app_blue2}
                                    bgColor={theme.color.app_lightBlue}
                                    borderColor={theme.color.app_blue2}
                                    onClick={() => setMenuOpen(false)}
                                />
                            </Link>
                        </ButtonsWrap>
                    )}
                    <Content>
                        {isLogged && (
                            <Link
                                style={{ textDecoration: "none", color: "black" }}
                                to={`/Modal/Profile`}
                                state={{ backgroundLocation: location }}>
                                <MenuElement
                                    icon={<UserOutlined style={{ fontSize: 20, color: "black" }} />}
                                    title="Moje dane"
                                    onClick={() => setMenuOpen(false)}
                                />
                            </Link>
                        )}
                        <MenuElement
                            icon={<ShoppingCartOutlined style={{ fontSize: 20, color: "black" }} />}
                            onClick={() => {
                                navigate("/Cart");
                                setMenuOpen(false);
                            }}
                            title="Koszyk"
                        />
                        {isLogged && (
                            <MenuElement
                                icon={<InboxOutlined style={{ fontSize: 20, color: "black" }} />}
                                onClick={() => {
                                    navigate("/Orders");
                                    setMenuOpen(false);
                                }}
                                title="Zamówienia"
                            />
                        )}

                        <Line />
                        <MenuElement
                            icon={<AppstoreOutlined style={{ fontSize: 20, color: "black" }} />}
                            onClick={() => window.open("https://zakuwanie24.pl/baza-wiedzy/", "_blank")}
                            title="Baza wiedzy"
                        />
                        <MenuElement
                            icon={<DesktopOutlined style={{ fontSize: 20, color: "black" }} />}
                            onClick={() => window.open("https://zakuwanie24.pl/", "_blank")}
                            title="Zakuwanie24.pl"
                        />
                        <Line />
                        {isLogged && <AppButtonRegular title="Wyloguj się" onClick={logOut} />}
                    </Content>
                </MenuContent>
            </OverlayToClose>
        </MenuWrapper>
    );
};

export default BurgerMenu;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.app_grey};
    margin: 8px 0;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 14px;
`;
const ButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;
const SubTitle = styled.p`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.black};
`;
const Title = styled.p`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.black};
    margin-bottom: 3px;
`;
const TitleWrap = styled.div``;
const TopWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;
const MenuContent = styled.div`
    display: flex !important;
    flex-direction: column;
    padding: 25px;
    outline: none !important;
    background-color: ${({ theme }) => theme.color.white};
    height: 100%;
    width: 100%;
    @media (min-width: 1000px) {
        width: 400px;
        cursor: auto;
    }
`;
const OverlayToClose = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    outline: 0;
`;
const MenuWrapper = styled(Menu)`
    height: 100vh;
    width: 100% !important;
    background-color: ${({ theme }) => theme.color.white};
    cursor: pointer;
    @media (min-width: 1000px) {
        background-color: rgba(107, 112, 121, 0.4);
        backdrop-filter: blur(2px);
    }
`;
