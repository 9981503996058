import React from "react";
import styled from "styled-components";

interface ParamTabTypes {
    value: string;
    icon: any;
    fittingMode?: boolean;
    fontSize?: string;
}
export const ParamTab = ({ value, icon, fittingMode = false, fontSize }: ParamTabTypes) => {
    return (
        <Wrapper fittingMode={fittingMode}>
            {icon}
            <Value style={{ fontSize: fontSize }} fittingMode={fittingMode}>
                {value}
            </Value>
        </Wrapper>
    );
};

const Value = styled.p<{ fittingMode: boolean }>`
    margin: ${({ fittingMode }) => (fittingMode ? 0 : "0 0 0 4")}px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.black};
`;
const Wrapper = styled.div<{ fittingMode: boolean }>`
    padding: ${({ fittingMode }) => (fittingMode ? "5px 0px" : "5px 0px")};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 3px 0;
`;
