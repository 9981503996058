import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IconArrowDownSvg from "../../../icons/Icon_ArrowDownSvg";
import { Text } from "../../../styled/typography";
import { HoseConfig } from "../../hoseImages/HoseConfig/HoseConfig";
import SummaryFittingSection from "../Summary_FittingSection";
import SummaryHoseSection from "../Summary_HoseSection";

export const SummaryMobile = ({ isScrolled = false }: any) => {
    const { hose, leftFitting, rightFitting } = useSelector((state: any) => state.currentHose);
    const [open, setOpen] = useState<boolean>(false);
    return (
        <Wrapper style={{ height: isScrolled ? 32 : 42 }}>
            <ButtonWrap onClick={() => setOpen(!open)}>
                <ButtonTitle>{open ? "Ukryj" : "Pokaż"} rysunek i szczegóły konfiguracji</ButtonTitle>
                <IconPosition open={open}>
                    <IconArrowDownSvg COLOR="black" />
                </IconPosition>
            </ButtonWrap>

            <SummaryWrapper style={{ display: open ? "block" : "none", top: isScrolled ? 32 : 42 }}>
                <HoseConfig />
                <SummaryHoseSection
                    parameters={hose?.parameters}
                    dimension={hose?.dimension}
                    category={hose?.category}
                />
                <SummaryFittingSection
                    left={true}
                    data={{
                        angle: leftFitting.angle,
                        type: leftFitting.type,
                        diameter: leftFitting.parameters?.srednica_gwintu,
                        parameters: leftFitting.parameters,
                    }}
                />
                <SummaryFittingSection
                    left={false}
                    data={{
                        angle: rightFitting.angle,
                        type: rightFitting.type,
                        diameter: rightFitting.parameters?.srednica_gwintu,
                        parameters: rightFitting.parameters,
                    }}
                />
            </SummaryWrapper>
        </Wrapper>
    );
};
const SummaryWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    left: 0;
    background-color: ${({ theme }) => theme.color.app_grey4};
    padding: 10px 0 200px;
    z-index: 999;
    -webkit-box-shadow: 0px 11px 9px -13px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 11px 9px -13px rgba(66, 68, 90, 1);
    box-shadow: 0px 11px 9px -13px rgba(66, 68, 90, 1);
    overflow: scroll;
    height: 100vh;
`;
const Wrapper = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.color.app_grey4};
    border-top: 1px solid ${({ theme }) => theme.color.app_lightBlue};
    position: relative;
    z-index: 999;
`;
const ButtonWrap = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey6};
`;
const ButtonTitle = styled(Text)`
    font-size: 12px;
    font-weight: 700;
    margin-right: 6px;
    color: ${({ theme }) => theme.color.black};
`;
const IconPosition = styled.div<{ open: boolean }>`
    transform: ${({ open }) => (!open ? "rotate(180deg)" : "rotate(0deg)")};
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 2px;
`;
