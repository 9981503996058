import * as React from "react"

function IconFittingEyeSvg({SIZE = 30, COLOR = '#FFF'}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={SIZE}
			height={SIZE}
			viewBox="0 0 38.902 16.189"
		>
			<g data-name="Group 769">
				<path
					data-name="Path 763"
					d="M8.098 3.383a4.713 4.713 0 104.713 4.711 4.718 4.718 0 00-4.713-4.711zm0 8.926a4.213 4.213 0 114.213-4.215 4.218 4.218 0 01-4.213 4.215z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 764"
					d="M8.098 1.779a6.317 6.317 0 106.317 6.315 6.324 6.324 0 00-6.317-6.315zm0 12.134a5.817 5.817 0 115.817-5.819 5.823 5.823 0 01-5.817 5.819z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 765"
					d="M15.465 5.558a.25.25 0 00-.158.316 7.646 7.646 0 01.315 3.469.25.25 0 00.213.283h.035a.25.25 0 00.247-.215 8.211 8.211 0 00.079-1.133 8.087 8.087 0 00-.415-2.565.25.25 0 00-.316-.155z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 766"
					d="M38.651 4.146h-2.624l-2.722-1.03a.247.247 0 00-.089-.022h-1.479a.25.25 0 00-.25.25v.8h-2.781l-1.292-.492a.247.247 0 00-.089-.017H26.18a.25.25 0 00-.25.25v.258h-2.5l-.938-.48a.249.249 0 00-.114-.027h-1.689a.25.25 0 00-.25.25v.466h-4.168l-.9-.7a.252.252 0 00-.154-.053h-.391a8.1 8.1 0 100 8.99h.394a.257.257 0 00.153-.052l.911-.7h4.155v.466a.25.25 0 00.25.25h1.691a.265.265 0 00.114-.027l.938-.479h2.5v.258a.25.25 0 00.25.25h1.145a.247.247 0 00.089-.017l1.292-.492h2.782v.8a.25.25 0 00.25.25h1.479a.247.247 0 00.089-.017l2.722-1.03h2.626a.25.25 0 00.25-.25V4.394a.252.252 0 00-.255-.248zm-.25 7.4h-2.42a.247.247 0 00-.089.017l-2.722 1.031h-1.183v-.8a.263.263 0 00-.265-.25h-3.066a.247.247 0 00-.089.017l-1.292.492h-.849v-.259a.25.25 0 00-.25-.25H23.37a.262.262 0 00-.114.027l-.938.48h-1.381v-.467a.25.25 0 00-.25-.25h-4.451a.244.244 0 00-.178.077c-.024.009-.05 0-.071.02l-.853.658h-.435c-.01 0-.016.009-.025.01a.217.217 0 00-.086.036.239.239 0 00-.076.052c-.006.008-.017.007-.023.016a7.6 7.6 0 110-8.22c0 .008.017.008.023.016a.224.224 0 00.076.052.227.227 0 00.087.036c.009 0 .015.01.024.01h.434l.852.667a.252.252 0 00.154.053c.031.013.056.038.091.038h4.2v3.423a.25.25 0 00.5 0V4.136h1.381l.938.479a.252.252 0 00.114.027h2.559v3.632a.25.25 0 00.5 0v-4.14h.849l1.292.492a.247.247 0 00.089.017h2.828v3.631a.25.25 0 00.5 0v-4.68h1.183l2.722 1.03a.247.247 0 00.089.017h2.42v6.9z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 767"
					d="M20.688 8.961a.25.25 0 00-.25.25v1.192a.25.25 0 00.5 0V9.211a.25.25 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 768"
					d="M31.723 8.961a.25.25 0 00-.25.25v1.192a.25.25 0 10.5 0V9.211a.25.25 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
				<path
					data-name="Path 769"
					d="M26.175 8.961a.25.25 0 00-.25.25v1.192a.25.25 0 00.5 0V9.211a.25.25 0 00-.25-.25z"
					fill={COLOR}
					stroke={COLOR}
					strokeWidth={0.35}
				/>
			</g>
		</svg>
	)
}

export default IconFittingEyeSvg
