import { MenuOutlined, ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useScreenSize from "use-screen-size";
import Logo from "../../assets/logo-color.png";
import { theme } from "../../constants/Theme";
import { RootState } from "../../reducers";
import { AppButtonRegular } from "../buttons/App_ButtonRegular";
const currentEnv = process.env.REACT_APP_ENVIRONMENT;
const AppHeader = ({ setMenuOpen, isMenuOpen }: any) => {
    const isLogged = useSelector((state: RootState) => state.account.isLogged);
    const { hosesIds } = useSelector((state: RootState) => state.account.user);
    const guestCartHoses = useSelector((state: RootState) => state.guestCart.guestCartHoses);
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    let location = useLocation();

    return (
        <Wrapper style={{ backgroundColor: currentEnv === "staging" ? theme.color.app_orange : theme.color.white }}>
            <LogoImg onClick={() => navigate("/")} src={Logo} alt="zakuwanie24_logo" />
            <RightWrap>
                {!!isLogged && (
                    <Link
                        style={{ textDecoration: "none", width: "100%" }}
                        to={`/Modal/Profile`}
                        state={{ backgroundLocation: location }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                position: "relative",
                                marginRight: 27,
                            }}>
                            <UserOutlined style={{ fontSize: 26, color: "black" }} />
                        </div>
                    </Link>
                )}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        position: "relative",
                    }}
                    onClick={() => navigate("/Cart")}>
                    <ShoppingCartOutlined style={{ fontSize: 26, color: "black" }} />
                    {((isLogged && hosesIds?.length > 0) || (!isLogged && guestCartHoses?.length > 0)) && (
                        <AbsoluteDot>{isLogged ? hosesIds?.length : guestCartHoses?.length}</AbsoluteDot>
                    )}
                </div>
                {screenSize.width > 650 && (
                    <>
                        {!isLogged && (
                            <ButtonsWrap>
                                <Link
                                    style={{ textDecoration: "none", width: "100%" }}
                                    to={`/Modal/Login`}
                                    state={{ backgroundLocation: location }}>
                                    <AppButtonRegular title={"Zaloguj się"} onClick={undefined} />
                                </Link>
                                <div style={{ width: 12 }} />
                                <Link
                                    style={{ textDecoration: "none", width: "100%" }}
                                    to={`/Modal/Register`}
                                    state={{ backgroundLocation: location }}>
                                    <AppButtonRegular
                                        bgColor={theme.color.app_lightBlue}
                                        color={theme.color.app_blue2}
                                        title={"Utwórz konto"}
                                        onClick={undefined}
                                        borderColor={theme.color.app_blue2}
                                    />
                                </Link>
                            </ButtonsWrap>
                        )}
                    </>
                )}
                <div style={{ marginLeft: 27 }}>
                    <MenuOutlined onClick={() => setMenuOpen(!isMenuOpen)} style={{ fontSize: 26, color: "black" }} />
                </div>
            </RightWrap>
        </Wrapper>
    );
};

export default AppHeader;

const LogoImg = styled.img`
    object-fit: contain;
    height: 20px;
    cursor: pointer;
`;
const AbsoluteDot = styled.div`
    position: absolute;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.color.app_blue2};
    right: -7px;
    top: -2px;
    font-size: 9px;
    color: ${({ theme }) => theme.color.white};
    font-weight: 700;
`;
const ButtonsWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 322px;
    margin-left: 41px;
`;
const RightWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Wrapper = styled.header`
    position: fixed;
    z-index: 11;
    top: 0;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding: 0 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.color.app_grey4};
    @media (min-width: 1279px) {
        padding: 0 54px;
    }
`;
