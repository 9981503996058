import { theme } from "../../constants/Theme";
import { Text } from "../../styled/typography";
import { ReusableInfoElementWithEmoji } from "../elements/ReusableInfoElementWithEmoji";

export const CartInformationElement = () => {
    return (
        <ReusableInfoElementWithEmoji
            style={{ backgroundColor: theme.color.app_lightGreen, borderColor: theme.color.app_green }}
            infos={[
                {
                    emoji: "📦",
                    description:
                        "Dla wszystkich zamówień o wartości powyżej 150 złotych, dostawa jest zupełnie za darmo!",
                },
                {
                    emoji: "🕚",
                    description:
                        "Ekspresowa wysyłka! Zamówienia złożone do godziny 11:00 są wysyłane tego samego dnia.",
                },
                {
                    emoji: "📞",
                    description: (
                        <div>
                            <Text>
                                Potrzebujesz pomocy? Zadzwoń od 7:30 do 16:00 pod
                                <span style={{ fontWeight: 600 }}> 885 443 185 </span> lub napisz
                                <span style={{ fontWeight: 600 }}> info@zakuwanie24.pl</span>
                            </Text>
                        </div>
                    ),
                },
            ]}
        />
    );
};
